import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import BranchManagement from "./BranchManagement";
import { getBranch } from "../../redux/actions/branches";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-materialize";
import BranchAnalytics from "./BranchAnalytics";

const SingleBranch = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { branch, loading } = useSelector((state) => state.branch);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBranch(id));
  }, [dispatch, id]);

  return (
    <div className="main">
      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5>{branch?.name}</h5>
          </div>
        </div>

        <button
          className="btn"
          onClick={() => navigate("/sale/" + branch?._id)}
        >
          Make A Sale
        </button>
      </header>
      {loading && <ProgressBar />}

      <main className="p-2  main-body flex b-d-container">
        <section className=" ">
          <BranchAnalytics />
        </section>
        <aside className="white p-1">
          <BranchManagement />
        </aside>
      </main>
    </div>
  );
};

export default SingleBranch;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  deleteProduct,
  getAllProducts,
  removeStock,
} from "../../../redux/actions/products";
import { getBranch } from "../../../redux/actions/branches";

const RemoveStockModal = ({
  showRemoveStockModal,
  setShowRemoveStockModal,
  product,
}) => {
  const { loading } = useSelector((state) => state.product);
  const { branch } = useSelector((state) => state.branch);
  const dispatch = useDispatch();

  function saveInventory(e) {
    e.preventDefault();
    dispatch(
      removeStock(
        branch._id,
        {
          product: product?._id,
        },
        () => {
          setShowRemoveStockModal(false);
          dispatch(getBranch(branch._id));
        }
      )
    );
  }


  return (
    <Modal
      onClose={() => {
        setShowRemoveStockModal(false);
      }}
      open={showRemoveStockModal}
      center
    >
      <form onSubmit={saveInventory} className="mt-2 p-2">
        <h5 className="mb-1">Confirmation</h5>
        <p>
          Are you sure you want to remove this from stock? This action cannot be
          undone
        </p>

        <div className="mt-2">
          <p className="capitalize">{product?.manufacturer}</p>
          <p className="bold capitalize mt-1 margin-0">{product?.name}</p>
        </div>

        <div className="mt-2 gap-1 flex ">
          <button type="submit" className="btn">
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              "Delete Product"
            )}
          </button>

          <button
            className="btn grey lighten-1"
            onClick={() => {
              setShowRemoveStockModal(false);
            }}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveStockModal;

import React from "react";
import { Button } from "react-materialize";
import { HomeButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const SaleHeader = ({
  inputRef,
  searchProductDropdownRef,
  searchProduct,
  setSearchProduct,
  priceMode,
  setPriceMode,
  navigate,
  setTabs,
  tabs,
}) => {
  const branchId = useParams().id;
  const { branch } = useSelector((state) => state.branch);

  return (
    <header className="flex full-width  space-between p-2 sales-header align-center shadow">
      <div className="flex align-center">
        <HomeButton />
        <div>
          <h5 className="margin-0">{branch?.name}</h5>
          <p className="small-text">Start Selling Products Now</p>
        </div>
      </div>

      <Button
        className="btn flex align-center justify-center waves-effect waves-light teal darken-2"
        tooltip="Add a new tab"
        onClick={() => {
          const deactiveTabs = tabs.map((tab) => {
            return { ...tab, active: false };
          });

          setTabs([
            ...deactiveTabs,
            {
              id: tabs.length + 1,
              title: `Sales Tab ${tabs.length + 1}`,
              active: true,
              products: [],
              priceMode: branch.wholesale ? "wholesale" : "retail",
              customer: {
                name: "No customer selected",
                id: "",
                phoneNumber: "N/A",
              },
              salesType: "cash",
              tabType: "salesReceipt",
              branch: branchId,
            },
          ]);
          navigate(`/sale/${branchId}`);
        }}
      >
        <p>New Tab</p>
        <span className="material-symbols-outlined">add</span>
      </Button>
    </header>
  );
};

export default SaleHeader;

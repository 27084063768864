import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { amount } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import EditPaymentModal from "./EditPayment";
import DeletePayment from "./DeletePayment";
import { BackButton } from "../../components/Buttons";
import { getCustomer } from "../../redux/actions/customers";
import { useParams } from "react-router-dom";

const PaymentHistoryAll = () => {
  const { customer, loading } = useSelector((state) => state.customers);
  const [showMakePaymentModal, setShowMakePaymentModal] = React.useState(false);
  const [payment, setPayment] = React.useState({});
  const [, setPayments] = React.useState([]);
  const [showDeletePaymentModal, setShowDeletePaymentModal] =
    React.useState(false);
  const dispatch = useDispatch();
  const id = useParams().id;

  useEffect(() => {
    dispatch(getCustomer(id));
  }, [dispatch, id]);

  return (
    <>
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Payment History</h5>
            <p className="">{customer?.name}</p>
          </div>
        </div>
      </header>

      {loading && <ProgressBar />}
      <section className="rectangular-sectio p-2 mt-2">
        <EditPaymentModal
          showMakePaymentModal={showMakePaymentModal}
          setShowMakePaymentModal={setShowMakePaymentModal}
          customerId={customer._id}
          customer={customer}
          payment={payment}
        />
        <DeletePayment
          showDeletePaymentModal={showDeletePaymentModal}
          setShowDeletePaymentModal={setShowDeletePaymentModal}
          customer={customer}
          payment={payment}
        />

        <main>
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Entered By</th>
                <th>Date & Time</th>
                <th>Out. Balance Before</th>
                <th>Amount Paid</th>
                <th>Out. Balance After</th>
                <th>Payment Method</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customer.payments?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p>{item.receivedBy?.name}</p>
                    </td>
                    <td>
                      <p>
                        {new Date(item.createdAt).toDateString()},{" "}
                        {new Date(item.createdAt).toLocaleTimeString()}{" "}
                      </p>
                    </td>

                    <td>
                      <h6>{amount(item.balanceBefore)}</h6>
                    </td>
                    <td>
                      <h6>{amount(item.amount)}</h6>
                    </td>
                    <td>
                      <h6>{amount(item.balanceAfter)}</h6>
                    </td>

                    <td>
                      <p>{item.paymentMethod}</p>
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          <a
                            href="#!"
                            onClick={() => {
                              setPayment(item);
                              setShowMakePaymentModal(true);
                            }}
                          >
                            Edit Payment
                          </a>
                          <a
                            href="#!"
                            onClick={() => {
                              setPayment(item);
                              setShowDeletePaymentModal(true);
                            }}
                          >
                            Delete Payment
                          </a>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      </section>
    </>
  );
};

export default PaymentHistoryAll;

import React, { useEffect } from "react";
import Card from "../../components/Card";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import AddProduct from "../../components/AddProduct";
import SelectBranch from "./SelectBranch";
import { getAccount } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import EndOfDayModal from "./EndOfDayModal";
import ExpenseModal from "./ExpenseModal";
import { getBranch } from "../../redux/actions/branches";
import AddBranch from "../branches/components/AddBranch";
import { Dropdown } from "react-materialize";

const MenuCardList = () => {
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showSelectBranchModal, setShowSelectBranchModal] =
    React.useState(false);
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);
  const [selectBranchType, setSelectBranchType] = React.useState("");
  const [showEndOfDayModal, setShowEndOfDayModal] = React.useState(false);
  const [selectedBranch, setSelectedBranch] = React.useState("");
  const [showExpenseModal, setShowExpenseModal] = React.useState(false);
  const [showAddBranchModal, setShowAddBranchModal] = React.useState(false);
  const { branches, warehouses } = useSelector((state) => state.branch);

  const navigate = useNavigate();

  function selectBranchAction(branchId, type) {
    if (type === "sales-history") navigate(`/sales-history/${branchId}`);
    if (type === "sale") navigate(`/sale/${branchId}`);
    if (type === "end-of-day") {
      setShowSelectBranchModal(false);
      setSelectedBranch(branchId);
      setShowEndOfDayModal(true);
    }

    if (type === "expense") {
      setShowSelectBranchModal(false);
      setSelectedBranch(branchId);
      setShowExpenseModal(true);
    }

    if (type === "sales-report") {
      dispatch(getBranch(branchId));
      navigate(`/daily-total-sales/${branchId}`);
    }

    if (type === "receive-inventory")
      navigate(`/receive-inventory/${branchId}`);

    if (type === "suppliers") navigate(`/suppliers/${branchId}`);

    if (type === "inventory-receipt-history")
      navigate(`/inventory-receipt-history/${branchId}`);

    if (type === "branch-products") navigate(`/branch-products/${branchId}`);

    if (type === "non-today-sale") navigate(`/non-today-sale/${branchId}`);

    if (type === "customers") navigate(`/customers/${branchId}`);
  }

  return (
    <div className="menu-card-list ">
      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct setShowProductModal={setShowProductModal} />
      </Modal>

      <Modal
        open={showAddBranchModal}
        onClose={() => setShowAddBranchModal(false)}
      >
        <AddBranch setShowAddBranchModal={setShowAddBranchModal} />
      </Modal>

      <SelectBranch
        type={selectBranchType}
        setShowSelectBranchModal={setShowSelectBranchModal}
        showSelectBranchModal={showSelectBranchModal}
        onClick={(type, branchId) => selectBranchAction(type, branchId)}
      />

      <EndOfDayModal
        showEndOfDayModal={showEndOfDayModal}
        setShowEndOfDayModal={setShowEndOfDayModal}
        branch={selectedBranch}
      />
      <ExpenseModal
        showExpenseModal={showExpenseModal}
        setShowExpenseModal={setShowExpenseModal}
        branch={selectedBranch}
      />

      <header className="flex my-header">
        <h5>Main Menu</h5>

        <div className="flex gap-1">
          <Dropdown
            className="user-dropdown"
            trigger={
              <div className="flex gap-1 align-center">
                <span
                  className="material-symbols-outlined teal lighten-4 teal p-1"
                  style={{ borderRadius: "50%" }}
                >
                  person
                </span>
                <div>
                  <p className="">{account?.name}</p>
                  <p className="small-text grey-text margin-0">salesperson</p>
                </div>
              </div>
            }
          >
            <span>
              <span className="material-symbols-outlined">settings</span>{" "}
              Settings
            </span>
            <span
              onClick={() => {
                localStorage.removeItem("__beemPOS");
                window.location.reload();
              }}
            >
              <span className="material-symbols-outlined">logout</span>
              Logout
            </span>
          </Dropdown>
        </div>
      </header>

      <section className="mb-2">
        <p className="mb-1">General</p>

        <div className="flex gap-1">
          {account.permissions?.includes("create-sale") && (
            <Card
              title={"Make A Sale"}
              description={"Start adding products to be sold "}
              icon={"shopping_cart"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("sale");
                } else {
                  navigate(`/sale/${account.branches[0]}`);
                }
              }}
            />
          )}

          {account.permissions?.includes("create-non-today-sale") && (
            <Card
              title={"Add Non-Today sales"}
              description={"Enter sales from a different day"}
              icon={"shopping_cart"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("non-today-sale");
                } else {
                  navigate(`/non-today-sale/${account.branches[0]}`);
                }
              }}
            />
          )}

          {account.permissions?.includes("read-sales-report") && (
            <Card
              title={"View Sales Report"}
              description={"View your sales report for the day "}
              icon={"receipt"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("sales-report");
                } else {
                  navigate(`/sales-report/${account.branches[0]}`);
                }
              }}
            />
          )}

          {account.permissions?.includes("read-sale") && (
            <Card
              title={"Sales History"}
              description={"View and manage your sales history "}
              icon={"list_alt"}
              onClick={() => {
                if (account.branches?.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("sales-history");
                } else {
                  navigate(`/sales-history/${account.branches[0]}`);
                }
              }}
            />
          )}

          <Card
            title={"My Customers "}
            description={"View and manage your customers "}
            icon={"people"}
            onClick={() => {
              if (account.branches.length > 1) {
                setShowSelectBranchModal(true);
                setSelectBranchType("customers");
              } else {
                navigate(`/customers/${account.branches[0]}`);
              }
            }}
          />

          {account.permissions?.includes("add-expense") && (
            <Card
              title={"Register An Expense"}
              description={"Add an expense to your store "}
              onClick={() => {
                if (account.branches.length === 1) {
                  setSelectedBranch(account.branches[0]);
                  setShowExpenseModal(true);
                  return;
                }
                setShowSelectBranchModal(true);
                setSelectBranchType("expense");
              }}
              icon={"money_off"}
            />
          )}

          {account.permissions?.includes("end-sales-day") && (
            <Card
              title={"End Of Day"}
              description={"Close your cash register, end your sales day "}
              onClick={() => {
                if (account.branches.length === 1) {
                  setSelectedBranch(account.branches[0]);
                  setShowEndOfDayModal(true);
                  return;
                }
                setShowSelectBranchModal(true);
                setSelectBranchType("end-of-day");
                // setShowEndOfDayModal(true);
              }}
              icon={"point_of_sale"}
            />
          )}
        </div>
      </section>

      <section className="mb-2">
        <p className="mb-1">Inventory</p>

        <div className="flex gap-1">
          {account.permissions?.includes("create-product") && (
            <Card
              title={"Add Product"}
              description={"Add a new product to your inventory "}
              icon={"add_box"}
              onClick={() => setShowProductModal(true)}
            />
          )}

          {
            <Card
              title={"View Inventory"}
              description={"View and manage your inventory"}
              icon={"inventory_2"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("branch-products");
                } else {
                  navigate(`/branch-products/${account.branches[0]}`);
                }
              }}
            />
          }

          <Card
            title={"View  Products"}
            icon={"inventory"}
            description={"View and manage your products "}
            onClick={() => navigate("/all-products")}
          />

          {account.permissions?.includes("receive-inventory") && (
            <Card
              title={"Receive Stock"}
              description={"Add new stock to your inventory "}
              icon={"local_shipping"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("receive-inventory");
                } else {
                  navigate(`/receive-inventory/${account.branches[0]}`);
                }
              }}
            />
          )}

          {account.permissions?.includes("read-inventory-receipts") && (
            <Card
              title={"Stock Receival History"}
              description={"View and manage your stock history "}
              icon={"history"}
              onClick={() => {
                if (account.branches.length > 1) {
                  setShowSelectBranchModal(true);
                  setSelectBranchType("inventory-receipt-history");
                } else {
                  navigate(`/inventory-receipt-history/${account.branches[0]}`);
                }
              }}
            />
          )}

          <Card
            title={"View  Suppliers"}
            description={"manage your inventory suppliers "}
            icon={"people"}
            onClick={() => {
              if (account.branches.length > 1) {
                setShowSelectBranchModal(true);
                setSelectBranchType("suppliers");
              } else {
                navigate(`/suppliers/${account.branches[0]}`);
              }
            }}
          />
        </div>
      </section>

      {account.permissions?.includes("create-branch") && (
        <section className="mb-2">
          <p className="mb-1">Branches & Related</p>

          <div className="flex gap-1">
            {account.permissions?.includes("create-branch") && (
              <Card
                title={"Add A Branch"}
                description={"Add a new branch to your store"}
                icon={"add_box"}
                onClick={() => setShowAddBranchModal(true)}
              />
            )}

            {account.permissions?.includes("create-branch") && (
              <Card
                title={"View  Branches"}
                icon={"store"}
                description={"View and manage your branches "}
                onClick={() => navigate("/branches")}
              />
            )}
          </div>
        </section>
      )}

      {warehouses.length > 0 && (
        <section className="mb-2">
          <p className="mb-1">Warehouses And Related</p>

          <div className="flex gap-1">
            {warehouses.length > 0 && (
              <Card
                title={"My Warehouse"}
                description={"View and manage your warehouse "}
                icon={"storefront"}
                onClick={() => {}}
              />
            )}

            {account.permissions?.includes("create-branch") && (
              <Card
                title={"Add New Warehouse"}
                description={"Add a new branch to your store"}
                icon={"add_box"}
                onClick={() => setShowAddBranchModal(true)}
              />
            )}

            {account.permissions?.includes("create-branch") && (
              <Card
                title={"View  Warehouses"}
                icon={"store"}
                description={"View and manage your branches "}
                onClick={() => navigate("/branches")}
              />
            )}

            {warehouses.length > 0 && (
              <Card
                title={"Receive Warehouse Stock"}
                description={"Add new stock to your warehouse "}
                icon={"local_shipping"}
                onClick={() => {
                  const warehouse = branches.find(
                    (branch) => branch.isWarehouse
                  );

                  if (warehouse) {
                    navigate(`/receive-inventory/${warehouse._id}`);
                  }
                }}
              />
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default MenuCardList;

import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { amount } from "../../helpers/helper";

const DebtorsList = () => {
  const { branch } = useSelector((state) => state.branch);
  const [keyword, setKeyword] = React.useState("");
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customers);

  //   useEffect(() => {
  //     if (isEmpty(customers) || branch._id !== branchId) {
  //       dispatch(getCustomers(branchId));
  //     }
  //   }, [branchId, dispatch]);

  useEffect(() => {
    const debtors = customers.filter((item) => {
      return item.due > 0;
    });

    setList(debtors);
  }, [customers]);

  return (
    <div>
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Debtors</h5>
            <p className="">{branch?.name}</p>
          </div>
        </div>
        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input "
              placeholder="Search for customer"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        </div>

        <Dropdown
          trigger={<a className="btn-small white teal-text">I want to...</a>}
        >
          <span
            onClick={() => {
              navigate("/debtors-print/" + branch._id);
            }}
          >
            Print
          </span>
        </Dropdown>
      </header>

      <div>
        <table className="striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Account Details</th>

              <th>Balance Due</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {list?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className="capitalize">
                  <p>{item.name}</p>
                  <p>
                    {item.phoneNumber}, {item.city}
                  </p>
                </td>
                <td className="capitalize">{amount(item.due)}</td>
                <td className="capitalize">
                  <button
                    className="btn-small"
                    onClick={() => {
                      dispatch({ type: "SET_CUSTOMER", payload: item });
                      navigate(`/customer-profile/${item._id}`);
                    }}
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DebtorsList;

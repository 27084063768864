import React from "react";
import Modal from "react-responsive-modal";
import { notify } from "../../helpers/helper";
const QuantityPriceModal = ({
  showQuantityPriceModal,
  setShowQuantityPriceModal,
  tab,
  tabs,
  setTabs,
  productIndex,
}) => {
  const [price, setPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (productIndex === -1) return;
    const product = tab.products[productIndex];
    if (!product) return;
    setPrice(product.price);
    setQuantity(product.quantity);
    setTotal(product.total);
  }, [productIndex, tab.products]);

  if (productIndex === -1) return null;

  function updateProduct() {
    if (!quantity || !price || !total)
      return notify("Please enter Quantity and Price", "e rror");

    const newTabs = [...tabs];
    newTabs[tab.id - 1].products[productIndex].price = parseFloat(price);
    newTabs[tab.id - 1].products[productIndex].quantity = parseFloat(quantity);
    newTabs[tab.id - 1].products[productIndex].total = parseFloat(total);
    setTabs(newTabs);
    localStorage.setItem("salesTab", JSON.stringify(newTabs));
  }

  return (
    <Modal
      onClose={() => {
        setShowQuantityPriceModal(false);
      }}
      open={showQuantityPriceModal}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      center
      closeOnEsc={false}
    >
      <form
        className="p-2"
        onSubmit={(e) => {
          e.preventDefault();
          updateProduct();
          setShowQuantityPriceModal(false);
        }}
      >
        <div className="modal-header mb-2">
          <h5 className="modal-title"> Change Quantity & Price</h5>
        </div>

        <div className="flex mb-2 gap-1 align-end">
          <div>
            <p className="small-text  mb-1">Change Quantity</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={quantity}
                min="0"
                onChange={(e) => {
                  setQuantity(e.target.value);
                  setTotal((e.target.value * price).toFixed(2));
                }}
              />
            </div>
          </div>

          <p>
            <span className="material-symbols-outlined">close</span>
          </p>

          <div>
            <p className="small-text  mb-1">Unit Price</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={price}
                step="any"
                min="0"
                onChange={(e) => {
                  setPrice(e.target.value);
                  setTotal((e.target.value * quantity).toFixed(2));
                }}
              />
            </div>
          </div>

          <p style={{ fontSize: 22, fontWeight: 800 }}>=</p>

          <div>
            <p className="small-text  mb-1">Total</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={total}
                step="any"
                min="0"
                onChange={(e) => {
                  setTotal(e.target.value);
                  setPrice((e.target.value / quantity).toFixed(2));
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <button type="submit" className="btn">
            Save changes
          </button>
          <button
            type="button"
            className="btn white teal-text"
            onClick={() => {
              setShowQuantityPriceModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default QuantityPriceModal;

import React from "react";
import { useSelector } from "react-redux";
import {
  calculateTotal,
  decreaseProductQuantity,
  increaseProductQuantity,
  removeProduct,
  setCashPrices,
  setCreditPrices,
  updateProductOnTab,
} from "../../pages/sale/actions";
import RIChangeParamsModal from "../inventory/components/RIChangeParamsModal";
import { amount, amountWithoutCurrency, notify } from "../../helpers/helper";
import AddNonTodaySaleModal from "./components/AddNonTodaySaleModal";
import { Dropdown } from "react-materialize";
import AddCustomerModal from "./components/AddCustomerModal";
import Modal from "react-responsive-modal";
import AddProduct from "../../components/AddProduct";
import AddSystemProduct from "../products/components/AddSystemProduct";

const NonTodaySaleSection = ({ tab, setTabs, tabs, tabIndex, date }) => {
  const inputRef = React.useRef();
  const searchProductDropdownRef = React.useRef();
  const [searchProduct, setSearchProduct] = React.useState("");
  const [productsList, setProductsList] = React.useState([]);
  const { branch } = useSelector((state) => state.branch);
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const [showQuantityPriceModal, setShowQuantityPriceModal] =
    React.useState(false);
  React.useState(false);
  const [productIndex, setProductIndex] = React.useState(-1);
  const [selectedInput, setSelectedInput] = React.useState("quantity");
  const [showSaveSaleModal, setShowSaveSaleModal] = React.useState(false);
  const searchCustomerInputRef = React.useRef();
  const searchCustomerDropdownRef = React.useRef();
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [customerList, setCustomerList] = React.useState([]);
  const [searchType, setSearchType] = React.useState("product");
  const [showAddCustomer, setShowAddCustomer] = React.useState(false);
  const { customers } = useSelector((state) => state.customers);
  const [showAddSystemProductModal, setShowAddSystemProductModal] =
    React.useState(false);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const { account } = useSelector((state) => state.auth);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, productsList.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      const product = productsList[highlightedIndex];
      searchProductDropdownRef.current.style.display = "none";
      setSearchProduct("");

      updateProductOnTab(
        tabIndex,
        {
          _id: product.details?._id,
          manufacturer: product.details?.manufacturer,
          name: product.details?.name,
          size: product.details?.size,
          type: product.details?.type,
          quantity: 1,
          price:
            tab.priceMode === "retail"
              ? product.retailPrice
              : product.wholesalePrice,
          total:
            tab.priceMode === "retail"
              ? product.retailPrice
              : product.wholesalePrice,
        },
        tabs,
        setTabs
      );
    }
  };

  React.useEffect(() => {
    if (searchProduct.length >= 1) {
      searchProductDropdownRef.current.style.display = "block";
      setProductsList(
        branch?.products.filter((product) =>
          product.details?.name
            .toLowerCase()
            .includes(searchProduct.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchProductDropdownRef.current.style.display = "none";
  }, [branch?.products, searchProduct]);

  React.useEffect(() => {
    if (searchCustomer.length >= 1) {
      searchCustomerDropdownRef.current.style.display = "block";
      console.log(
        customers.filter((customer) =>
          customer.name.toLowerCase().includes(searchCustomer.toLowerCase())
        )
      );
      setCustomerList(
        customers.filter((customer) =>
          customer.name.toLowerCase().includes(searchCustomer.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchCustomerDropdownRef.current.style.display = "none";
  }, [customers, searchCustomer, searchProduct]);

  React.useEffect(() => {
    if (branch.products) setProductsList(branch?.products);
  }, [branch.products]);

  React.useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!searchProductDropdownRef.current.contains(e.target)) {
        searchProductDropdownRef.current.style.display = "none";
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className=" sales-tab">
      {showQuantityPriceModal && (
        <RIChangeParamsModal
          showQuantityPriceModal={showQuantityPriceModal}
          setShowQuantityPriceModal={setShowQuantityPriceModal}
          tab={tab}
          setTabs={setTabs}
          tabIndex={tabIndex}
          productIndex={productIndex}
          setProductIndex={setProductIndex}
          tabs={tabs}
          selectedInput={selectedInput}
        />
      )}

      {showSaveSaleModal && (
        <AddNonTodaySaleModal
          showSaveSaleModal={showSaveSaleModal}
          setShowSaveSaleModal={setShowSaveSaleModal}
          setTabs={setTabs}
          tabs={tabs}
          tab={tab}
          tabIndex={tabIndex}
          branchId={branch._id}
          date={date}
        />
      )}

      <AddCustomerModal
        setShow={setShowAddCustomer}
        show={showAddCustomer}
        branchId={branch._id}
      />

      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct
          setShowProductModal={setShowProductModal}
          branchId={branch?._id}
        />
      </Modal>

      <Modal
        open={showAddSystemProductModal}
        onClose={() => setShowAddSystemProductModal(false)}
      >
        <AddSystemProduct
          setShowAddSystemProductModal={setShowAddSystemProductModal}
          branchId={branch?._id}
        />
      </Modal>
      <header className="flex space-between align-end sales-tab-header ">
        <div className="flex align-center gap-1  sales-recipient">
          <div className="p-1">
            <h6>{date ? new Date(date).toDateString() : "No Date Selected"}</h6>
          </div>

          {tab.supplier.id && (
            <span
              className="material-symbols-outlined"
              onClick={() => {
                setTabs((prevTabs) => {
                  const newTabs = [...prevTabs];
                  newTabs[tabIndex].supplier = {
                    name: "No Supplier Selected",
                    id: "",
                    phoneNumber: "N/A",
                  };
                  return newTabs;
                });
              }}
            >
              close
            </span>
          )}
        </div>

        <div className="flex gap-1 align-center " style={{ height: "100%" }}>
          <div className="relative">
            <div
              className="custom-input-wrapper grey lighten-4 flex "
              style={{ minWidth: "max(300px, 35vw)" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default
               custom-input grey lighten-4"
                placeholder="Search for product..."
                ref={inputRef}
                value={searchProduct}
                onChange={(e) => {
                  setSearchProduct(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>

            <article className="custom-dropdown" ref={searchProductDropdownRef}>
              {
                <ul className="collection">
                  {productsList?.length === 0 ? (
                    <li className="collection-item">
                      No Products matching "{searchProduct}"
                    </li>
                  ) : (
                    productsList?.map((product, index) => (
                      <li
                        className={`collection-item flex space-between align-center hoverable gap-1 ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          searchProductDropdownRef.current.style.display =
                            "none";
                          updateProductOnTab(
                            tabIndex,
                            {
                              _id: product.details?._id,
                              manufacturer: product.details?.manufacturer,
                              name: product.details?.name,
                              size: product.details?.size,
                              type: product.details?.type,
                              quantity: 1,
                              price:
                                tab.priceMode === "retail"
                                  ? product.retailPrice
                                  : product.wholesalePrice,
                              total:
                                tab.priceMode === "retail"
                                  ? product.retailPrice
                                  : product.wholesalePrice,
                            },
                            tabs,
                            setTabs
                          );
                        }}
                      >
                        <div>
                          <label className="uppercase">
                            {product.details?.manufacturer}
                          </label>
                          <div className="flex gap-1">
                            <p className="uppercase">{product.details?.name}</p>
                            <p>{product.details?.size}</p>
                            <label className="grey-text">
                              {product.details?.type}
                            </label>
                          </div>
                        </div>

                        <div className="flex  gap-1">
                          {branch.wholesale && (
                            <div>
                              <label>Wholesale </label>
                              <p>{amount(product.wholesalePrice)}</p>
                            </div>
                          )}

                          {branch.retail && (
                            <div>
                              <label>Retail </label>
                              <p>{amount(product.retailPrice)}</p>
                            </div>
                          )}
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              }
            </article>
          </div>

          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span onClick={() => setShowAddSystemProductModal(true)}>
              Add System Product
            </span>
            <span onClick={() => setShowProductModal(true)}>
              Add New Product
            </span>
          </Dropdown>
        </div>
        {account.permissions?.includes("create-credit-sale") && (
          <div className="flex align-center" style={{ height: "100%" }}>
            <Dropdown
              trigger={
                <button className="btn waves-effect waves-light  flex justify-center align-center white teal-text">
                  Options
                </button>
              }
            >
              <span
                onClick={() => {
                  setCashPrices(tabIndex, tabs, setTabs, branch.products);
                }}
              >
                Set As Cash Invoice
              </span>
              <span
                onClick={() => {
                  setCreditPrices(tabIndex, tabs, setTabs, branch.products);
                }}
              >
                Set As Credit Invoice
              </span>
            </Dropdown>
          </div>
        )}
        <div
          className="flex gap-1 align-center select-supplier-input"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <div
              className="custom-input-wrapper grey lighten-4 flex "
              style={{ minWidth: "max(300px, 35vw)" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default
               custom-input grey lighten-4"
                placeholder="Search for customer..."
                ref={searchCustomerInputRef}
                value={searchCustomer}
                onChange={(e) => {
                  if (searchType === "product") setSearchType("customer");
                  setSearchCustomer(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>

            <article
              className="custom-dropdown"
              ref={searchCustomerDropdownRef}
            >
              {
                <ul className="collection">
                  {customerList?.length === 0 ? (
                    <li className="collection-item">
                      No suppliers matching "{searchCustomer}"
                    </li>
                  ) : (
                    customerList?.map((customer, index) => (
                      <li
                        className={`collection-item flex space-between align-center hoverable ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          searchCustomerDropdownRef.current.style.display =
                            "none";
                          setSearchCustomer("");
                          setTabs((prevTabs) => {
                            const newTabs = [...prevTabs];
                            newTabs[tabIndex].customer = {
                              name: customer.name,
                              _id: customer._id,
                              phoneNumber: customer.phoneNumber,
                              city: customer.city,
                            };
                            newTabs[tabIndex].title = customer.name;
                            return newTabs;
                          });
                        }}
                      >
                        <div>
                          <p className="capitalize">{customer?.name}</p>
                          <p>{customer?.phoneNumber}</p>
                        </div>

                        <div className="flex  gap-1">
                          <div>
                            <p className="capitalize">{customer?.address}</p>
                            <p className="capitalize">{customer?.city}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              }
            </article>
          </div>
          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span onClick={() => setShowAddCustomer(true)}>Add Customer</span>
          </Dropdown>
        </div>
      </header>

      <main className="sales-main">
        <section>
          {tab.customer?._id && (
            <div className="flex space-between">
              <div
                style={{
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <p className="capitalize">
                  {tab.customer?.name}, {tab.customer?.phoneNumber},{" "}
                  {tab.customer?.city}
                </p>
              </div>

              {tab.salesType === "credit" && (
                <div className="orange sales-type-chip">Credit</div>
              )}
            </div>
          )}
          {tab.products.length === 0 ? (
            <div
              className="flex justify-center align-center"
              style={{ height: "100%" }}
            >
              <div className="flex column align-center grey-text">
                <i className="material-symbols-outlined">list_alt</i>
                <h5>No Products Added</h5>
                <>Start adding products by searching for them</>
              </div>
            </div>
          ) : (
            tab.products.map((item, index) => {
              return (
                <>
                  <div className="product-card " key={index}>
                    <div className="product-details">
                      <p>{index + 1}</p>
                      <div>
                        <p className="small-text uppercase">
                          {item.manufacturer}
                        </p>
                        <div className="flex gap-1">
                          <p className="uppercase bold">{item.name}</p>
                          <p>{item.size}</p>
                          <p className="grey-text">{item.type}</p>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div
                        className="flex column gap-1 align-center  space-between"
                        style={{ width: "fit-content" }}
                      >
                        <p className="small-text grey-text">Quantity</p>

                        <div className="flex gap-1 align-center">
                          <button
                            className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                            onClick={() => {
                              increaseProductQuantity(
                                tabIndex,
                                item,
                                tabs,
                                setTabs,
                                index
                              );
                            }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                          </button>

                          <h6
                            onClick={() => {
                              setSelectedInput("quantity");
                              setProductIndex(index);
                              setShowQuantityPriceModal(true);
                            }}
                          >
                            {item.quantity}
                          </h6>

                          <button
                            className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                            onClick={() => {
                              decreaseProductQuantity(
                                tabIndex,
                                item,
                                tabs,
                                setTabs,
                                index
                              );
                            }}
                          >
                            <span className="material-symbols-outlined">
                              remove
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">Unit Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("price");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amount(item.price)}
                        </h6>
                      </div>
                    </div>

                    <div className="flex column gap-1 align-center space-between">
                      <p className="small-text grey-text">Subtotal</p>
                      <h6
                        role="button"
                        onClick={() => {
                          setSelectedInput("total");
                          setProductIndex(index);
                          setShowQuantityPriceModal(true);
                        }}
                      >
                        {amountWithoutCurrency(item.total)}
                      </h6>
                    </div>
                    {branch.wholesale && (
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">Wholesale Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("wholesalePrice");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amountWithoutCurrency(item.wholesalePrice)}
                        </h6>
                      </div>
                    )}

                    <div>
                      <button
                        className="btn-floating waves-effect waves-light  flex justify-center align-center red"
                        onClick={() => {
                          removeProduct(tabIndex, item, tabs, setTabs, index);
                        }}
                      >
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* <div className="product-card-mobile" key={index}>
                    <div className="flex space-between">
                      <div className="product-details">
                        <p>{index + 1}</p>
                        <div>
                          <p className="small-text">{item.manufacturer}</p>
                          <p>{item.name}</p>
                        </div>
                      </div>

                      <div className="">
                        <div
                          className="flex column gap-1 align-center  space-between"
                          style={{ width: "fit-content" }}
                        >
                          <p className="small-text grey-text">Quantity</p>

                          <div className="flex gap-1 align-center">
                            <button
                              className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                              onClick={() => {
                                increaseProductQuantity(
                                  tabIndex,
                                  item,
                                  tabs,
                                  setTabs
                                );
                              }}
                            >
                              <span className="material-symbols-outlined">
                                add
                              </span>
                            </button>

                            <h6
                              onClick={() => {
                                setSelectedInput("quantity");
                                setProductIndex(index);
                                setShowQuantityPriceModal(true);
                              }}
                            >
                              {item.quantity}
                            </h6>

                            <button
                              className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                              onClick={() => {
                                decreaseProductQuantity(
                                  tabIndex,
                                  item,
                                  tabs,
                                  setTabs
                                );
                              }}
                            >
                              <span className="material-symbols-outlined">
                                remove
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-between mt-1">
                      <div className="">
                        <p className="small-text grey-text">Unit Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("price");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amount(item.price)}
                        </h6>
                      </div>

                      <div className="">
                        <p className="small-text grey-text">Subtotal</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("total");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amountWithoutCurrency(item.total)}
                        </h6>
                      </div>
                    </div>

                    <div className="flex align-center space-between gap-1">
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">
                          Closest Expiry Date
                        </p>
                        <div className="flex align-center gap-1 datepicker white">
                          <DatePicker
                            className="browser-default "
                            onChange={(date) => {
                              date = new Date(date).toJSON().slice(0, 10);

                              updateProductOnTab(
                                tabIndex,
                                {
                                  ...item,
                                  expiryDate: date,
                                },
                                tabs,
                                setTabs
                              );
                            }}
                            value={item.expiryDate}
                          />
                          <span className="material-symbols-outlined">
                            calendar_month
                          </span>
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn-floating waves-effect waves-light  flex justify-center align-center red"
                          onClick={() => {
                            removeProduct(tabIndex, item, tabs, setTabs);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </button>
                      </div>
                    </div>
                  </div> */}
                </>
              );
            })
          )}
        </section>

        <aside>
          <div className=" ml-2 flex justify-end ">
            {tabs?.length > 1 && (
              <i
                className="material-symbols-outlined red-text"
                onClick={() => {
                  if (tabs.length === 1)
                    return notify("Cannot close last tab", "info");
                  let newTabs = [...tabs];
                  newTabs.splice(tabIndex, 1);
                  setTabs(newTabs);
                }}
              >
                close
              </i>
            )}
          </div>
          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Subtotal</p>
              <h6 className="bold">{calculateTotal(tab)}</h6>
            </div>
            <div className="flex space-between align-center">
              <p className="grey-text">Discount</p>
              <h6 className="bold">0.00</h6>
            </div>
            <div className="flex space-between align-center">
              <p className="grey-text">Tax</p>
              <h6 className="bold">0.00</h6>
            </div>
          </section>

          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Total Amount</p>
              <h5 className="bold">{amount(calculateTotal(tab))}</h5>
            </div>
          </section>

          <div className="flex mt-1 gap-1">
            <button
              className="btn-large"
              style={{ width: "100%" }}
              onClick={() => {
                setShowSaveSaleModal(true);
              }}
              disabled={tab.products.length === 0}
            >
              Save Sale
            </button>
          </div>
        </aside>
      </main>
    </div>
  );
};

export default NonTodaySaleSection;

import React, { useEffect } from "react";
import { Autocomplete, Icon } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { addProduct, updateProduct } from "../redux/actions/products";
import { getBranch } from "../redux/actions/branches";

const EditProduct = ({ product, setShowEditProductModal, branchId }) => {
  const { branches } = useSelector((state) => state.branch);
  const { account } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.product);
  const [values, setValues] = React.useState({
    name: "",
    manufacturer: "",
    nickname: "",
    type: "",
    size: "",
    factoryPrice: "",
    branches: [],
  });
  const [chipData, setChipData] = React.useState([]);
  const [autocompleteData, setAutocompleteData] = React.useState({});
  const dispatch = useDispatch();
  const handleAutocomplete = (value) => {
    if (chipData.includes(value)) return;
    setChipData([...chipData, value]);
  };

  useEffect(() => {
    setValues({
      name: product.name,
      manufacturer: product.manufacturer,
      nickname: product.nickname,
      type: product.type.toLowerCase(),
      size: product.size,
      factoryPrice: product.factoryPrice,
    });
  }, [
    product.factoryPrice,
    product.manufacturer,
    product.name,
    product.nickname,
    product.size,
    product.type
  ]);

  const send = (e) => {
    e.preventDefault();
    const data = {
      name: values.name,
      manufacturer: values.manufacturer,
      nickname: values.nickname,
      type: values.type,
      size: values.size,
      factoryPrice: values.factoryPrice,
    };

    dispatch(
      updateProduct(product._id, data, () => {
        setValues({
          name: "",
          manufacturer: "",
          nickname: "",
          type: "",
          size: "",
          factoryPrice: "",
          branches: [],
        });
        setShowEditProductModal(false);
        if (branchId) dispatch(getBranch(branchId));
      })
    );
  };

  return (
    <form onSubmit={send} className="mt-2 p-2 ">
      <h4>Edit Product</h4>

      <div className="mb-2">
        <p>
          Enter Product Name
          <span className="red-text"> * </span>
        </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. Paracetamol Tab"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          required
        />
      </div>

      <div className="mb-2">
        <p>Enter Product Nickname or Other Names </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. Para"
          value={values.nickname}
          onChange={(e) => setValues({ ...values, nickname: e.target.value })}
        />
      </div>

      <div className="mb-2">
        <p>
          Enter Product Manufacturer
          <span className="red-text"> * </span>
        </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. Letap Pharmaceuticals"
          value={values.manufacturer}
          onChange={(e) =>
            setValues({ ...values, manufacturer: e.target.value })
          }
          required
        />
      </div>

      <div className="mb-2">
        <p>
          Choose Product Type
          <span className="red-text"> * </span>
        </p>
        <select
          className="browser-default custom-select"
          required
          onChange={(e) => setValues({ ...values, type: e.target.value })}
          value={values.type}
        >
          <option value="" selected>
            Select Product Type
          </option>
          <option value="Tablet">Tablet</option>
          <option value="Capsule">Capsule</option>
          <option value="Syrup">Syrup</option>
          <option value="Injection">Injection</option>
          <option value="Cream">Cream</option>
          <option value="Powder">Powder</option>
          <option value="Drops">Drops</option>
          <option value="Ointment">Ointment</option>
          <option value="Gel">Gel</option>
          <option value="Lotion">Lotion</option>
          <option value="Suspension">Suspension</option>
          <option value="Solution">Solution</option>
          <option value="Aerosol">Aerosol</option>
          <option value="Mouth Wash">Mouth Wash</option>
          <option value="Shampoo">Shampoo</option>
          <option value="Soap">Soap</option>
          <option value="Spray">Spray</option>
          <option value="Drops">Drops</option>
          <option value="Oil">Oil</option>
          <option value="Suppository">Suppository</option>
          <option value="Sachet">Sachet</option>
          <option value="mixture">mixture</option>
        </select>
      </div>

      <div className="mb-2">
        <p>Enter Product Size </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. 500ml (Medium Size)"
          value={values.size}
          onChange={(e) => setValues({ ...values, size: e.target.value })}
        />
      </div>

      <div className="mb-2">
        <p>Enter Product Factory Price </p>
        <input
          type="number"
          className="browser-default
               custom-text-input"
          placeholder="Eg. 10.00"
          value={values.factoryPrice}
          onChange={(e) =>
            setValues({ ...values, factoryPrice: e.target.value })
          }
          min="0"
          step="0.01"
        />
      </div>

      <button className="btn" type="submit">
        {loading ? (
          <ClipLoader color="#fff" size={20} />
        ) : (
          <span>Update Product</span>
        )}
      </button>
    </form>
  );
};

export default EditProduct;

const authReducer = (
  state = {
    loading: false,
    account: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "LOGGING_IN":
      return {
        ...state,
        loading: true,
      };

    case "LOGGED_IN":
      return {
        ...state,
        loading: false,
      };

    case "LOGIN_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
      };

    case "GETTING_ACCOUNT":
      return {
        ...state,
        loading: true,
      };

    case "GOT_ACCOUNT":
      return {
        ...state,
        loading: false,
        account: action.payload,
      };

    case "GET_ACCOUNT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;

import React from "react";
import { HomeButton } from "../../../components/Buttons";
import AddProduct from "../../../components/AddProduct";
import Modal from "react-responsive-modal";
import { DatePicker } from "react-materialize";

const NonTodaySalesHeader = ({ branch, date, setDate }) => {
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <header className="flex full-width  space-between p-2 sales-header align-center  ">
      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct
          setShowProductModal={setShowProductModal}
          branchId={branch?._id}
        />
      </Modal>
      <div className="flex align-center">
        <HomeButton />
        <div>
          <h5 className="margin-0">{branch?.name}</h5>
          <p className="small-text">Add Non Today Sales</p>
        </div>
      </div>

      <div className=" flex pointer">
        <DatePicker
          className="browser-default datepicker pointer"
          style={{ width: "100%" }}
          options={{
            //maxdate to be yesterday
            maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
          }}
          placeholder="Choose Day"
          onChange={(date) => {
            date = new Date(date).toJSON().slice(0, 10);
            setDate(date);
          }}
          value={date}
        />
      </div>
    </header>
  );
};

export default NonTodaySalesHeader;

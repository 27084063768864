import React from "react";

const Card = ({ title, description, icon, color, onClick }) => {
  let showColor = color ? color : "white";

  return (
    <div
      className={`my-card  ${showColor}`}
      onClick={onClick ? onClick : () => {}}
    >
      <header className="flex space-between ">
        <p></p>
        <div>
          <span className={`material-symbols-outlined ${showColor}-text`}>
            {icon}
          </span>
        </div>
      </header>
      <span className="material-symbols-outlined">{icon}</span>

      <h6>{title}</h6>
      <p className="small-text">{description}</p>
    </div>
  );
};

export default Card;

import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomers } from "../../redux/actions/customers";
import { getBranch } from "../../redux/actions/branches";
import isEmpty from "lodash/isEmpty";
import { Dropdown, ProgressBar } from "react-materialize";

const Customer = () => {
  const { branch } = useSelector((state) => state.branch);
  const { customers, loading } = useSelector((state) => state.customers);
  const branchId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState("");
  const [list, setList] = React.useState([]);

  useEffect(() => {
    dispatch(getCustomers(branchId));
    if (isEmpty(branch) || branch._id !== branchId) {
      dispatch(getBranch(branchId));
    }
  }, [branchId, dispatch]);

  useEffect(() => {
    if (keyword) {
      const filteredList = customers.filter((item) => {
        return (
          item.name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.city.toLowerCase().includes(keyword.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(keyword.toLowerCase()) ||
          item.address.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      setList(filteredList);
    } else {
      setList(customers);
    }
  }, [keyword, customers]);

  return (
    <div>
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">My Customers</h5>
            <p className="">{branch?.name}</p>
          </div>
        </div>
        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input "
              placeholder="Search for customer"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        </div>

        <Dropdown
          trigger={<a className="btn-small white teal-text">I want to...</a>}
        >
          <span>Add New Customer</span>
          <span>Export Customers</span>
          <span
            onClick={() => {
              navigate("/debtors/" + branchId);
            }}
          >
            {" "}
            View Debtors
          </span>
        </Dropdown>
      </header>

       {loading && <ProgressBar />}

      <div>
        <table className="striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Account</th>
              <th>City</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {list?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className="capitalize">{item.name}</td>
                <td className="capitalize">{item.city}</td>
                <td className="capitalize">{item.phoneNumber}</td>
                <td className="capitalize">{item.address}</td>
                <td className="capitalize">
                  <button
                    className="btn-small"
                    onClick={() => {
                      dispatch({ type: "SET_CUSTOMER", payload: item });
                      navigate(`/customer-profile/${item._id}`);
                    }}
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customer;

import React from "react";
import { useSelector } from "react-redux";
import { amount } from "../../../helpers/helper";

const Expenses = ({ expenses }) => {
  useSelector((state) => state.analytics);

  return (
    <div className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Expenses Recorded</h5>
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th>Registered By</th>
            <th>Total Amount</th>
            <th> Description </th>
          </tr>
        </thead>

        <tbody>
          {expenses?.map((expense) => (
            <tr key={expense._id}>
              <td>{expense.account?.name}</td>
              <td>{amount(expense.amount)}</td>
              <td>{expense.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Expenses;

import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import SaleModal from "../sale/SaleModal";
import ConfirmDeleteSale from "../sale/components/ConfirmDeleteSale";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { amount } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import { getCustomer } from "../../redux/actions/customers";

const PurchaseHistoryAll = () => {
  const { customer, loading } = useSelector((state) => state.customers);
  const [sale, setSale] = React.useState({});
  const [showSaleModal, setShowSaleModal] = React.useState(false);
  const [showDeleteSaleModal, setShowDeleteSaleModal] = React.useState(false);

  const { branch } = useSelector((state) => state.branch);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCustomer(id));
  }, [dispatch, id]);

  return (
    <div>
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Purchase History</h5>
            <p className="">{customer?.name}</p>
          </div>
        </div>
      </header>

      {loading && <ProgressBar />}

      <SaleModal
        showSaleModal={showSaleModal}
        setShowSaleModal={setShowSaleModal}
      />
      <ConfirmDeleteSale
        setShowConfirmDeleteSaleModal={setShowDeleteSaleModal}
        showConfirmDeleteSaleModal={showDeleteSaleModal}
        sale={sale}
        branchId={branch._id}
      />

      <main>
        <table className="">
          <thead>
            <tr>
              <th>#</th>
              <th>Sold By</th>
              <th>Date & Time</th>
              <th>Invoice Type</th>
              <th>Total Amount</th>
              <th>Amount Paid</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {[...customer.sales].reverse().map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <p>{item.seller?.name}</p>
                  </td>
                  <td>
                    <p>
                      {new Date(item.createdAt).toDateString()},{" "}
                      {new Date(item.createdAt).toLocaleTimeString()}{" "}
                    </p>
                  </td>

                  <td>
                    <h6
                      className={`${
                        item.salesType === "credit" ? "red-text" : ""
                      }`}
                    >
                      {item.salesType}
                    </h6>
                  </td>

                  <td>
                    <h6>{amount(item.total)}</h6>
                  </td>

                  <td>
                    <p>{amount(item.paid)}</p>
                  </td>

                  <td className="">
                    <div className="flex align-center">
                      <button
                        className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                        onClick={() => {
                          setShowSaleModal(true);
                          dispatch({ type: "GOT_SALE", payload: item });
                        }}
                      >
                        View
                      </button>
                      <Dropdown
                        trigger={
                          <i className="material-symbols-outlined ">
                            more_vert
                          </i>
                        }
                      >
                        <a
                          href="#!"
                          onClick={() => {
                            console.log(item.products);
                            const products = item.products.map((product) => {
                              const data = {
                                manufacturer: product.product?.manufacturer,
                                name: product.product?.name,
                                price: product.price,
                                quantity: product.quantity,
                                total: product.total,
                                _id: product.product?._id,
                              };

                              return data;
                            });

                            const tab = {
                              id: 1,
                              title: "Sales Tab 1",
                              active: true,
                              products,
                              branch: branch._id,
                              customer: {
                                name:
                                  item.customer?.name || "No customer selected",
                                id: item.customer?._id || "",
                                phoneNumber: item.customer?.phoneNumber || "",
                              },
                              priceMode:
                                item.priceMode || branch.wholesale
                                  ? "wholesale"
                                  : "retail",
                              salesType: item.salesType || "cash",
                              saleId: item._id,
                              saved: true,
                            };

                            dispatch({
                              type: "GOT_SALES_RECEIPT_TAB",
                              payload: tab,
                            });

                            navigate("/sale/" + branch._id);
                          }}
                        >
                          Edit Sale{" "}
                        </a>
                        <a
                          href="#!"
                          onClick={() => {
                            setSale(item);
                            setShowDeleteSaleModal(true);
                          }}
                        >
                          Delete Sale{" "}
                        </a>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    </div>
  );
};

export default PurchaseHistoryAll;

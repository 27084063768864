import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeButton } from "../../components/Buttons";
import { useSelector } from "react-redux";

const InventoryList = () => {
  const navigate = useNavigate();
  const { branch } = useSelector((state) => state.branch);

  return (
    <div className="main">
      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <HomeButton />
          <h5>Inventory List</h5>
        </div>

        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input"
              placeholder="search for product..."
            />
          </div>
          <button className="btn">search</button>
        </div>
      </header>

      <section
        style={{ maxHeight: "calc(100vh - 100px)", overflowY: "scroll" }}
        className="p-2"
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
          return (
            <div className="product-card ">
              <p>{item}</p>

              <div style={{ maxWidth: "30%" }}>
                <p className="small-text">Joy Industries</p>
                <h6>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </h6>
                <p>XL</p>
              </div>

              <div className="">
                <div
                  className="flex column gap-1 align-center"
                  style={{ width: "fit-content" }}
                >
                  <p className="small-text grey-text">Quantity In Stock</p>

                  <div className="flex gap-1 align-center">
                    <h6>1</h6>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex column gap-1 align-center">
                  <p className="small-text grey-text">Wholesale Price</p>
                  <h6>120.00</h6>
                </div>
              </div>

              <div>
                <div className="flex column gap-1 align-center">
                  <p className="small-text grey-text">Retail Price</p>
                  <h6>120.00</h6>
                </div>
              </div>

              <div>
                <div className="flex column gap-1 align-center">
                  <p className="small-text grey-text">Factory Price</p>
                  <h6>120.00</h6>
                </div>
              </div>

              <div>
                <p className="small-text grey-text mb-1">Actions</p>
                <div className="flex gap-1">
                  <button className="btn waves-effect waves-light  flex justify-center align-center ">
                    Change Price
                  </button>
                  <button className="btn waves-effect waves-light  flex justify-center align-center white teal-text">
                    Sell Product
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default InventoryList;

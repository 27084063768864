export const inventoryReducer = (
  state = {
    inventoryReceipts: [],
    loading: false,
    inventoryReceipt: {},
    inventoryReceiptTab: {},
  },
  action
) => {
  switch (action.type) {
    case "ADDING_INVENTORY_RECEIPT":
      return {
        ...state,
        loading: true,
      };

    case "INVENTORY_RECEIPT_ADDED":
      return {
        ...state,
        loading: false,
        inventoryReceipts: [...state.inventoryReceipts, action.payload],
      };

    case "ADD_INVENTORY_RECEIPT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_INVENTORY_RECEIPTS":
      return {
        ...state,
        loading: true,
      };

    case "GOT_INVENTORY_RECEIPTS":
      return {
        ...state,
        loading: false,
        inventoryReceipts: action.payload,
      };

    case "GET_INVENTORY_RECEIPTS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GOT_INVENTORY_RECEIPT":
      return {
        ...state,
        inventoryReceipt: action.payload,
      };

    case "GOT_INVENTORY_RECEIPT_TAB":
      return {
        ...state,
        inventoryReceiptTab: action.payload,
      };

    case "RESET_INVENTORY_RECEIPT_TAB":
      return {
        ...state,
        inventoryReceiptTab: [],
      };

    case "DELETING_INVENTORY_RECEIPT":
      return {
        ...state,
        loading: true,
      };

    case "INVENTORY_RECEIPT_DELETED":
      return {
        ...state,
        loading: false,
      };

    case "DELETE_INVENTORY_RECEIPT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_INVENTORY_RECEIPT":
      return {
        ...state,
        loading: true,
      };

    case "INVENTORY_RECEIPT_UPDATED":
      return {
        ...state,
        loading: false,
        inventoryReceipts: state.inventoryReceipts.map((receipt) =>
          receipt._id === action.payload._id ? action.payload : receipt
        ),
      };

    case "UPDATE_INVENTORY_RECEIPT_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

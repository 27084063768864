import React from "react";
import Modal from "react-responsive-modal";
import { updateStockPrice } from "../../../redux/actions/products";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../../redux/actions/branches";
import { ClipLoader } from "react-spinners";
import { Checkbox } from "react-materialize";

const ChangePriceModal = ({
  product,
  setShowChangePriceModal,
  showChangePriceModal,
  branchId,
  priceType,
}) => {
  const [price, setPrice] = React.useState(product[priceType]);
  const [retailPrice, setRetailPrice] = React.useState(0);
  const [autoCalculate, setAutoCalculate] = React.useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.product);
  const { branch } = useSelector((state) => state.branch);

  React.useEffect(() => {
    setPrice(product.quantity);
  }, [product.quantity]);

  function updateProduct() {
    const data = {
      product: product.details?._id,
      retailPrice,
      [`${priceType}Price`]: price,
    };
    if (!autoCalculate && priceType !== "retail") delete data.retailPrice;

    dispatch(
      updateStockPrice(branchId, data, () => {
        setShowChangePriceModal(false);
        setPrice(0);
        setAutoCalculate(false);
        setRetailPrice(0);
        dispatch(getBranch(branchId));
      })
    );
  }

  console.log(retailPrice);

  return (
    <Modal
      onClose={() => {
        setShowChangePriceModal(false);
      }}
      open={showChangePriceModal}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      center
      closeOnEsc={false}
    >
      <form
        className="p-2"
        onSubmit={(e) => {
          e.preventDefault();
          updateProduct();
        }}
      >
        <div className="modal-header mb-2">
          <h5 className="modal-title">{`Change ${priceType} Price`}</h5>
        </div>

        <div className="flex mb-2 gap-1 align-end">
          <div>
            <p className="small-text  mb-1">Change Price</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Price"
                value={price}
                min="0"
                step="0.01"
                onChange={(e) => {
                  setPrice(e.target.value);

                  let rp =
                    parseFloat(e.target.value) * branch.retailPricePercentage +
                    parseFloat(e.target.value);

                  if (isNaN(rp)) rp = 0;
                  if (branch.roundRetailPrices) rp = Math.round(rp);
                  setRetailPrice(rp);
                }}
              />
            </div>
            {autoCalculate && (
              <p className="mt-1">Retail Price: {retailPrice}</p>
            )}
          </div>
        </div>

        <div>
          {priceType === "factory" && (
            <Checkbox
              id="Checkbox_1"
              label="Auto calculate Retail Price"
              value="Red"
              checked={autoCalculate}
              onChange={() => {
                autoCalculate
                  ? setAutoCalculate(false)
                  : setAutoCalculate(true);
              }}
            />
          )}
        </div>

        <div className="flex gap-1 mt-2">
          <button type="submit" className="btn" disabled={loading}>
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              `Change ${priceType} Price`
            )}
          </button>
          <button
            type="button"
            className="btn white teal-text"
            onClick={() => {
              setShowChangePriceModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePriceModal;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { amount } from "../../helpers/helper";
import { deletePayment, getCustomer } from "../../redux/actions/customers";

const DeletePayment = ({
  showDeletePaymentModal,
  setShowDeletePaymentModal,
  customer,
  payment,
}) => {
  const { loading } = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  function saveInventory(e) {
    e.preventDefault();

    dispatch(
      deletePayment(payment?._id, () => {
        setShowDeletePaymentModal(false);
        dispatch(getCustomer(customer._id));
      })
    );
  }

  return (
    <Modal onClose={() => {}} open={showDeletePaymentModal} center>
      <form onSubmit={saveInventory} className="mt-2 p-2">
        <h5 className="mb-1">Confirmation</h5>
        <p>Are you sure you want to delete this payment?</p>

        <div className="mt-2">
          <p className="capitalize">{customer?.name}</p>
          <p className="bold capitalize mt-1 margin-0">
            {amount(payment.amount)}
          </p>
        </div>

        <div className="mt-2 gap-1 flex ">
          <button type="submit" className="btn">
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              "Delete Payment"
            )}
          </button>

          <button
            className="btn grey lighten-1"
            onClick={() => {
              setShowDeletePaymentModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeletePayment;

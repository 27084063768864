import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getBranch } from "../../redux/actions/branches";
import { useDispatch, useSelector } from "react-redux";
import NonTodaySalesHeader from "./components/NonTodaySalesHeader";
import NonTodaySaleSection from "./NonTodaySaleSection";
import { getCustomers } from "../../redux/actions/customers";
import { locallySavedTabs } from "./actions";

const NonTodaySale = () => {
  const branchId = useParams().id;
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branch);
  const [date, setDate] = React.useState("");
  const [tabs, setTabs] = React.useState([
    {
      id: 1,
      title: "Sales Tab 1",
      active: true,
      products: [],
      priceMode: branch.wholesale ? "wholesale" : "retail",
      supplier: {
        name: "No Supplier Selected",
        id: "",
        phoneNumber: "N/A",
      },
      tabType: "nonTodaySales",
      branch: branchId,
    },
  ]);

  useEffect(() => {
    if (branchId) dispatch(getBranch(branchId));
    dispatch(getCustomers(branchId));
  }, [dispatch, branchId]);

  useEffect(() => {
    locallySavedTabs(branchId, setTabs, "nonTodaySalesTab");
  }, [branchId]);

  return (
    <div>
      <NonTodaySalesHeader branch={branch} setDate={setDate} date={date} />
      <section className="sales-section">
        <NonTodaySaleSection
          tab={tabs[0]}
          setTabs={setTabs}
          tabs={tabs}
          tabIndex={0}
          date={date}
        />
      </section>
    </div>
  );
};

export default NonTodaySale;

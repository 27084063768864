import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  deleteInventoryReceipt,
  getInventoryReceipts,
} from "../../../redux/actions/inventory";

const ConfirmDeleteInventoryReceiptModal = ({
  showDeleteInventoryReceiptModal,
  setShowDeleteInventoryReceiptModal,
  id,
}) => {
  const dispatch = useDispatch();
  const { loading, inventoryReceipt } = useSelector((state) => state.inventory);

  function deleteInventory(e) {
    e.preventDefault();
    dispatch(
      deleteInventoryReceipt(inventoryReceipt._id, () => {
        setShowDeleteInventoryReceiptModal(false);
      })
    );
    dispatch(getInventoryReceipts(id));
  }

  return (
    <Modal
      onClose={() => {
        setShowDeleteInventoryReceiptModal(false);
      }}
      open={showDeleteInventoryReceiptModal}
      center
    >
      <form className="mt-2 p-2">
        <h5 className="mb-1">Confirmation</h5>
        <p>
          Are you sure you want to delete this invetory receival? This will
          deduct the stock from the inventory
        </p>

        <div className="mt-2 gap-1 flex ">
          <button type="button" className="btn" onClick={deleteInventory}>
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Delete"}
          </button>

          <button
            className="btn grey lighten-1"
            type="button"
            onClick={(e) => {
              setShowDeleteInventoryReceiptModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmDeleteInventoryReceiptModal;

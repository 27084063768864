import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { addCustomer, getCustomers } from "../../../redux/actions/customers";

const AddCustomerModal = ({ show, setShow, branchId }) => {
  const { loading } = useSelector((state) => state.customers);

  const [values, setValues] = React.useState({
    name: "",
    phoneNumber: "",
    address: "",
    city: "",
  });

  const dispatch = useDispatch();

  function saveSale(e) {
    e.preventDefault();

    dispatch(
      addCustomer(
        {
          name: values.name,
          phoneNumber: values.phoneNumber,
          address: values.address,
          city: values.city,
          branch: branchId,
        },
        () => {
          setShow(false);
          dispatch(getCustomers(branchId));
        }
      )
    );
  }

  return (
    <Modal
      onClose={() => {
        setShow(false);
      }}
      open={show}
      center
      styles={{
        //style modal to be at the bottom of the page
        modal: {
          //   width: "500px",
          padding: "20px",
          borderRadius: "10px",
          minHeight: "50vh",
        },
      }}
    >
      <form onSubmit={saveSale} className="mt-2 p-2">
        <h5 className="mb-1">Add Customer</h5>

        <div className="mb-2">
          <p>
            Enter Customer Name
            <span className="red-text"> * </span>
          </p>
          <input
            type="text"
            className="browser-default
               custom-text-input"
            placeholder="Eg. John Doe"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            required
          />
        </div>

        <div className="mb-2">
          <p>
            Enter Customer Phone Number
            <span className="red-text"> * </span>
          </p>
          <input
            type="number"
            className="browser-default
               custom-text-input"
            placeholder="Eg. 0700000000"
            value={values.phoneNumber}
            onChange={(e) =>
              setValues({ ...values, phoneNumber: e.target.value })
            }
            required
          />
        </div>

      <div className="mb-2">
          <p>
            Enter Customer Address
            <span className="red-text"> * </span>
          </p>
          <input
            type="text"
            className="browser-default
               custom-text-input"
            placeholder="Eg. somewhere, spintex, accra, Ghana"
            value={values.address}
            onChange={(e) => setValues({ ...values, address: e.target.value })}
            required
          />
        </div>

        <div className="mb-2">
          <p>
            Enter Customer City
            <span className="red-text"> * </span>
          </p>
          <input
            type="text"
            className="browser-default
               custom-text-input"
            placeholder="Eg. spintex"
            value={values.city}
            onChange={(e) => setValues({ ...values, city: e.target.value })}
            required
          />
        </div>

        <button
          className="btn"
          disabled={
            loading ||
            !values.name ||
            !values.phoneNumber ||
            !values.address ||
            !values.city
          }
        >
          {loading ? <ClipLoader color="#ffffff" size={20} /> : `Add Customer`}
        </button>
      </form>
    </Modal>
  );
};

export default AddCustomerModal;

import React from "react";
import { HomeButton } from "../../components/Buttons";
import { Dropdown } from "react-materialize";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Branch = () => {
  const { branches } = useSelector((state) => state.branch);
  const navigate = useNavigate();
  return (
    <div className="main">
      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <HomeButton />
          <h5>My Branches</h5>
        </div>

        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input"
              placeholder="search for product..."
            />
          </div>
          <button className="btn">search</button>
        </div>
      </header>

      <section className="p-2 container main-body">
        <p className="mb-1">List of Branches</p>
        {branches.map((branch, index) => (
          <div className="box flex align-center space-between mb-1" key={index}>
            <div className="flex align-center">
              <i
                className="material-symbols-outlined mr-1 grey-text"
                style={{ fontSize: "4rem" }}
              >
                store
              </i>
              <div>
                <h5>{branch.name}</h5>
                <p>0{branch.phoneNumber}</p>
              </div>
            </div>

            <div>
              <p className="small-text grey-tex"> Total Members</p>
              <h5>{branch.members?.length} members</h5>
            </div>

            <div>
              <p className="small-text grey-tex"> Total Products</p>
              <h5> {branch.products?.length} Products</h5>
            </div>

            <div className="flex align-center">
              <button
                className="btn mr-1"
                onClick={() => navigate(`/branch/${branch._id}`)}
              >
                View Branch
              </button>
              <Dropdown
                id="Dropdown_6"
                trigger={
                  <i className="material-symbols-outlined ">more_vert</i>
                }
              >
                <a href="#!">Edit</a>
                <a href="#!">Delete</a>
              </Dropdown>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Branch;

import React from "react";
import { amount } from "../../../helpers/helper";

const ProductSearchDropDown = ({
  searchProduct,
  setSearchProduct,
  productsList,
  highlightedIndex,
  searchProductDropdownRef,
  setShowProductSaleHistoryModal,
  inputRef,
  handleKeyDown,
  setSelectedProduct,
}) => {
  return (
    <div className="relative">
      <div
        className="custom-input-wrapper grey lighten-4 flex "
        style={{ minWidth: "max(300px, 35vw)" }}
      >
        <span className="material-symbols-outlined">search</span>
        <input
          type="text"
          className="browser-default
         custom-input grey lighten-4"
          placeholder="Search for product..."
          ref={inputRef}
          value={searchProduct}
          onChange={(e) => setSearchProduct(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>

      <article className="custom-dropdown" ref={searchProductDropdownRef}>
        {
          <ul className="collection">
            {productsList.length === 0 ? (
              <li className="collection-item">"{searchProduct}"</li>
            ) : (
              productsList.map((product, index) => (
                <li
                  className={`collection-item flex space-between align-center hoverable ${
                    highlightedIndex === index ? "highlighted" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    setShowProductSaleHistoryModal(true);
                    setSelectedProduct(product);
                  }}
                >
                  <div>
                    <label className="uppercase">{product.manufacturer}</label>
                    <div className="flex gap-1">
                      <p className="uppercase">{product.name}</p>
                      <p>{product?.size}</p>
                      <p className="grey-text">{product.type}</p>
                    </div>
                  </div>

                  <div className="flex  gap-1"></div>
                </li>
              ))
            )}
          </ul>
        }
      </article>
    </div>
  );
};

export default ProductSearchDropDown;

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const addProduct = (product, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_PRODUCT" });
  try {
    const res = await POS.post("/api/products/create", product);
    dispatch({ type: "PRODUCT_ADDED", payload: res.data });

    notify("Product Added Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRODUCT_ADD_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const updateProduct = (id, product, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_PRODUCT" });
  try {
    const res = await POS.put("/api/products/update/" + id, product);
    dispatch({ type: "PRODUCT_UPDATED", payload: res.data });
    notify("Product Updated Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRODUCT_UPDATE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const updateStockQuantity = (id, data, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_QUANTITY" });
  try {
    const res = await POS.put(
      "/api/products/update/branch/stock-quantity/" + id,
      data
    );
    dispatch({ type: "QUANTITY_UPDATED", payload: res.data });
    notify("Stock Updated Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "QUANTITY_UPDATE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const updateStockPrice = (id, data, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_PRICE" });
  try {
    const res = await POS.put(
      "/api/products/update/branch/stock-price/" + id,
      data
    );
    dispatch({ type: "PRICE_UPDATED", payload: res.data });
    notify("Price Updated Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRICE_UPDATE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const resetAllStock = (id, cb) => async (dispatch) => {
  dispatch({ type: "RESETING_STOCK" });
  try {
    const res = await POS.put(
      "/api/products/update/branch/clear-all-stock/" + id
    );
    dispatch({ type: "STOCK_RESET", payload: res.data });
    notify("Stock Reset Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "STOCK_RESET_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const deleteProduct = (id, cb) => async (dispatch) => {
  dispatch({ type: "DELETING_PRODUCT" });
  try {
    const res = await POS.delete("/api/products/delete/" + id);
    dispatch({ type: "PRODUCT_DELETED", payload: res.data });
    notify("Product Deleted Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRODUCT_DELETE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const removeStock = (id, data, cb) => async (dispatch) => {
  dispatch({ type: "DELETING_PRODUCT" });
  try {
    const res = await POS.put(
      "/api/products/update/branch/remove-stock/" + id,
      data
    );
    dispatch({ type: "PRODUCT_DELETED", payload: res.data });
    notify("Product Deleted Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRODUCT_DELETE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const addSystemProduct = (id, data, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_PRODUCT" });
  try {
    const res = await POS.post(
      "/api/products/create/system-products/" + id,
      data
    );
    dispatch({ type: "PRODUCT_ADDED", payload: res.data });
    notify("Products added to current branch", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PRODUCT_ADD_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const getAllProducts = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_PRODUCTS" });
  try {
    const res = await POS.get("/api/products/read");
    dispatch({ type: "GOT_ALL_PRODUCTS", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_ALL_PRODUCTS_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

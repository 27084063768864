import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-materialize";
import isEmpty from "lodash/isEmpty";
import { amount } from "../../../helpers/helper";

const InventoryPrintTemplate = () => {
  const branchId = useParams().id;
  const { branch } = useSelector((state) => state.branch);
  const { customers } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = React.useState([]);

  useEffect(() => {
    const debtors = customers.filter((item) => {
      return item.due > 0;
    });

    setList(debtors);
  }, [customers]);

  return (
    <section className="A4">
      <section className="flex align-center space-between mb-1 mt-1">
        <div className="flex align-center">
          <img
            src="/images/baiclass.png"
            alt="logo"
            style={{ width: 50, height: 50 }}
          />
          <div className="flex justify column ml-2">
            <h6 className="margin-0">Baiclass Enterprise</h6>
            <p className="small-text">
              Behind main station, Ashaiman, Accra, Ghana
            </p>

            <div className="flex">
              <p className="small-text">+233 24 318 0605</p> /
              <p className="small-text">+233 54 333 3071 </p>
            </div>
          </div>
        </div>


        <p>{new Date().toDateString()}</p>
      </section>

      <div className="flex justify-center">
        <p className="uppercase bold text-center">Debtors List</p>
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Account Details</th>

            <th>Balance Due</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {list?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="capitalize">
                <p>{item.name}</p>
                <p>
                  {item.phoneNumber}, {item.city}
                </p>
              </td>
              <td className="capitalize">{amount(item.due)}</td>
              <td className="capitalize">
                <button
                  className="btn-small"
                  onClick={() => {
                    dispatch({ type: "SET_CUSTOMER", payload: item });
                    navigate(`/customer-profile/${item._id}`);
                  }}
                >
                  View Profile
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default InventoryPrintTemplate;

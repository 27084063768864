import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, HomeButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { amount } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import DatePicker from "react-datepicker";
import { getSales } from "../../redux/actions/sale";
import SaleModal from "./SaleModal";
import { ClipLoader } from "react-spinners";
import ConfirmDeleteSale from "./components/ConfirmDeleteSale";
import { getBranch } from "../../redux/actions/branches";
import isEmpty from "lodash/isEmpty";
import ProductSaleHistoryModal from "./ProductSaleHistoryModal";
import ProductSearchDropDown from "./components/ProductSearchDropDown";

const SalesHistory = () => {
  const { sales, loading } = useSelector((state) => state.sale);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [showSaleModal, setShowSaleModal] = React.useState(false);
  const [showDeleteSaleModal, setShowDeleteSaleModal] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams().id;
  const { branches, branch } = useSelector((state) => state.branch);
  const inputRef = React.useRef();
  const useQuery = () => {
    return new URLSearchParams(window.location.search);
  };
  const [sale, setSale] = React.useState({});
  const [searchProduct, setSearchProduct] = React.useState("");
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const [productsList, setProductsList] = React.useState([]);
  const [productsWithinRange, setProductsWithinRange] = React.useState([]);
  const [productSales, setProductSales] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const searchProductDropdownRef = React.useRef();
  const [showProductSaleHistoryModal, setShowProductSaleHistoryModal] =
    React.useState(false);

  const query = useQuery();
  const start = query.get("start");
  const end = query.get("end");

  const onChange = (dates) => {
    let [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  React.useEffect(() => {
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
  }, []);

  React.useEffect(() => {
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, [end, start]);

  React.useEffect(() => {
    if (!id || !startDate || !endDate) return;
    navigate(
      `/sales-history/${id}?start=${new Date(startDate)
        .toJSON()
        .slice(0, 10)}&end=${new Date(endDate).toJSON().slice(0, 10)}`
    );
    dispatch(getSales(id, startDate, endDate));
  }, [dispatch, endDate, id, navigate, startDate]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, productsList.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      const product = productsList[highlightedIndex];
      searchProductDropdownRef.current.style.display = "none";
    }
  };

  React.useEffect(() => {
    if (searchProduct.length >= 1) {
      searchProductDropdownRef.current.style.display = "block";
      setProductsList(
        branch?.products.filter(
          (product) =>
            product.details?.name
              .toLowerCase()
              .includes(searchProduct.toLowerCase()) ||
            product.details?.manufacturer
              .toLowerCase()
              .includes(searchProduct.toLowerCase()) ||
            product.details?.nickname
              .toLowerCase()
              .includes(searchProduct.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchProductDropdownRef.current.style.display = "none";
  }, [branch?.products, searchProduct]);

  React.useEffect(() => {
    dispatch(getBranch(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (isEmpty(sales)) return;
    setProductsList(
      productsWithinRange.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchProduct.toLowerCase()) ||
          item.nickname?.toLowerCase().includes(searchProduct.toLowerCase())
      )
    );
  }, [productsWithinRange, sales, searchProduct]);

  React.useEffect(() => {
    if (isEmpty(branch) || isEmpty(sales)) return;

    let productsSoldWithinRange = [];

    sales.forEach((sale) => {
      sale.products.forEach((product) => {
        const productIndex = productsSoldWithinRange.findIndex(
          (item) => item._id === product?.product?._id
        );

        if (productIndex === -1) {
          productsSoldWithinRange.push({
            ...product.product,
            sales: [sale],
            quantitySold: product.quantity,
          });
        } else {
          productsSoldWithinRange[productIndex].sales.push(sale);
          productsSoldWithinRange[productIndex].quantitySold +=
            product.quantity;
        }
      });
    });

    setProductsWithinRange(productsSoldWithinRange);
    setProductsList(productsSoldWithinRange);
  }, [branch, sales]);

  console.log(productsList);

  return (
    <div className="main">
      <SaleModal
        showSaleModal={showSaleModal}
        setShowSaleModal={setShowSaleModal}
      />
      <ConfirmDeleteSale
        setShowConfirmDeleteSaleModal={setShowDeleteSaleModal}
        showConfirmDeleteSaleModal={showDeleteSaleModal}
        sale={sale}
        branchId={id}
      />

      <ProductSaleHistoryModal
        showProductSaleHistoryModal={showProductSaleHistoryModal}
        setShowProductSaleHistoryModal={setShowProductSaleHistoryModal}
        startDate={startDate}
        endDate={endDate}
        productsList={productsList}
        product={selectedProduct}
        setSale={setSale}
        setShowDeleteSaleModal={setShowDeleteSaleModal}
        setShowSaleModal={setShowSaleModal}
        setSearchProduct={setSearchProduct}
      />

      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <HomeButton />
          <div>
            <h5 className="margin-0">Sales History</h5>
            <>{branch?.name}</>
          </div>
        </div>

        {/* <div className="relative">
          <div
            className="custom-input-wrapper grey lighten-4 flex "
            style={{ minWidth: "max(300px, 35vw)" }}
          >
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default
               custom-input grey lighten-4"
              placeholder="Search for product..."
              ref={inputRef}
              value={searchProduct}
              onChange={(e) => setSearchProduct(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <article className="custom-dropdown" ref={searchProductDropdownRef}>
            {
              <ul className="collection">
                {productsList.length === 0 ? (
                  <li className="collection-item">
                    No Products matching "{searchProduct}"
                  </li>
                ) : (
                  productsList.map((product, index) => (
                    <li
                      className={`collection-item flex space-between align-center hoverable ${
                        highlightedIndex === index ? "highlighted" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        setShowProductSaleHistoryModal(true);
                      }}
                    >
                      <div>
                        <label className="uppercase">
                          {product.details?.manufacturer}
                        </label>
                        <div className="flex gap-1">
                          <p className="uppercase">{product.details?.name}</p>
                          <p>{product.details?.size}</p>
                          <p className="grey-text">{product.details?.type}</p>
                        </div>
                      </div>

                      <div className="flex  gap-1">
                        <div>
                          <label>Wholesale </label>
                          <p>{amount(product.wholesalePrice)}</p>
                        </div>

                        <div>
                          <label>Retail </label>
                          <p>{amount(product.retailPrice)}</p>
                        </div>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            }
          </article>
        </div> */}

        <ProductSearchDropDown
          searchProduct={searchProduct}
          setSearchProduct={setSearchProduct}
          productsList={productsList}
          highlightedIndex={highlightedIndex}
          searchProductDropdownRef={searchProductDropdownRef}
          setShowProductSaleHistoryModal={setShowProductSaleHistoryModal}
          inputRef={inputRef}
          handleKeyDown={handleKeyDown}
          setSelectedProduct={setSelectedProduct}

        />

        <div className="flex align-center gap-1 ">
          <div className="flex align-center gap-1" style={{ zIndex: 100 }}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat={"yyyy/MM/dd"}
              selectsRange
            />
          </div>

          <button
            className="btn-small"
            onClick={() => dispatch(getSales(id, startDate, endDate))}
          >
            {" "}
            Show Results
          </button>
        </div>
      </header>
      {loading && <ProgressBar />}

      {loading ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <ClipLoader color="teal" loading={loading} size={50} />
        </div>
      ) : sales.length === 0 ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <h5>No Sales History Found</h5>
          <p>
            Sales history for this branch is empty. Click the button below to
            add sales
          </p>
          <button className="btn mt-1" onClick={() => navigate(`/sale/${id}`)}>
            Make A Sale
          </button>
        </div>
      ) : (
        <section className="p-2 main-body">
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Sold By</th>
                <th>Date & Time</th>
                <th>Customer</th>
                <th>Number of Products</th>
                <th>Total Amount</th>
                <th>Payment Method</th>
                <th>Invoice Type </th>
                <th>Invoice Number </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sales.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p>{item.seller?.name}</p>
                    </td>
                    <td>
                      <p>
                        {new Date(item.createdAt).toDateString()},{" "}
                        {new Date(item.createdAt).toLocaleTimeString()}{" "}
                      </p>
                    </td>
                    <td>
                      <p>{item.customer?.name}</p>
                    </td>
                    <td>
                      <p>{item.products?.length} product(s)</p>
                    </td>
                    <td>
                      <h6>{amount(item.total)}</h6>
                    </td>

                    <td>
                      <p>{item.paymentMethod}</p>
                    </td>
                    <td>
                      <p>{item.salesType}</p>
                    </td>
                    <td>
                      <p>{item.invoiceNumber}</p>
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <button
                          className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                          onClick={() => {
                            setShowSaleModal(true);
                            dispatch({ type: "GOT_SALE", payload: item });
                          }}
                        >
                          View
                        </button>
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          <a
                            href="#!"
                            onClick={() => {
                              const products = item.products.map((product) => {
                                const data = {
                                  manufacturer: product.product.manufacturer,
                                  name: product.product.name,
                                  price: product.price,
                                  quantity: product.quantity,
                                  total: product.total,
                                  _id: product.product._id,
                                };

                                return data;
                              });

                              const tab = {
                                id: 1,
                                title: "Sales Tab 1",
                                active: true,
                                products,
                                branch: branch._id,
                                customer: {
                                  name:
                                    item.customer?.name ||
                                    "No customer selected",
                                  id: item.customer?._id || "",
                                  phoneNumber: item.customer?.phoneNumber || "",
                                },
                                priceMode:
                                  item.priceMode || branch.wholesale
                                    ? "wholesale"
                                    : "retail",
                                salesType: item.salesType || "cash",
                                saleId: item._id,
                                saved: true,
                              };

                              dispatch({
                                type: "GOT_SALES_RECEIPT_TAB",
                                payload: tab,
                              });

                              navigate("/sale/" + branch._id);
                            }}
                          >
                            Edit Sale{" "}
                          </a>
                          <a
                            href="#!"
                            onClick={() => {
                              setSale(item);
                              setShowDeleteSaleModal(true);
                            }}
                          >
                            Delete Sale{" "}
                          </a>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default SalesHistory;

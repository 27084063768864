const productReducer = (
  state = {
    products: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case "ADDING_PRODUCT":
      return {
        ...state,
        loading: true,
      };
    case "PRODUCT_ADDED":
      return {
        ...state,
        loading: false,
        products: [...state.products, action.payload],
      };
    case "PRODUCT_ADD_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_PRODUCT":
      return {
        ...state,
        loading: true,
      };

    case "PRODUCT_UPDATED":
      return {
        ...state,
        loading: false,
        products: state.products.map((product) =>
          product._id === action.payload._id ? action.payload : product
        ),
      };

    case "PRODUCT_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETING_PRODUCT":
      return {
        ...state,
        loading: true,
      };

    case "PRODUCT_DELETED":
      return {
        ...state,
        loading: false,
      };

    case "PRODUCT_DELETE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_ALL_PRODUCTS":
      return {
        ...state,
        loading: true,
      };

    case "GOT_ALL_PRODUCTS":
      return {
        ...state,
        loading: false,
        products: action.payload,
      };

    case "GET_ALL_PRODUCTS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_PRICE":
      return {
        ...state,
        loading: true,
      };

    case "PRICE_UPDATED":
      return {
        ...state,
        loading: false,
      };

    case "PRICE_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_QUANTITY":
      return {
        ...state,
        loading: true,
      };

    case "QUANTITY_UPDATED":
      return {
        ...state,
        loading: false,
      };

    case "QUANTITY_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "RESETING_STOCK":
      return {
        ...state,
        loading: true,
      };

    case "STOCK_RESET":
      return {
        ...state,
        loading: false,
      };

    case "STOCK_RESET_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default productReducer;

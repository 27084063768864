import React from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BestSellingProductsChart = ({ data }) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const { bestSellingProductsLoading } = useSelector(
    (state) => state.analytics
  );

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <section className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Best Selling Products</h5>
        {bestSellingProductsLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <div className="">
            <div className="flex align-center gap-1 datepicker">
              <ReactDatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="browser-default "
              />
              <span className="material-symbols-outlined">calendar_month</span>
            </div>
          </div>
        )}
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <defs>
            <linearGradient
              id="tealMagentaGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor="#008080" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FF00FF" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="quantity"
            fill="url(#tealMagentaGradient)"
            background={{ fill: "#eee" }}
            radius={[10, 10, 0, 0]}
            maxBarSize={30}
          />
        </BarChart>
      </ResponsiveContainer>
    </section>
  );
};

export default BestSellingProductsChart;

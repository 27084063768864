import React from "react";
import { useSelector } from "react-redux";
import { amount } from "../../helpers/helper";

const CashAtHand = ({ salesShift, total }) => {
  const { account } = useSelector((state) => state.auth);

  // console.log(
  //   salesShift?.expenses
  //     ?.fiter((expense) => expense.account?._id === account?._id)
  //     .reduce((acc, curr) => acc + curr.amount, 0)
  // );

  return (
    <div className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Report Breakdown</h5>
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Account</th>
            <th>Total Sales Made</th>
            <th>Expenses </th>
            <th>Expected Cash At Hand </th>
            <th>Cash At hand </th>
          </tr>
        </thead>

        <tbody>
          {salesShift.cashAtHand?.map((item, index) => {
            let xpense = salesShift.expenses
              ?.filter((expense) => expense.account?._id === item.account?._id)
              .reduce((acc, curr) => acc + curr.amount, 0);

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.account?.name}</td>
                <td>{amount(total)}</td>
                <td>{amount(xpense)}</td>
                <td>{total - xpense}</td>
                <td>{amount(item.amount)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CashAtHand;

import React from "react";
import { BeatLoader } from "react-spinners";

const Loading = () => {
  return (
    <div
      className="flex justify-center align-center"
      style={{ height: "100vh" }}
    >
      <div className="flex justify-center column align-center ">
        <img src="/images/baiclass.png" alt="logo" 
         style={{ width: "100px", height: "100px" }}
        />
        <p className="bold">Baiclass Enterprise POS</p>
        <BeatLoader color="teal" size={10} />
        <p className=" teal-text small-text ">Loading...</p>
      </div>
    </div>
  );
};

export default Loading;

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const addInvetoryReceipt =
  (receipt, branchId, cb) => async (dispatch) => {
    dispatch({ type: "ADDING_INVENTORY_RECEIPT" });
    try {
      const res = await POS.post("/api/inventory/create/" + branchId, receipt);
      dispatch({ type: "INVENTORY_RECEIPT_ADDED", payload: res.data });
      notify("Inventory Receipt Added Successfully", "success");
      if (cb) cb();
    } catch (error) {
      dispatch({ type: "ADD_INVENTORY_RECEIPT_FAILED", payload: error });
      notify(error.response?.data || error.message, "error");
    }
  };

export const updateInventoryReceipt = (receipt, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_INVENTORY_RECEIPT" });
  try {
    const res = await POS.put("/api/inventory/update/" + receipt._id, receipt);
    dispatch({ type: "INVENTORY_RECEIPT_UPDATED", payload: res.data });
    notify("Inventory Receipt Updated Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "UPDATE_INVENTORY_RECEIPT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const getInventoryReceipts = (branchId) => async (dispatch) => {
  dispatch({ type: "GETTING_INVENTORY_RECEIPTS" });
  try {
    const res = await POS.get("/api/inventory/read/" + branchId);
    dispatch({ type: "GOT_INVENTORY_RECEIPTS", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_INVENTORY_RECEIPTS_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const deleteInventoryReceipt = (id, cb) => async (dispatch) => {
  dispatch({ type: "DELETING_INVENTORY_RECEIPT" });
  try {
    const res = await POS.delete("/api/inventory/delete/" + id);
    dispatch({ type: "INVENTORY_RECEIPT_DELETED", payload: res.data });
    notify("Inventory Receipt Deleted Successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "DELETE_INVENTORY_RECEIPT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

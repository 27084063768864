import React from "react";

const LowStockProducts = ({ data }) => {
  return (
    <div className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Low Stock Products</h5>
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Product</th>
            <th>Quantity </th>
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.details.name}</td>
              <td>{item.stock}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LowStockProducts;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { amount, productImg } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import Modal from "react-responsive-modal";
import AddProduct from "../../components/AddProduct";
import { getBranch } from "../../redux/actions/branches";
import isEmpty from "lodash/isEmpty";
import AddSystemProduct from "./components/AddSystemProduct";
import ChangeStockQuantity from "./components/ChangeStockQuantity";
import ChangePriceModal from "./components/ChangePriceModal";
import EditProduct from "../../components/EditProduct";
import DeleteProductModal from "./components/DeleteProductModal";
import RemoveStockModal from "../inventory/components/RemoveStockModal";
import { ClipLoader } from "react-spinners";
import { resetAllStock } from "../../redux/actions/products";

const BranchProducts = () => {
  const { branches, loading } = useSelector((state) => state.branch);
  const { loading: productLoading } = useSelector((state) => state.product);
  const { account } = useSelector((state) => state.auth);
  const { products } = useSelector((state) => state.product);
  const [productsList, setProductsList] = React.useState([]);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showAddSystemProductModal, setShowAddSystemProductModal] =
    React.useState(false);
  const branchId = useParams().id;
  const { branch } = useSelector((state) => state.branch);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [showChangeStockQuantityModal, setShowChangeStockQuantityModal] =
    React.useState(false);
  const [showChangePriceModal, setShowChangePriceModal] = React.useState(false);
  const [priceType, setPriceType] = React.useState("retail");
  const [showEditProductModal, setShowEditProductModal] = React.useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [showRemoveStockModal, setShowRemoveStockModal] = React.useState(false);
  const navigate = useNavigate();
  const [showResetStockModal, setShowResetStockModal] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBranch(branchId));
  }, [dispatch, branchId]);

  React.useEffect(() => {
    if (isEmpty(branch)) return;
    setProductsList(
      branch.products.filter(
        (item) =>
          item.details?.name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.details?.nickname
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          item.details?.manufacturer
            .toLowerCase()
            .includes(keyword.toLowerCase())
      )
    );
  }, [branch, branch.products, keyword]);

  function resetStock(e) {
    e.preventDefault();
    dispatch(
      resetAllStock(branchId, () => {
        dispatch(getBranch(branchId));
        setShowResetStockModal(false);
      })
    );
  }

  console.log(showRemoveStockModal);

  return (
    <div className="main">
      <Modal
        open={showResetStockModal}
        onClose={() => setShowResetStockModal(false)}
      >
        <form onSubmit={resetStock} className="mt-2 p-2">
          <h5 className="mb-1">Confirmation</h5>
          <p>
            Are you sure you want to reset all stock to 0? This action cannot be
            undone
          </p>

          <div className="mt-2 gap-1 flex ">
            <button type="submit" className="btn">
              {productLoading ? (
                <ClipLoader color="#ffffff" size={20} />
              ) : (
                "Reset All Stock"
              )}
            </button>

            <button
              className="btn grey lighten-1"
              onClick={() => {
                setShowResetStockModal(false);
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct setShowProductModal={setShowProductModal} />
      </Modal>
      <Modal
        open={showAddSystemProductModal}
        onClose={() => setShowAddSystemProductModal(false)}
      >
        <AddSystemProduct
          setShowAddSystemProductModal={setShowAddSystemProductModal}
          branchId={branchId}
        />
      </Modal>
      <ChangeStockQuantity
        product={selectedProduct}
        branchId={branchId}
        setShowStockModal={setShowChangeStockQuantityModal}
        showStockModal={showChangeStockQuantityModal}
      />
      <ChangePriceModal
        product={selectedProduct}
        branchId={branchId}
        setShowChangePriceModal={setShowChangePriceModal}
        showChangePriceModal={showChangePriceModal}
        priceType={priceType}
      />

      <Modal
        open={showEditProductModal}
        onClose={() => setShowEditProductModal(false)}
      >
        <EditProduct
          setShowEditProductModal={setShowEditProductModal}
          product={selectedProduct}
        />
      </Modal>

      <RemoveStockModal
        product={selectedProduct}
        setShowRemoveStockModal={setShowRemoveStockModal}
        showRemoveStockModal={showRemoveStockModal}
      />
      <header className="flex full-width  space-between sales-header align-center">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Products</h5>
            <p>Products added to {branch?.name}</p>
          </div>
        </div>

        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input"
              placeholder="search for product..."
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                const value = e.target.value;
              }}
            />
          </div>
          <button className="btn">search</button>
        </div>

        <div className="flex gap-1 align-center">
          <button
            className="btn "
            onClick={() => setShowAddSystemProductModal(true)}
          >
            Add System Product
          </button>
          <button
            className="btn white teal-text"
            onClick={() => setShowProductModal(true)}
          >
            Add New Product
          </button>

          <Dropdown
            trigger={<i className="material-symbols-outlined">more_vert</i>}
          >
            <span
              onClick={() =>
                setProductsList(
                  branch.products.filter((item) => item.stock <= 10)
                )
              }
            >
              Show Low Stock Products
            </span>
            {account.permissions.includes("clear-all-stock") && (
              <span onClick={() => setShowResetStockModal(true)}>
                Reset all stock to 0
              </span>
            )}

            <span onClick={() => navigate("/inventory-print/" + branch._id)}>
              Export
            </span>
            <span>Print</span>
          </Dropdown>
        </div>
      </header>
      {loading && <ProgressBar />}
      {productsList?.length === 0 && !loading ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <h5>No Products Found</h5>
          <p>
            You have not added any products to your platform. Click the button
            below to add products
          </p>
          <button
            className="btn mt-1"
            onClick={() => setShowProductModal(true)}
          >
            Add Product
          </button>
        </div>
      ) : (
        <section className="p-2 main-body">
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Details</th>
                <th>Size</th>
                {<th>Quantity In Stock</th>}

                {account._id === "65d0daa478c17746cfa7b7d7" && (
                  <th>Factory Price</th>
                )}
                <th>Wholesale Price</th>
                <th>Retail Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {productsList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="flex align-center">
                      <img
                        src={productImg}
                        alt=""
                        className="mr-2"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10%",
                        }}
                      />
                      <div>
                        <p className="small-text">
                          {item.details?.manufacturer}
                        </p>

                        <div className="flex gap-1">
                          <div>
                            <h6>{item.details?.name}</h6>
                            <p>{item.details?.nickname}</p>
                          </div>

                          <p>{item.type}</p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div
                        className="flex column gap-1 align-center"
                        style={{ width: "fit-content" }}
                      >
                        <div className="flex gap-1 align-center">
                          <h6>{item.details?.size || "N/A"}</h6>
                        </div>
                      </div>
                    </td>

                    {
                      <td>
                        <h6
                          className={
                            !account.permissions.includes(
                              "change-stock-quantity"
                            )
                              ? "grey-text"
                              : "pointer teal-text"
                          }
                          onClick={() => {
                            if (
                              !account.permissions.includes(
                                "change-stock-quantity"
                              )
                            )
                              return;
                            setSelectedProduct(item);
                            setShowChangeStockQuantityModal(true);
                          }}
                        >
                          {item.stock}
                        </h6>
                      </td>
                    }

                    {account._id === "65d0daa478c17746cfa7b7d7" && (
                      <td>
                        <h6
                          className={
                            !account.permissions.includes("change-stock-price")
                              ? "grey-text"
                              : "pointer teal-text"
                          }
                          onClick={() => {
                            if (
                              !account.permissions.includes(
                                "change-stock-price"
                              )
                            )
                              return;
                            setSelectedProduct(item);
                            setShowChangePriceModal(true);
                            setPriceType("factory");
                          }}
                        >
                          {amount(item.basePrice)}
                        </h6>
                      </td>
                    )}

                    <td>
                      <h6
                        className={
                          !account.permissions.includes("change-stock-price")
                            ? "grey-text"
                            : "pointer teal-text"
                        }
                        onClick={() => {
                          if (
                            !account.permissions.includes("change-stock-price")
                          )
                            return;
                          setSelectedProduct(item);
                          setShowChangePriceModal(true);
                          setPriceType("wholesale");
                        }}
                      >
                        {amount(item.wholesalePrice)}
                      </h6>
                    </td>

                    <td>
                      <h6
                        className={
                          !account.permissions.includes("change-stock-price")
                            ? "grey-text"
                            : "pointer teal-text"
                        }
                        onClick={() => {
                          if (
                            !account.permissions.includes("change-stock-price")
                          )
                            return;
                          setSelectedProduct(item);
                          setShowChangePriceModal(true);
                          setPriceType("retail");
                        }}
                      >
                        {amount(item.retailPrice)}
                      </h6>
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          {account.permissions.includes("update-product") && (
                            <span
                              onClick={() => {
                                setSelectedProduct(
                                  products.find(
                                    (product) =>
                                      product._id === item.details._id
                                  )
                                );
                                setShowEditProductModal(true);
                              }}
                            >
                              Edit Product
                            </span>
                          )}

                          {account.permissions.includes("remove-stock") && (
                            <span
                              onClick={() => {
                                setSelectedProduct(
                                  products.find(
                                    (product) =>
                                      product._id === item.details._id
                                  )
                                );
                                setShowRemoveStockModal(true);
                              }}
                            >
                              Remove Product from stock
                            </span>
                          )}
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default BranchProducts;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const DaySalesTimeChart = ({ data }) => {
  return (
    <section className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Sales Timeline</h5>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <XAxis
            dataKey="date"
            tickFormatter={(date) => {
              return new Date(date).toLocaleTimeString().split(":")[0] + ":00";
            }}
          />
          <YAxis interval={1} />
          <Tooltip
            labelFormatter={(date) => {
              return new Date(date).toLocaleTimeString();
            }}
          />
          <Legend />
          <defs>
            <linearGradient
              id="tealMagentaGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor="#008080" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FF00FF" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <Area
            dataKey={"total"}
            fill="url(#tealMagentaGradient)"
            background={{ fill: "#eee" }}
            stroke="none"
          />
        </AreaChart>
      </ResponsiveContainer>
    </section>
  );
};

export default DaySalesTimeChart;

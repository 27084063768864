const initialState = {
  loading: false,
  suppliers: [],
  supplier: {},
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GETTING_SUPPLIERS":
      return {
        ...state,
        loading: true,
      };
    case "GOT_SUPPLIERS":
      return {
        ...state,
        loading: false,
        suppliers: action.payload,
      };
    case "GET_SUPPLIERS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_SUPPLIER":
      return {
        ...state,
        loading: true,
      };

    case "ADDED_SUPPLIER":
      return {
        ...state,
        loading: false,
        suppliers: [...state.suppliers, action.payload],
      };

    case "ADD_SUPPLIER_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_SUPPLIER":
      return {
        ...state,
        loading: true,
      };

    case "GOT_SUPPLIER":
      return {
        ...state,
        loading: false,
        supplier: action.payload,
      };

    case "GET_SUPPLIER_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_SUPPLIER":
      return {
        ...state,
        loading: true,
      };

    case "UPDATED_SUPPLIER":
      return {
        ...state,
        loading: false,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier._id === action.payload._id) {
            return action.payload;
          }
          return supplier;
        }),
      };

    case "UPDATE_SUPPLIER_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETING_SUPPLIER":
      return {
        ...state,
        loading: true,
      };

    case "DELETED_SUPPLIER":
      return {
        ...state,
        loading: false,
        suppliers: state.suppliers.filter(
          (supplier) => supplier._id !== action.payload
        ),
      };

    case "DELETE_SUPPLIER_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};


export default suppliersReducer;
import React, { useEffect } from "react";
import { amount, amountWithoutCurrency, notify } from "../../helpers/helper";
import { Dropdown, Select } from "react-materialize";
import { useSelector } from "react-redux";
import {
  calculateTotal,
  decreaseProductQuantity,
  increaseProductQuantity,
  removeProduct,
  setCashPrices,
  setCreditPrices,
  updateProductOnTab,
} from "./actions";
import QuantityPriceModal from "./QuantityPriceModal";
import ReceivedPaymentModal from "./ReceivedPaymentModal";
import SelectCustomerModal from "./components/SelectCustomerModal";
import Modal from "react-responsive-modal";
import AddSystemProduct from "../products/components/AddSystemProduct";
import AddProduct from "../../components/AddProduct";

const SalesTab = ({ tab, setTabs, tabs, tabIndex }) => {
  const inputRef = React.useRef();
  const searchProductDropdownRef = React.useRef();
  const [searchProduct, setSearchProduct] = React.useState("");
  const [productsList, setProductsList] = React.useState([]);
  const { branch } = useSelector((state) => state.branch);
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const [showQuantityPriceModal, setShowQuantityPriceModal] =
    React.useState(false);
  const [showReceivedPaymentModal, setShowReceivedPaymentModal] =
    React.useState(false);
  const [productIndex, setProductIndex] = React.useState(-1);
  const [showSelectCustomerModal, setShowSelectCustomerModal] =
    React.useState(false);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showAddSystemProductModal, setShowAddSystemProductModal] =
    React.useState(false);
  const { account } = useSelector((state) => state.auth);
  const salesMainRef = React.useRef();
  const { customers } = useSelector((state) => state.customers);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, productsList.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      const product = productsList[highlightedIndex];
      searchProductDropdownRef.current.style.display = "none";
      setSearchProduct("");
      updateProductOnTab(
        tabIndex,
        {
          _id: product.details?._id,
          manufacturer: product.details?.manufacturer,
          name: product.details?.name,
          size: product.details?.size,
          type: product.details?.type,
          quantity: 1,
          price:
            tab.priceMode === "retail"
              ? product.retailPrice
              : product.wholesalePrice,
          total:
            tab.priceMode === "retail"
              ? product.retailPrice
              : product.wholesalePrice,
        },
        tabs,
        setTabs
      );
    }
  };

  React.useEffect(() => {
    if (searchProduct.length >= 1) {
      searchProductDropdownRef.current.style.display = "block";
      setProductsList(
        branch?.products.filter(
          (product) =>
            product.details?.name
              .toLowerCase()
              .includes(searchProduct.toLowerCase()) ||
            product.details?.manufacturer
              .toLowerCase()
              .includes(searchProduct.toLowerCase()) ||
            product.details?.nickname
              .toLowerCase()
              .includes(searchProduct.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchProductDropdownRef.current.style.display = "none";
  }, [branch?.products, searchProduct]);

  React.useEffect(() => {
    setProductsList(branch?.products);
  }, [branch.products]);

  React.useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!searchProductDropdownRef.current.contains(e.target)) {
        searchProductDropdownRef.current.style.display = "none";
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  console.log(showSelectCustomerModal);
  useEffect(() => {
    console.log(
      salesMainRef.current.addEventListener("keydown", (e) => {
        console.log("clikc");
      })
    );
    const handleKeyPress = (event) => {
      // Update the input value with the key pressed
      // inputRef.current.focus();
      // if (showSelectCustomerModal) return;
      // inputRef.current.focus();
    };

    // Add event listener for key presses
    document.addEventListener("keydown", (e, salesTab) => {
      handleKeyPress(e);
    });

    // Clean up the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="sales-tab">
      {showQuantityPriceModal && (
        <QuantityPriceModal
          showQuantityPriceModal={showQuantityPriceModal}
          setShowQuantityPriceModal={setShowQuantityPriceModal}
          tab={tab}
          setTabs={setTabs}
          tabIndex={tabIndex}
          productIndex={productIndex}
          setProductIndex={setProductIndex}
          tabs={tabs}
        />
      )}

      <ReceivedPaymentModal
        tab={tab}
        setShowReceivedPaymentModal={setShowReceivedPaymentModal}
        showReceivedPaymentModal={showReceivedPaymentModal}
        setTabs={setTabs}
        tabs={tabs}
        tabIndex={tabIndex}
        branchId={branch._id}
      />

      <SelectCustomerModal
        showSelectCustomerModal={showSelectCustomerModal}
        setShowSelectCustomerModal={setShowSelectCustomerModal}
        tab={tab}
        setTabs={setTabs}
        branchId={branch._id}
        tabs={tabs}
        date={new Date()}
      />

      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct
          setShowProductModal={setShowProductModal}
          branchId={branch?._id}
        />
      </Modal>

      <Modal
        open={showAddSystemProductModal}
        onClose={() => setShowAddSystemProductModal(false)}
      >
        <AddSystemProduct
          setShowAddSystemProductModal={setShowAddSystemProductModal}
          branchId={branch?._id}
        />
      </Modal>

      <header className="flex space-between align-end sales-tab-header">
        <div className="p-2 flex gap-1 align-center grey lighten-4 rounded">
          <div>
            <h6 className="capitalize">{tab.customer?.name}</h6>
            <p>{tab.customer?.phoneNumber}</p>
          </div>
          {tab.customer?.id ? (
            <span
              className="material-symbols-outlined teal-text ml-1 "
              onClick={() => {
                const tabIndex = tabs.findIndex((t) => t.id === tab.id);
                console.log(tabIndex);
                if (tabIndex === -1) return;
                tabs[tabIndex].customer = {
                  name: "No customer selected",
                  id: "",
                  phoneNumber: "N/A",
                };

                tabs[tabIndex].title = "Sales Tab ";
                setTabs([...tabs]);
              }}
            >
              close
            </span>
          ) : (
            <span
              className="material-symbols-outlined teal-text ml-1 "
              onClick={() => setShowSelectCustomerModal(true)}
            >
              add
            </span>
          )}
        </div>

        <div className="flex gap-1 align-center " style={{ height: "100%" }}>
          <div className="relative">
            <div
              className="custom-input-wrapper grey lighten-4 flex "
              style={{ minWidth: "max(300px, 35vw)" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default
               custom-input grey lighten-4"
                placeholder="Search for product..."
                ref={inputRef}
                value={searchProduct}
                onChange={(e) => setSearchProduct(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>

            <article className="custom-dropdown" ref={searchProductDropdownRef}>
              {
                <ul className="collection">
                  {productsList.length === 0 ? (
                    <li className="collection-item">
                      No Products matching "{searchProduct}"
                    </li>
                  ) : (
                    productsList.map((product, index) => (
                      <li
                        className={`collection-item flex space-between align-center hoverable ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          searchProductDropdownRef.current.style.display =
                            "none";
                          updateProductOnTab(
                            tabIndex,
                            {
                              _id: product.details?._id,
                              manufacturer: product.details?.manufacturer,
                              name: product.details?.name,
                              size: product.details?.size,
                              type: product.details?.type,
                              quantity: 1,
                              price:
                                tab.priceMode === "retail"
                                  ? product.retailPrice
                                  : product.wholesalePrice,
                              total:
                                tab.priceMode === "retail"
                                  ? product.retailPrice
                                  : product.wholesalePrice,
                            },
                            tabs,
                            setTabs
                          );
                          inputRef.current.focus();
                          setSearchProduct("");
                        }}
                      >
                        <div>
                          <label className="uppercase">
                            {product.details?.manufacturer}
                          </label>
                          <div className="flex gap-1">
                            <p className="uppercase">{product.details?.name}</p>
                            <p>{product.details?.size}</p>
                            <p className="grey-text">{product.details?.type}</p>
                          </div>
                        </div>

                        <div className="flex  gap-1">
                          <div>
                            <label>Wholesale </label>
                            <p>{amount(product.wholesalePrice)}</p>
                          </div>

                          <div>
                            <label>Retail </label>
                            <p>{amount(product.retailPrice)}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              }
            </article>
          </div>
          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span onClick={() => setShowAddSystemProductModal(true)}>
              Add System Product
            </span>
            <span onClick={() => setShowProductModal(true)}>
              Add New Product
            </span>
          </Dropdown>
        </div>
        {account.permissions?.includes("create-credit-sale") && (
          <div className="flex align-center" style={{ height: "100%" }}>
            <Dropdown
              trigger={
                <button className="btn waves-effect waves-light  flex justify-center align-center white teal-text">
                  More Options
                </button>
              }
            >
              <span
                onClick={() => {
                  setCashPrices(tabIndex, tabs, setTabs, branch.products);
                }}
              >
                Set As Cash Invoice
              </span>
              <span
                onClick={() => {
                  setCreditPrices(tabIndex, tabs, setTabs, branch.products);
                }}
              >
                Set As Credit Invoice
              </span>
            </Dropdown>
          </div>
        )}

        <section className="flex ">
          <div className="priceModeSwitch">
            {branch.retail && (
              <button
                className={tab.priceMode === "retail" ? "my-active-switch" : ""}
                onClick={() => {
                  if (tab.priceMode === "retail") return;
                  let newTabs = [...tabs];
                  newTabs[tabIndex].priceMode = "retail";
                  newTabs[tabIndex].products.forEach((product) => {
                    const pd = branch.products.find(
                      (p) => p.details?._id === product._id
                    );
                    product.price = pd.retailPrice;
                    product.total = pd.retailPrice * product.quantity;
                  });

                  setTabs(newTabs);
                }}
              >
                Retail
              </button>
            )}
            {branch.wholesale && (
              <button
                className={
                  tab.priceMode === "wholesale" ? "my-active-switch" : ""
                }
                onClick={() => {
                  if (tab.priceMode === "wholesale") return;
                  let newTabs = [...tabs];
                  newTabs[tabIndex].priceMode = "wholesale";
                  newTabs[tabIndex].products.forEach((product) => {
                    const pd = branch.products.find(
                      (p) => p.details?._id === product._id
                    );

                    product.price = pd.wholesalePrice;
                    product.total = pd.wholesalePrice * product.quantity;
                  });
                  setTabs(newTabs);
                }}
              >
                {" "}
                Wholesale
              </button>
            )}
          </div>
        </section>
      </header>

      <main className="sales-main" ref={salesMainRef}>
        <section>
          <div className="flex justify-end">
            {tab.salesType === "credit" && (
              <div className="orange sales-type-chip">Credit</div>
            )}
            {tab.saved && (
              <div className="orange sales-type-chip">Update Sale</div>
            )}
          </div>

          {tab.products.map((item, index) => {
            return (
              <div className="product-card " key={index}>
                <div className="product-details">
                  <p>{index + 1}</p>
                  <div>
                    <p className="small-text uppercase">{item.manufacturer}</p>
                    <div className="flex gap-1">
                      <p className="uppercase bold">{item.name}</p>
                      <p>{item.size}</p>
                      <p className="grey-text">{item.type}</p>
                    </div>
                  </div>
                </div>

                {account.permissions?.includes("update-sale") && (
                  <div className="uppercase">
                    <div className="flex column gap-1 align-center space-between">
                      <p className="small-text grey-text">In Stock</p>
                      <h6
                        className={
                          branch.products.find(
                            (p) => p.details?._id === item._id
                          )?.stock < item.quantity
                            ? "red-text"
                            : "green-text"
                        }
                      >
                        {
                          branch.products.find(
                            (p) => p.details?._id === item._id
                          )?.stock
                        }
                      </h6>
                    </div>
                  </div>
                )}
                <div className="uppercase">
                  <div
                    className="flex column gap-1 align-center"
                    style={{ width: "fit-content" }}
                  >
                    <p className="small-text grey-text">Quantity</p>

                    <div className="flex gap-1 align-center">
                      <button
                        className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                        onClick={() => {
                          increaseProductQuantity(
                            tabIndex,
                            item,
                            tabs,
                            setTabs,
                            index
                          );
                        }}
                      >
                        <span className="material-symbols-outlined">add</span>
                      </button>

                      <h6
                        onClick={() => {
                          setProductIndex(index);
                          setShowQuantityPriceModal(true);
                        }}
                      >
                        {item.quantity}
                      </h6>

                      <button
                        className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                        onClick={() => {
                          decreaseProductQuantity(
                            tabIndex,
                            item,
                            tabs,
                            setTabs,
                            index
                          );
                        }}
                      >
                        <span className="material-symbols-outlined">
                          remove
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="uppercase">
                  <div className="flex column gap-1 align-center space-between">
                    <p className="small-text grey-text">Unit Price</p>
                    <h6
                      role="button"
                      onClick={() => {
                        setProductIndex(index);
                        setShowQuantityPriceModal(true);
                      }}
                    >
                      {amount(item.price)}
                    </h6>
                  </div>
                </div>

                <div className="flex column gap-1 align-center space-between uppercase">
                  <p className="small-text grey-text">Subtotal</p>
                  <h6
                    role="button"
                    onClick={() => {
                      setProductIndex(index);
                      setShowQuantityPriceModal(true);
                    }}
                  >
                    {amountWithoutCurrency(item.total)}
                  </h6>
                </div>

                <div>
                  <button
                    className="btn-floating waves-effect waves-light  flex justify-center align-center red"
                    onClick={() => {
                      removeProduct(tabIndex, item, tabs, setTabs, index);
                    }}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </div>
              </div>
            );
          })}
        </section>

        <aside>
          <div className=" ml-2 flex justify-end mb-1">
            {tabs.length > 1 && (
              <i
                className="material-symbols-outlined red-text"
                onClick={() => {
                  if (tabs.length === 1)
                    return notify("Cannot close last tab", "info");
                  let newTabs = [...tabs];
                  newTabs.splice(tabIndex, 1);
                  newTabs = newTabs.map((tab, index) => {
                    tab.id = index + 1;
                    return tab;
                  });
                  setTabs(newTabs);
                  localStorage.setItem("salesTab", JSON.stringify(newTabs));
                }}
              >
                close
              </i>
            )}
          </div>
          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Subtotal</p>
              <h6 className="bold">{calculateTotal(tab)}</h6>
            </div>
            <div
              className="flex space-between align-center"
              style={{ gap: "50px" }}
            >
              <p className="grey-text">Discount</p>
              <h6 className="bold">0.00</h6>
            </div>
            <div
              className="flex space-between align-center"
              style={{ gap: "50px" }}
            >
              <p className="grey-text">Tax</p>
              <h6 className="bold">0.00</h6>
            </div>
          </section>

          <section className="sales-subtotal">
            <div
              className="flex space-between align-center"
              style={{ gap: "50px" }}
            >
              <p className="grey-text">Amount Payable</p>
              <h5 className="bold">{amount(calculateTotal(tab))}</h5>
            </div>
          </section>
          {tab.saved ? (
            <div className="flex mt-2 gap-1">
              {/* <button
              className="btn-large white teal-text"
              style={{ width: "100%" }}
            >
              Print Receipt
              <span className="material-symbols-outlined">print</span>
            </button> */}
              <button
                className="btn-large orange"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowReceivedPaymentModal(true);
                }}
              >
                Update Sale
              </button>
            </div>
          ) : (
            <div className="flex mt-2 gap-1">
              {/* <button
             className="btn-large white teal-text"
             style={{ width: "100%" }}
           >
             Print Receipt
             <span className="material-symbols-outlined">print</span>
           </button> */}
              <button
                className="btn-large"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowReceivedPaymentModal(true);
                }}
              >
                Received Payment
              </button>
            </div>
          )}
        </aside>
      </main>
    </div>
  );
};

export default SalesTab;

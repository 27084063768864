const customersReducer = (
  state = {
    loading: false,
    customers: [],
    customer: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_CUSTOMERS":
      return {
        ...state,
        loading: true,
      };

    case "CUSTOMERS_GOT":
      return {
        ...state,
        loading: false,
        customers: action.payload,
      };

    case "CUSTOMERS_GET_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_CUSTOMER":
      return {
        ...state,
        loading: true,
      };

    case "CUSTOMER_ADDED":
      return {
        ...state,
        loading: false,
      };

    case "CUSTOMER_ADD_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_CUSTOMER":
      return {
        ...state,
        loading: true,
      };

    case "CUSTOMER_GOT":
      return {
        ...state,
        loading: false,
        customer: action.payload,
      };

    case "SET_CUSTOMER":
      return {
        ...state,
        loading: false,
        customer: action.payload,
      };

    case "CUSTOMER_GET_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "MAKING_PAYMENT":
      return {
        ...state,
        loading: true,
      };

    case "PAYMENT_MADE":
      return {
        ...state,
        loading: false,
      };

    case "PAYMENT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "EDITING_PAYMENT":
      return {
        ...state,
        loading: true,
      };

    case "PAYMENT_EDITED":
      return {
        ...state,
        loading: false,
      };

    case "EDIT_PAYMENT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETING_PAYMENT":
      return {
        ...state,
        loading: true,
      };

    case "PAYMENT_DELETED":
      return {
        ...state,
        loading: false,
      };

    case "DELETE_PAYMENT_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default customersReducer;

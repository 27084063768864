import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import Loading from "./components/Loading";
import Menu from "./pages/menu/Menu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-select-search/style.css";
import Login from "./pages/auth/Login";
import store from "./config/store";
import Sale from "./pages/sale/Sale";
import InventoryList from "./pages/inventory/InventoryList";
import { getALlBranches } from "./redux/actions/branches";
import Branch from "./pages/branches/Branch";
import AllProducts from "./pages/products/AllProducts";
import { getAllProducts } from "./redux/actions/products";
import SingleBranch from "./pages/branches/SingleBranch";
import SalesHistory from "./pages/sale/SalesHistory";
import SalesReport from "./pages/sale/SalesReport";
import ReceiveInventory from "./pages/inventory/ReceiveInventory";
import Suppliers from "./pages/suppliers/Suppliers";
import { getSuppliers } from "./redux/actions/supplier";
import InventoryReceiptHistory from "./pages/inventory/InventoryReceiptHistory";
import { getAccount } from "./redux/actions/auth";
import BranchProducts from "./pages/products/BranchProducts";
import NonTodaySale from "./pages/sale/NonTodaySale";
import Invoice from "./pages/sale/Invoice";
import InventoryPrintTemplate from "./pages/inventory/InventoryPrintTemplate";
import DailyTotalSalesList from "./pages/sale/DailyTotalSalesList";
import DailyTotalSalesPrint from "./pages/sale/DailySalesPrinTemplate";
import Customer from "./pages/customers/Customer";
import CustomerProfile from "./pages/customers/CustomerProfile";
import DebtorsList from "./pages/inventory/DebtorsList";
import DebtorsPrintTemplate from "./pages/inventory/components/DebtorsPrintTemplate";
import Receipt from "./pages/customers/Receipt";
import SupplierProfile from "./pages/suppliers/Supplier";
import PaymentHistoryAll from "./pages/customers/PaymentHistoryAll";
import PurchaseHistoryAll from "./pages/customers/PurchaseHistoryAll";

function App() {
  const tokenPresent = localStorage.getItem("__beemPOS");

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        {tokenPresent ? <Authenticated /> : <Unauthenticated />}
      </BrowserRouter>
    </Provider>
  );
}

const Unauthenticated = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
    </Routes>
  );
};

const Authenticated = () => {
  const [caseType, setCaseType] = React.useState("loading");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getALlBranches(() => {
        setCaseType("standard");
      })
    );
    dispatch(getAllProducts());
    dispatch(getSuppliers());
    dispatch(getAccount());
  }, [dispatch]);

  const Standard = () => {
    return (
      <Routes>
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/print-receipt/:id" element={<Receipt />} />
        <Route path="/customer-profile/:id" element={<CustomerProfile />} />
        <Route path="/supplier-profile/:id" element={<SupplierProfile />} />
        <Route path="/customers/:id/payments" element={<PaymentHistoryAll />} />
        <Route path="/customers/:id/purchases" element={<PurchaseHistoryAll />} />
        <Route path="/customers/:id" element={<Customer />} />\
        <Route path="/debtors/:id" element={<DebtorsList />} />
        <Route path="/debtors-print/:id" element={<DebtorsPrintTemplate />} />
        <Route
          path="/inventory-print/:id"
          element={<InventoryPrintTemplate />}
        />
        <Route path="/suppliers/:id" element={<Suppliers />} />
        <Route
          path="/print-daily-total-sales"
          element={<DailyTotalSalesPrint />}
        />
        <Route path="/receive-inventory/:id" element={<ReceiveInventory />} />
        <Route
          path="/daily-total-sales/:id"
          element={<DailyTotalSalesList />}
        />
        <Route path="/sales-report/:id" element={<SalesReport />} />
        <Route path="/sales-history/:id" element={<SalesHistory />} />
        <Route path="/branch/:id" element={<SingleBranch />} />
        <Route path="/sale/:id" element={<Sale />} />
        <Route path="/non-today-sale/:id" element={<NonTodaySale />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/branch-products/:id" element={<BranchProducts />} />
        <Route path="/branches" element={<Branch />} />
        <Route
          path="/inventory-receipt-history/:id"
          element={<InventoryReceiptHistory />}
        />
        <Route path="/inventory-list" element={<InventoryList />} />
        <Route path="/" element={<Menu />} />
      </Routes>
    );
  };

  return (
    <>
      {((caseType) => {
        switch (caseType) {
          case "standard":
            return <Standard />;

          case "loading":
            return <Loading />;

          case "somethingWentWrong":
            return <AccessDenied />;

          default:
            return <Standard />;
        }
      })(caseType)}
    </>
  );
};

const AccessDenied = () => {
  return (
    <Routes>
      <Route path="/network-error" element={<AccessDenied />} />
      <Route path="/" element={<AccessDenied />} />
    </Routes>
  );
};

export default App;

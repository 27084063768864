import React, { useEffect } from "react";
import { DatePicker, ProgressBar } from "react-materialize";
// import { amount } from "../../helpers/helper";
import BestSellingProductsChart from "../branches/components/BestSellingProducts";
import LowStockProducts from "../branches/components/LowStockProducts";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Expenses from "./components/Expenses";
import {
  getBestSellingProducts,
  getProductsLowInStock,
  getSalesReport,
  getSalesShift,
  getSalesTimelineChart,
} from "../../redux/actions/sale";
import { amount } from "../../helpers/helper";
import DaySalesTimeChart from "./components/DaySalesTimeChart";
import SalesReportHistory from "./SalesReportHistory";
import CashAtHand from "./CashAtHands";

const BranchAnalytics = () => {
  const { branch } = useSelector((state) => state.branch);
  const branchId = useParams().id;
  const {
    salesTimelineChart,
    salesReport,
    salesShift,
    bestSellingProducts,
    productsLowInStock,
    loading,
  } = useSelector((state) => state.sale);

  const query = new URLSearchParams(window.location.search);
  const [date, setDate] = React.useState(new Date(query.get("date")));
  const dispatch = useDispatch();
  const handleDateChange = (e) => {
    setDate(e);
    dispatch({ type: "CLEAR_SALES_REPORT" });
  };

  useEffect(() => {
    dispatch(getSalesReport(branchId, date.toJSON().slice(0, 10)));
    dispatch(getSalesShift(branchId, date.toJSON().slice(0, 10)));
    dispatch(getSalesTimelineChart(branchId, date.toJSON().slice(0, 10)));
    dispatch(getBestSellingProducts(branchId, date.toJSON().slice(0, 10)));
    dispatch(getProductsLowInStock(branchId));
  }, [branchId, date, dispatch]);

  return (
    <section className="sr-container">
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Daily Sales Report</h5>
            <h6 className="bold margin-0">{branch?.name}</h6>
          </div>
        </div>

        <div className="date-select">
          <div className="date-input">
            <span className="material-symbols-outlined">calendar_today</span>
            <DatePicker
              className="browser-default "
              options={{
                defaultDate: date,
              }}
              value={date ? date.toDateString() : ""}
              onChange={(e) => {
                handleDateChange(e);
                // setDate(addSeconds(e, 3600));
                //   document.body.style.overflow = "auto";
              }}
            />
          </div>
        </div>
      </header>
      {loading && <ProgressBar />}
      <article className="flex">
        <section>
          <div className="flex space-between p-1 align-center">
            <h5 className="bold">{date.toDateString()}</h5>

            {/* <div>
              <button className="btn">Export</button>
            </div> */}
          </div>

          <section className="flex space-between mt-1  gap-1">
            <div className="d-card">
              <p className=""> TOTAL SALES</p>

              <div>
                <h4 className="bold margin-0">
                  {" "}
                  {amount(salesReport?.totalRevenue)}
                </h4>
              </div>
            </div>

            <div className="d-card">
              <p>TOTAL EXPENSES</p>

              <div>
                <h4 className="bold margin-0">
                  {amount(salesReport?.totalExpenses)}
                </h4>
              </div>
            </div>

            <div className="d-card">
              <p>PRODUCTS SOLD</p>

              <div>
                <h4 className="bold margin-0">
                  {salesReport?.numberOfProductsSold}
                </h4>
              </div>
            </div>

            <div className="d-card">
              <p>PROFIT</p>

              <div>
                <h4 className="bold margin-0">
                  {" "}
                  {amount(salesReport?.profitMade)}
                </h4>
              </div>
            </div>
          </section>

          <CashAtHand
            salesShift={salesShift}
            total={salesReport?.totalRevenue}
          />

          {salesShift.expenses?.length > 0 && (
            <Expenses expenses={salesShift.expenses} />
          )}

          <DaySalesTimeChart data={salesTimelineChart} />

          <BestSellingProductsChart data={bestSellingProducts} />

          <LowStockProducts data={productsLowInStock?.slice(5)} />
        </section>
        <SalesReportHistory data={salesShift.sales} />
      </article>
    </section>
  );
};

export default BranchAnalytics;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { deleteProduct, getAllProducts } from "../../../redux/actions/products";

const DeleteProductModal = ({
  showDeleteProductModal,
  setShowDeleteProductModal,
  product,
}) => {
  const { loading } = useSelector((state) => state.product);
  const { branch } = useSelector((state) => state.branch);
  const dispatch = useDispatch();

  function saveInventory(e) {
    e.preventDefault();
    dispatch(
      deleteProduct(product?._id, () => {
        setShowDeleteProductModal(false);
        dispatch(getAllProducts());
      })
    );
  }

  console.log(product);

  return (
    <Modal
      onClose={() => {
        setShowDeleteProductModal(false);
      }}
      open={showDeleteProductModal}
      center
    >
      <form onSubmit={saveInventory} className="mt-2 p-2">
        <h5 className="mb-1">Confirmation</h5>
        <p>Are you sure you want to delete this product?</p>

        <div className="mt-2">
          <p className="capitalize">{product?.manufacturer}</p>
          <p className="bold capitalize mt-1 margin-0">{product?.name}</p>
        </div>
      
        <div className="mt-2 gap-1 flex ">
          <button type="submit" className="btn">
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              "Delete Product"
            )}
          </button>

          <button
            className="btn grey lighten-1"
            onClick={() => {
              setShowDeleteProductModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteProductModal;

import React from "react";
import { Tab, Tabs } from "react-materialize";
import { useSelector } from "react-redux";
import { amount } from "../../helpers/helper";

const BranchManagement = () => {
  const { branch } = useSelector((state) => state.branch);
  const [products, setProducts] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");

  React.useEffect(() => {
    if (!branch.products) return;
    setProducts(branch?.products);
  }, [branch]);

  return (
    <div>
      <Tabs>
        <Tab title="Products">
          <div className="flex gap-1 align-center mt-1 mb-1">
            <div
              className="custom-input-wrapper "
              style={{ border: "2px solid #ccc" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default custom-input"
                placeholder="search for product..."
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchProduct(value);
                  setProducts(
                    branch.products.filter(
                      (item) =>
                        item.details.name
                          .toLowerCase()
                          .includes(value.toLowerCase()) ||
                        item.details.nickname
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    )
                  );
                }}
                value={searchProduct}
              />
            </div>
          </div>

          {products.map((item, index) => {
            return (
              <div className="p-2 main mb-1 box" key={index}>
                <header className="flex space-between align-center">
                  <div>
                    <p className="small-text">{item.details?.manufacturer}</p>
                    <h6 className="bold">
                      {item.details?.name} {item.details?.size}{" "}
                    </h6>
                  </div>

                  <div>
                    <p className="small-text">Quantity In Stock</p>
                    <h5 className="margin-0 bold teal-text text-darken-3">
                      {item.stock}
                    </h5>
                  </div>
                </header>

                <div>
                  <div className="flex align-center">
                    <p className="small-text mr-1">Wholesale Price: </p>
                    <p className="bold">{amount(item.wholesalePrice)}</p>
                  </div>

                  <div className="flex align-center">
                    <p className="small-text mr-1">Retail Price: </p>
                    <p className="bold">{amount(item.retailPrice)}</p>
                  </div>
                </div>

                <footer className="flex align-center justify-end">
                  <button className="btn">Edit</button>
                </footer>
              </div>
            );
          })}
        </Tab>
        <Tab title="Accounts"></Tab>
      </Tabs>
    </div>
  );
};

export default BranchManagement;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { calculateTotal } from "../actions";
import { addNonTodaySale } from "../../../redux/actions/sale";
import { amount, notify } from "../../../helpers/helper";

const AddNonTodaySaleModal = ({
  showSaveSaleModal,
  setShowSaveSaleModal,
  tab,
  setTabs,
  branchId,
  tabs,
  date,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sale);
  const totalAmount = calculateTotal(tab);

  function saveSale(e) {
    e.preventDefault();

    if (!date) {
      notify("Please select a date", "error");
      setShowSaveSaleModal(false);
      return;
    }

    if (tab.salesType === "credit" && !tab.customer._id) {
      notify("Please select a customer for credit sales", "error");
      setShowSaveSaleModal(false);
      return;
    }

    const products = tab.products.map((product) => {
      return {
        product: product._id,
        quantity: product.quantity,
        price: product.price,
        total: product.total,
      };
    });

    console.log(tab);

    const sale = {
      products,
      total: parseFloat(totalAmount),
      note: "",
      date,
      customer: tab.customer?._id,
      salesType: tab.salesType,
      priceMode: tab.priceMode,
    };

    dispatch(
      addNonTodaySale(sale, branchId, () => {
        setShowSaveSaleModal(false);
        const tabIndex = tab.id - 1;
        const newTabs = [...tabs];

        if (tabIndex === 0) {
          newTabs[tabIndex].products = [];
          newTabs.customer = {
            name: "No customer selected",
            id: "",
            phoneNumber: "N/A",
          };
          setTabs(newTabs);

          return;
        }

        newTabs.splice(tabIndex, 1);
        newTabs[tabIndex - 1].active = true;
        setTabs([...newTabs]);
        localStorage.setItem("nonTodaySalesTab", JSON.stringify(newTabs));
      })
    );
  }
  return (
    <Modal
      onClose={() => {
        setShowSaveSaleModal(false);
      }}
      open={setShowSaveSaleModal}
      center
    >
      <form onSubmit={saveSale} className="mt-2 p-2">
        <h5 className="mb-1">Confirmation</h5>
        <p>Are you sure you want to save this sale </p>

        <h5 className="mt-1">{amount(parseFloat(totalAmount))}</h5>

        <div className="mt-2 gap-1 flex ">
          <button type="submit" className="btn">
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Save Only"}
          </button>

          <button
            className="btn grey lighten-1"
            onClick={() => {
              setShowSaveSaleModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNonTodaySaleModal;

import React from "react";
import { HomeButton } from "../../../components/Buttons";
import AddProduct from "../../../components/AddProduct";
import Modal from "react-responsive-modal";
import AddSystemProduct from "../../products/components/AddSystemProduct";
import { Button, DatePicker } from "react-materialize";
import { useNavigate } from "react-router-dom";

const ReceiveInventoryHeader = ({ branch, date, setDate, setTabs, tabs }) => {
  const navigate = useNavigate();

  return (
    <header className="flex full-width  space-between p-2 sales-header align-center  ">
      <div className="flex align-center">
        <HomeButton />
        <div>
          <h5 className="margin-0">{branch?.name}</h5>
          <p className="small-text">Receive Stock</p>
        </div>
      </div>

      <Button
        className="btn flex align-center justify-center waves-effect waves-light teal darken-2"
        tooltip="Add a new tab"
        onClick={() => {
          const deactiveTabs = tabs.map((tab) => {
            return { ...tab, active: false };
          });

          setTabs([
            ...deactiveTabs,
            {
              id: tabs.length + 1,
              title: `Receipt ${tabs.length + 1}`,
              active: true,
              products: [],
              priceMode: branch.wholesale ? "wholesale" : "retail",
              customer: {
                name: "No customer selected",
                id: "",
                phoneNumber: "N/A",
              },
              salesType: "cash",
              tabType: "inventoryReceipt",
              branch: branch?._id,
            },
          ]);
          navigate(`/receive-inventory/${branch?._id}`);
        }}
      >
        <p>Add Tab</p>
        <span className="material-symbols-outlined">add</span>
      </Button>

      {/* <div className="flex gap-1">
        <button
          className="btn "
          onClick={() => setShowAddSystemProductModal(true)}
        >
          Add System Product
        </button>
        <button
          className="btn white teal-text"
          onClick={() => setShowProductModal(true)}
        >
          Add New Product
        </button>
      </div> */}
    </header>
  );
};

export default ReceiveInventoryHeader;

export const updateProductOnTab = (tabIndex, product, tabs, setTabs) => {
  const tab = tabs[tabIndex];
  tab.products.push({
    ...product,
    quantity: 1,
    total: product.price,
  });

  setTabs([...tabs]);

  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));
  console.log(tab.tabType);

  if (tab.tabType === "inventoryReceipt")
    localStorage.setItem("inventoryTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};

export const increaseProductQuantity = (
  tabIndex,
  product,
  tabs,
  setTabs,
  productIndex
) => {
  const tab = tabs[tabIndex];

  tab.products[productIndex].quantity += 1;
  tab.products[productIndex].total =
    tab.products[productIndex].quantity * tab.products[productIndex].price;

  setTabs([...tabs]);

  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));

  if (tab.tabType === "inventoryReceipt")
    localStorage.setItem("inventoryTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};

export const decreaseProductQuantity = (
  tabIndex,
  product,
  tabs,
  setTabs,
  productIndex
) => {
  if (product.quantity === 1) return;
  const tab = tabs[tabIndex];

  tab.products[productIndex].quantity -= 1;
  tab.products[productIndex].total =
    tab.products[productIndex].quantity * tab.products[productIndex].price;

  setTabs([...tabs]);
  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));

  if (tab.tabType === "inventoryReceipt")
    localStorage.setItem("inventoryTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};

export const removeProduct = (
  tabIndex,
  product,
  tabs,
  setTabs,
  productIndex
) => {
  const tab = tabs[tabIndex];

  tab.products.splice(productIndex, 1);
  setTabs([...tabs]);

  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));

  if (tab.tabType === "inventoryReceipt")
    localStorage.setItem("inventoryTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};

export const calculateTotal = (tab) => {
  return tab.products.reduce((acc, curr) => acc + curr.total, 0)?.toFixed(2);
};

export const updateExpiryDate = (
  tabIndex,
  product,
  tabs,
  setTabs,
  productIndex
) => {
  const tab = tabs[tabIndex];
  tab.products[productIndex].expiryDate = product.expiryDate;
  setTabs([...tabs]);
};

export const setCashPrices = (tabIndex, tabs, setTabs, products) => {
  const tab = tabs[tabIndex];
  tab.salesType = "cash";

  tab.products.forEach((product) => {
    const originalProduct = products.find(
      (originalProduct) => originalProduct.details?._id === product._id
    );
    product.price = originalProduct.wholesalePrice;
    product.total = product.quantity * product.price || 0;
  });

  setTabs([...tabs]);

  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};

export const setCreditPrices = (tabIndex, tabs, setTabs, products) => {
  const tab = tabs[tabIndex];
  tab.salesType = "credit";

  tab.products.forEach((product) => {
    product.price = parseFloat((product.price * 1.05).toFixed(2));
    product.total = product.quantity * product.price || 0;
  });

  setTabs([...tabs]);

  if (tab.tabType === "salesReceipt")
    localStorage.setItem("salesTab", JSON.stringify(tabs));

  if (tab.tabType === "nonTodaySales")
    localStorage.setItem("nonTodaySalesTab", JSON.stringify(tabs));
};


export const locallySavedTabs = (branchId, setTabs, tabType) => {
  if (tabType === "salesTab" && localStorage.getItem("salesTab")) {
    const tab = JSON.parse(localStorage.getItem("salesTab"));
    const salesReceiptTab = tab.filter((tab) => tab.branch === branchId);
    if (salesReceiptTab.length) {
      setTabs(salesReceiptTab);
      return salesReceiptTab;
    }
  }

  if (tabType === "inventoryTab" && localStorage.getItem("inventoryTab")) {
    const tab = JSON.parse(localStorage.getItem("inventoryTab"));
    const inventoryReceiptTab = tab.filter((tab) => tab.branch === branchId);
    if (inventoryReceiptTab.length) {
      setTabs(inventoryReceiptTab);
      return inventoryReceiptTab;
    }
  }

  if (
    tabType === "nonTodaySalesTab" &&
    localStorage.getItem("nonTodaySalesTab")
  ) {
    const tab = JSON.parse(localStorage.getItem("nonTodaySalesTab"));
    const nonTodaySalesTab = tab.filter((tab) => tab.branch === branchId);
    if (nonTodaySalesTab.length) {
      setTabs(nonTodaySalesTab);
      return nonTodaySalesTab;
    }
  }
};

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const getSuppliers = () => async (dispatch) => {
  dispatch({ type: "GETTING_SUPPLIERS" });
  try {
    const res = await POS.get("/api/suppliers/read");
    dispatch({ type: "GOT_SUPPLIERS", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SUPPLIERS_FAILED", payload: error });
  }
};

export const addSupplier = (supplier, callback) => async (dispatch) => {
  dispatch({ type: "ADDING_SUPPLIER" });
  try {
    const res = await POS.post("/api/suppliers/create", supplier);
    dispatch({ type: "ADDED_SUPPLIER", payload: res.data });
    notify("Supplier added successfully", "success");
    callback();
  } catch (error) {
    dispatch({ type: "ADD_SUPPLIER_FAILED", payload: error });
    notify(error.response ? error.response.data : error.message, "error");
  }
};

export const getSupplier = (supplierId) => async (dispatch) => {
  dispatch({ type: "GETTING_SUPPLIER" });
  try {
    const res = await POS.get(`/api/suppliers/read/${supplierId}`);
    dispatch({ type: "GOT_SUPPLIER", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SUPPLIER_FAILED", payload: error });
  }
};

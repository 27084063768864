import React from "react";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { productImg } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import Modal from "react-responsive-modal";
import AddProduct from "../../components/AddProduct";
import { getSuppliers } from "../../redux/actions/supplier";
import AddSupplierModal from "./AddSupplier";
import { useNavigate, useParams } from "react-router-dom";

const Suppliers = () => {
  const { suppliers, loading } = useSelector((state) => state.supplier);
  const { branches } = useSelector((state) => state.branch);
  const [suppliersList, setSuppliersList] = React.useState([]);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const dispatch = useDispatch();
  const branchId = useParams().id;
  const navigate = useNavigate();

  
  React.useEffect(() => {
    setSuppliersList(suppliers);
  }, [suppliers]);

  React.useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  return (
    <div className="main">
      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddSupplierModal
          setShowAddSupplierModal={setShowProductModal}
          showAddSupplierModal={showProductModal}
          branchId={branchId}
        />
      </Modal>
      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Suppliers</h5>
            <p> All suppliers you've added </p>
          </div>
        </div>

        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input"
              placeholder="search for product..."
              onChange={(e) => {
                const value = e.target.value;
                setSuppliersList(
                  suppliers.filter(
                    (item) =>
                      item.name.toLowerCase().includes(value.toLowerCase()) ||
                      item.nickname.toLowerCase().includes(value.toLowerCase())
                  )
                );
              }}
            />
          </div>
          <button className="btn">search</button>
        </div>
        <div className="flex align-center">
          <button
            className="btn mt-1"
            onClick={() => setShowProductModal(true)}
          >
            Add Supplier
          </button>
        </div>
      </header>
      {loading && <ProgressBar />}

      {suppliersList.length === 0 ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <h5>No Products Found</h5>
          <p>
            You have not added any products to your platform. Click the button
            below to add products
          </p>
          <button
            className="btn mt-1"
            onClick={() => setShowProductModal(true)}
          >
            Add Product
          </button>
        </div>
      ) : (
        <section className="p-2 main-body">
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Supplier Name</th>
                <th>Phone Number(s)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {suppliersList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td
                      className="
                    "
                    >
                      <h6>{item.name}</h6>
                    </td>

                    <td className="flex align-center">
                      {item.phoneNumbers.map((number, index) => {
                        return <p key={index}>{number}</p>;
                      })}
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <button
                          className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                          onClick={() => {
                            navigate("/supplier-profile/" + item._id);
                          }}
                        >
                          View
                        </button>
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          <a href="#!">Edit Details</a>
                          <a href="#!">Delete</a>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default Suppliers;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const MakePaymentModal = ({
  showMakePaymentModal,
  setShowMakePaymentModal,
  supplierId,
  supplier,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState("cash");
  const dispatch = useDispatch();
  const [mobileMoneyName, setMobileMoneyName] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const { loading } = useSelector((state) => state.customers);
  const [date, setDate] = React.useState(
    new Date().toISOString().slice(0, 10).replace(/-/g, "-")
  );
  const navigate = useNavigate();

  function pay(e, print = false) {
    e.preventDefault();
    const payment = {
      amount: parseFloat(amount),
      note: "",
      paymentMethod,
      momoName: mobileMoneyName,
      transactionId: "",
      date,
    };
  }

  return (
    <Modal
      onClose={() => {
        setShowMakePaymentModal(false);
      }}
      open={showMakePaymentModal}
      center
    >
      <form className="mt-2 p-2">
        <div className="mb-1">
          <h5 className="margin-0">Enter Payment</h5>
          <p>Record Payment Made to a supplier</p>
        </div>

        <div className="mb-2 ">
          <p className="small-text ">Enter Amount Paid</p>
          <input
            type="number"
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Amount"
            value={amount}
            step="any"
            min="0"
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
            }}
          />
        </div>

        <div className="mb-2">
          <p>Select The Date Amount Was Paid</p>
          <input
            type="date"
            max={new Date().toISOString().slice(0, 10)}
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>

        <div className="mb-2">
          <p>Select Payment Method</p>
          <select
            className="browser-default custom-select"
            required
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="cash">Cash</option>
            <option value="cheque">Cheque</option>
            <option value="momo">Mobile Money</option>
            <option value="">select payment method</option>
          </select>
        </div>

        {paymentMethod === "momo" && (
          <div className="mb-2 ">
            <p className="">Enter Mobile Money Wallet Name </p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Wallet Name"
              value={mobileMoneyName}
              step="any"
              min="0"
              required={paymentMethod === "momo"}
              onChange={(e) => {
                setMobileMoneyName(e.target.value);
              }}
            />
          </div>
        )}
        {paymentMethod === "cheque" && (
          <div className="mb-2">
            <p>Select Cheque Due Date</p>
            <input
              type="date"
              max={new Date().toISOString().slice(0, 10)}
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </div>
        )}

        <div className="mb-2">
          <p>Enter the Cheque Number</p>
          <input
            type="number"
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Amount"
            value={amount}
            step="any"
            min="0"
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
            }}
          />
        </div>

        {loading ? (
        <div className="flex justify-center align-center">
            <ClipLoader size={30} color="#ccc" loading={loading} />
          </div>
        ) : (
          <div className="mt-2 gap-1 flex ">
            <button
              className="btn green flex align-center"
              disabled={
                parseFloat(amount) === 0 || !amount || amount < 0 || loading
              }
              type="button"
              onClick={(e) => {
                pay(e);
              }}
            >
              Save OnLy
            </button>

            <button
              className="btn blue darken-1 flex align-center"
              disabled={
                parseFloat(amount) === 0 || !amount || amount < 0 || loading
              }
              type="button"
              onClick={(e) => {
                pay(e, true);
              }}
            >
              Save & Print
            </button>

            {/* <button
              className="btn blue darken-3 flex align-center"
              disabled={
                (paid < totalAmount && tab.salesType !== "credit") ||
                parseFloat(totalAmount) === 0 ||
                (!paid && tab.salesType !== "credit") ||
                loading
              }
              type="button"
              onClick={(e) => {
                saveSale(e, true);
              }}
            >
              Save & Print
            </button> */}
          </div>
        )}
      </form>
    </Modal>
  );
};

export default MakePaymentModal;

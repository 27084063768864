import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { amount, amountWithoutCurrency } from "../../helpers/helper";
import PrintConfigModal from "../../components/PrintConfigModal";
import { calculateTotal } from "../sale/actions";

const Receipt = () => {
  const { sale } = useSelector((state) => state.sale);
  const [showPrintConfigDialogue, setShowPrintConfigDialogue] =
    React.useState(false);
  const [documentType, setDocumentType] = React.useState("A4");
  const isA4 = documentType === "A4";

  const total = parseFloat(
    calculateTotal({
      products: sale?.products || [],
    })
  );

  useEffect(() => {
    setShowPrintConfigDialogue(true);
  }, [sale]);

  return (
    <div className="flex justify-center">
      <div className={documentType}>
        <PrintConfigModal
          setShowPrintConfigDialogue={setShowPrintConfigDialogue}
          showPrintConfigDialogue={showPrintConfigDialogue}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />

        <section className="flex align-center space-between mb-1 mt-1">
          <div className="flex align-center">
            <img
              src="/images/baiclass.png"
              alt="logo"
              style={{ width: 50, height: 50 }}
            />
            <div className="flex justify column ml-2">
              <h6 className="margin-0">Baiclass Enterprise</h6>
              <p className="small-text">
                Behind main station, Ashaiman, Accra, Ghana
              </p>

              <div className="flex">
                <p className="small-text">+233 24 318 0605</p> /
                <p className="small-text">+233 54 333 3071 </p>
              </div>
            </div>
          </div>

          <div>
            <p className="capitalize bold">{sale.customer?.name}</p>
            <p>{sale.customer?.phoneNumber}</p>
            <p>{sale.customer?.city}</p>
            <p className="bold">
              <i>{new Date().toDateString()}</i>
            </p>
          </div>
        </section>

        <h6 className="text-center small-text bold">PAYMENT RECEIPT</h6>

        <p>
          Payment received from <span className="bold">MOHAMMED NAZIR</span> for
          the purchase of goods and services from Baiclass Enterprise on{" "}
          <span className="bold">12th August, 2021</span> at{" "}
          <span className="bold">12:00pm</span> as follows:
        </p>
        <table className="full-width">
          <thead>
            <tr>
              <th>Customer</th>
              <th>Balance Before </th>
              <th>Amount Paid</th>
              <th>Balance after</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mohammed Nazir</td>
              <td>{amount(2012)}</td>
              <td>{amount(12)}</td>
              <td>{amount(12)}</td>
            </tr>
          </tbody>
        </table>

        <div className="mt-2">
          <p>----------------------------</p>
          <p className="ml-2">signature</p>
        </div>
      </div>
    </div>
  );
};

export default Receipt;

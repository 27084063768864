import React from "react";
import { useNavigate } from "react-router-dom";

export const BackButton = ({ route }) => {
  const navigate = useNavigate();
  return (
    <div>
      <span
        className ="material-symbols-outlined pointer mr-1"
        onClick={() => route || navigate(-1)}
      >
        arrow_back_ios
      </span>
    </div>
  );
};

export const HomeButton = ({ size }) => {
  const navigate = useNavigate();
  return (
    <div>
      <span
        className="material-symbols-outlined pointer mr-2 text-hover"
        onClick={() => navigate("/")}
        style={{ fontSize: size ? size : "30px" }}
      >
        home
      </span>
    </div>
  );
};

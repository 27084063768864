import { toast } from "react-toastify";
export const notify = (message, type) => {
  const config = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 4000,
    hideProgressBar: type === "success" ? true : false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    closeButton: false,
  };

  switch (type) {
    case "success":
      toast.success(message, config);
      break;
    case "error":
      toast.error(message, config);
      break;
    case "warning":
      toast.warning(message, config);
      break;
    case "info":
      toast.info(message, config);
      break;
    default:
      toast(message, config);
      break;
  }
};

export const productImg = "/images/products.png";
export const branchImg = "/images/agency.png";

export const amount = (amount) => {
  return !amount
    ? "0".toLocaleString("en-US", {
        style: "currency",
        currency: "GHS",
        currencyDisplay: "symbol",
      })
    : amount.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS",
        currencyDisplay: "symbol",
      });
};

export const amountWithoutCurrency = (amount) => {
  amount = parseFloat(amount);
  if (isNaN(amount)) return 0;
  return amount.toFixed(2);
};

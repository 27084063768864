import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { amount, amountWithoutCurrency } from "../../../helpers/helper";
import { calculateTotal } from "../../sale/actions";
import { useNavigate } from "react-router-dom";

const StockReceiptModal = ({ showSaleModal, setShowSaleModal }) => {
  const { inventoryReceipt } = useSelector((state) => state.inventory);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const total = parseFloat(
    inventoryReceipt?.products
      ?.reduce((acc, curr) => acc + (curr.basePrice * curr.quantity || 0), 0)
      ?.toFixed(2)
  );

  return (
    <Modal
      open={showSaleModal}
      onClose={() => setShowSaleModal(false)}
      classNames={{
        modal: "receipt-container",
        customOverlay: "customOverlay",
      }}
      showCloseIcon={false}
    >
      <div className="flex justify-end mt-2">
        <button
          className="btn
        hide-on-print"
          onClick={() => {
            dispatch({
              type: "GOT_SALE",
              payload: {
                ...inventoryReceipt,
                products: inventoryReceipt.products.map((product) => ({
                  ...product,
                  price: product.basePrice,
                  total: product.basePrice * product.quantity,
                })),
                total,
              },
            });
            navigate(`/invoice/${inventoryReceipt._id}?type=stock`);
          }}
        >
          Print
        </button>
      </div>

      <section className="flex align-center space-between mb-1 mt-1 gap-1">
        <div className="flex align-center">
          <img
            src="/images/baiclass.png"
            alt="logo"
            style={{ width: 50, height: 50 }}
          />
          <div className="flex justify column ml-2">
            <h6 className="margin-0">Baiclass Enterprise</h6>
            <p className="small-text">
              Behind main station, Ashaiman, Accra, Ghana
            </p>

            <div className="flex">
              <p className="small-text">+233 24 318 0605</p> /
              <p className="small-text">+233 54 333 3071 </p>
            </div>
          </div>
        </div>

        <div className="ml-1">
          <i>
            <b className="capitalize">{inventoryReceipt.customer?.name}</b>
          </i>
          <p> {inventoryReceipt.customer?.addres}</p>
          <p> {inventoryReceipt.customer?.phoneNumber}</p>
        </div>
      </section>

      <section className="flex justify-end">
        <p className="mt-1">
          {new Date(inventoryReceipt.createdAt).toDateString()}
        </p>
      </section>

      <table className="striped">
        <thead>
          <tr>
            <th>
              <p className="small-text">#</p>
            </th>

            <th>
              <p className="small-text">Product</p>
            </th>

            <th>
              <p className="small-text">Quantity</p>
            </th>

            <th>
              <p className="small-text">Unit Price</p>
            </th>

            <th>
              <p className="small-text">Subtotal</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {inventoryReceipt?.products?.map((item, index) => {
            return (
              <tr className="uppercase" key={index}>
                <td>
                  <h6 style={{ fontSize: 12 }}>{index + 1}</h6>
                </td>

                <td style={{ width: "50%" }}>
                  <div>
                    <p style={{ fontSize: 14 }}>{item.product?.manufacturer}</p>
                    <div className="flex gap-1">
                      <p style={{ fontSize: 14 }} className="bold">
                        {item.product?.name}
                      </p>
                      <p style={{ fontSize: 12 }}>{item.product?.size} </p>
                    </div>
                  </div>
                </td>

                <td>
                  <h6 style={{ fontSize: 14 }}>{item.quantity}</h6>
                </td>

                <td>
                  <h6 style={{ fontSize: 14 }}>{amount(item.basePrice)}</h6>
                </td>

                <td>
                  <h6 style={{ fontSize: 14 }}>
                    {amountWithoutCurrency(item.basePrice * item.quantity)}
                  </h6>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <aside>
        <section className="sales-subtotal">
          <div className="flex space-between align-center">
            <p className="grey-text" style={{ fontSize: 14 }}>
              Subtotal
            </p>
            <p className="bold">{total.toFixed(2)}</p>
          </div>
          <div
            className="flex space-between align-center"
            style={{ gap: "50px" }}
          >
            <p className="grey-text" style={{ fontSize: 14 }}>
              Discount
            </p>
            <p className="bold">0.00</p>
          </div>
          <div
            className="flex space-between align-center"
            style={{ gap: "50px" }}
          >
            <p className="grey-text" style={{ fontSize: 14 }}>
              Tax
            </p>
            <p className="bold">0.00</p>
          </div>

          <div className="flex space-between align-center">
            <p className="bold" style={{ fontSize: 14 }}>
              Total
            </p>
            <h6 className="bold">{amount(total)}</h6>
          </div>
        </section>
      </aside>
    </Modal>
  );
};

export default StockReceiptModal;

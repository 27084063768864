import axios from "axios";
import { posConfig } from "./config";

export const POS = (() => {
  let POS = axios.create({
    baseURL: posConfig.baseURL,
  });
  const token = localStorage.getItem("__beemPOS");
  if (token) POS.defaults.headers.common["X-Auth-Token"] = token;
  return POS;
})();

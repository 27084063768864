import React from "react";
import { login } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);
  const emailRef = React.useRef();
  const passwordRef = React.useRef();

  const send = (e) => {
    e.preventDefault();
    if (!email) {
      emailRef.current.focus();
      emailRef.current.classList.add("custom-input-error");
      return;
    }

    if (!password) {
      passwordRef.current.focus();
      passwordRef.current.classList.add("custom-input-error");
      return;
    }
    dispatch(login({ medium:email, password }));
  };
  return (
    <div className="flex  justify-center align-center  login-container">
      <form onSubmit={send}>
        <h4>Login</h4>

        <div className="mb-2">
          <p>Enter Your User Name or Email</p>

          <input
            type="text"
            className={"browser-default custom-text-input"}
            placeholder="Eg. someone@example.com"
            value={email}
            onChange={(e) => {
              emailRef.current.classList.remove("custom-input-error");
              setEmail(e.target.value);
            }}
            ref={emailRef}
          />
        </div>

        <div className="mb-2 ">
          <p>Enter Your Password</p>
          <input
            type="password"
            className="browser-default
               custom-text-input"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => {
              passwordRef.current.classList.remove("custom-input-error");
              setPassword(e.target.value);
            }}
            ref={passwordRef}
          />
        </div>

        <div className="mb-2 flex justify-center ">
          <button
            className="btn flex align-center justify-center"
            type="submit"
          >
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, ProgressBar } from "react-materialize";
import { useParams } from "react-router-dom";
import { getSupplier } from "../../redux/actions/supplier";
import InvetoryReceiptHistory from "./InvetoryReceiptHistory";
import { amount } from "../../helpers/helper";
import MakePaymentModal from "./EnterPayment";

const SupplierProfile = () => {
  const { branch } = useSelector((state) => state.branch);
  const { supplier, loading } = useSelector((state) => state.supplier);
  const [showMakePaymentModal, setShowMakePaymentModal] = React.useState(false);
  const supplierId = useParams().id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSupplier(supplierId));
  }, [dispatch, supplierId]);

  return (
    <div>
      <MakePaymentModal
        showMakePaymentModal={showMakePaymentModal}
        setShowMakePaymentModal={setShowMakePaymentModal}
        supplierId={supplier._id}
        supplier={supplier}
      />
      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Supplier Profile</h5>
            <h6 className="bold margin-0">{branch?.name}</h6>
          </div>
        </div>

        <Dropdown
          trigger={<a className="btn-small white teal-text">I want to...</a>}
        >
          <span
            onClick={() => {
              setShowMakePaymentModal(true);
            }}
          >
            Enter Payment
          </span>
          <span>Add to outstanding balance</span>
          <span>Print</span>
        </Dropdown>
      </header>
      {loading && <ProgressBar />}
      <div className="p-1">
        <h5 className="capitalize">{supplier?.name}</h5>
        <p className="capitalize">
          {supplier?.phoneNumbers?.map((phone) => (
            <span key={phone}>{phone},</span>
          ))}
        </p>
      </div>

      <div>
        <section className="flex space-between mt-1  gap-1">
          <div className="d-card">
            <p className="">TOTAL PROCUREMENT</p>

            <div>
              <h4 className="bold margin-0">
                {amount(supplier?.totalProcurement)}
              </h4>
            </div>
          </div>

          <div className="d-card">
            <p>OUTSTANDING BALANCE</p>

            <div>
              <h4 className="bold margin-0">
                {amount(supplier?.outstandingBalance)}
              </h4>
            </div>
          </div>
        </section>
      </div>

      <InvetoryReceiptHistory
        branch={branch}
        inventoryReceipts={supplier?.inventoryReceipts || []}
      />
    </div>
  );
};

export default SupplierProfile;

const salesReducer = (
  state = {
    loading: false,
    sales: [],
    sale: {},
    salesShift: {},
    salesReport: {},
    dailyTotalSales: {},
    salesReceiptTab: {},
    salesTimelineChart: [],
    bestSellingProducts: [],
    productsLowInStock: [],
    deleting: false,
  },
  action
) => {
  switch (action.type) {
    case "GETTING_SALES":
      return {
        ...state,
        loading: true,
      };
    case "GOT_SALES":
      return {
        ...state,
        loading: false,
        sales: action.payload,
      };
    case "GET_SALES_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_SALE":
      return {
        ...state,
        loading: true,
      };

    case "ADDED_SALE":
      return {
        ...state,
        loading: false,
        sales: [...state.sales, action.payload],
      };

    case "ADD_SALE_FAILED":
      return {
        ...state,
        loading: false,
        sales: [...state.sales, action.payload],
      };

    case "UPDATING_SALE":
      return {
        ...state,
        loading: true,
      };

    case "UPDATED_SALE":
      return {
        ...state,
        loading: false,
        sale: action.payload,
      };

    case "UPDATE_SALE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_SALE":
      return {
        ...state,
        loading: true,
      };

    case "GOT_SALE":
      return {
        ...state,
        loading: false,
        sale: action.payload,
      };

    case "GET_SALE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETING_SALE":
      return {
        ...state,
        deleting: true,
      };

    case "DELETED_SALE":
      return {
        ...state,
        deleting: false,
        sales: state.sales.filter((sale) => sale._id !== action.payload._id),
      };

    case "DELETE_SALE_FAILED":
      return {
        ...state,
        deleting: false,
      };

    case "ENDING_DAY":
      return {
        ...state,
        loading: true,
      };

    case "ENDED_DAY":
      return {
        ...state,
        loading: false,
      };

    case "END_DAY_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_EXPENSE":
      return {
        ...state,
        loading: true,
      };

    case "ADDED_EXPENSE":
      return {
        ...state,
        loading: false,
      };

    case "ADD_EXPENSE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_SALES_SHIFT":
      return {
        ...state,
        loading: true,
      };

    case "GOT_SALES_SHIFT":
      return {
        ...state,
        loading: false,
        salesShift: action.payload,
      };

    case "GET_SALES_SHIFT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_SALES_REPORT":
      return {
        ...state,
        loading: true,
      };

    case "GOT_SALES_REPORT":
      return {
        ...state,
        loading: false,
        salesReport: action.payload,
      };

    case "GET_SALES_REPORT_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_SALES_TIMELINE_CHART":
      return {
        ...state,
        loading: true,
      };

    case "GOT_SALES_TIMELINE_CHART":
      return {
        ...state,
        loading: false,
        salesTimelineChart: action.payload,
      };

    case "GET_SALES_TIMELINE_CHART_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GET_BEST_SELLING_PRODUCTS":
      return {
        ...state,
        loading: true,
      };

    case "GOT_BEST_SELLING_PRODUCTS":
      return {
        ...state,
        loading: false,
        bestSellingProducts: action.payload,
      };

    case "GET_BEST_SELLING_PRODUCTS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_PRODUCTS_LOW_IN_STOCK":
      return {
        ...state,
        loading: true,
      };

    case "GOT_PRODUCTS_LOW_IN_STOCK":
      return {
        ...state,
        loading: false,
        productsLowInStock: action.payload,
      };

    case "GET_PRODUCTS_LOW_IN_STOCK_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_NON_TODAY_SALE":
      return {
        ...state,
        loading: true,
      };

    case "ADDED_NON_TODAY_SALE":
      return {
        ...state,
        loading: false,
      };

    case "ADD_NON_TODAY_SALE_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GOT_SALES_RECEIPT_TAB":
      return {
        ...state,
        salesReceiptTab: action.payload,
      };

    case "RESET_SALES_RECEIPT_TAB":
      return {
        ...state,
        salesReceiptTab: {},
      };

    case "CLEAR_SALES_REPORT":
      return {
        ...state,
        loading: false,
        salesShift: {},
        salesReport: {},
        salesTimelineChart: [],
        bestSellingProducts: [],
      };

    case "GETTING_DAILY_TOTAL_SALES":
      return {
        ...state,
        loading: true,
      };

    case "GOT_DAILY_TOTAL_SALES":
      return {
        ...state,
        loading: false,
        dailyTotalSales: action.payload,
      };

    case "GET_DAILY_TOTAL_SALES_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default salesReducer;

import React, { useEffect } from "react";
import { BackButton, HomeButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDailyTotalSales } from "../../redux/actions/sale";
import {} from "date-fns";
import { subDays } from "date-fns";
import DatePicker from "react-datepicker";
import { getBranch } from "../../redux/actions/branches";
import { format } from "date-fns";
import { ProgressBar } from "react-materialize";

const DailyTotalSalesList = () => {
  const { branch } = useSelector((state) => state.branch);
  const { id } = useParams();
  const query = new URLSearchParams(window.location.search);
  const [startDate, setStartDate] = React.useState(
    query.get("startDate")
      ? new Date(query.get("startDate"))
      : subDays(new Date(), 7)
  );
  const [endDate, setEndDate] = React.useState(
    query.get("endDate") ? new Date(query.get("endDate")) : new Date()
  );
  const { dailyTotalSales, loading } = useSelector((state) => state.sale);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDailyTotalSales(
        id,
        startDate.toJSON().slice(0, 10),
        endDate.toJSON().slice(0, 10)
      )
    );
  }, [dispatch, endDate, id, startDate]);

  useEffect(() => {
    dispatch(getBranch(id));
  }, [id, dispatch]);

  return (
    <div>
      <header className="flex full-width  space-between p-2 sales-header align-center ">
        <div className="flex align-center">
          <HomeButton />
          <div>
            <h5 className="margin-0">Daily Sales Total</h5>
            <p className="bold ">{branch?.name}</p>
          </div>
        </div>

        <div className="flex align-center gap-1 ">
          <div className="flex align-center gap-1" style={{ zIndex: 100 }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                navigate(
                  `/daily-total-sales/${id}?startDate=${date
                    .toJSON()
                    .slice(0, 10)}&endDate=${endDate.toJSON().slice(0, 10)}`
                );
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
            />
            <p className="bold">To</p>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                navigate(
                  `/daily-total-sales/${id}?startDate=${startDate
                    .toJSON()
                    .slice(0, 10)}&endDate=${date.toJSON().slice(0, 10)}`
                );
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
      </header>

      {loading && <ProgressBar />}

      <div className="daily-total-sales-container">
        <div className="flex space-between grey lighten-4 p-2 mb-1">
          <div>
            <p className="bold ">{branch?.name}</p>
            <p>
              {format(startDate, "dd MMM yyyy")} -{" "}
              {format(endDate, "dd MMM yyyy")}
            </p>
          </div>

          <button
            className="btn-small black"
            onClick={() =>
              navigate(
                `/print-daily-total-sales?startDate=${startDate
                  .toJSON()
                  .slice(0, 10)}&endDate=${endDate.toJSON().slice(0, 10)}`
              )
            }
          >
            Export
          </button>
        </div>
        <table className="striped">
          <thead>
            <tr>
              <th>
                <p className="small-text">Date</p>
              </th>

              <th>
                <p className="small-text">Total</p>
              </th>

              <th>
                <p className="small-text">Action</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {dailyTotalSales.eachDay?.map((item, index) => {
              return (
                <tr className="" key={index} style={{ padding: 0 }}>
                  <td>{new Date(item.date).toDateString()}</td>

                  <td>{item.total}</td>
                  <td>
                    <button
                      className="btn-small"
                      onClick={() =>
                        navigate(
                          `/sales-report/${branch._id}?date=${item.date.slice(
                            0,
                            10
                          )}`
                        )
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end p-2">
        <div className="flex align-end gap-1">
          <p>Total:</p>
          <h4 className="margin-0">{dailyTotalSales.totalSales}</h4>
        </div>
      </div>
    </div>
  );
};

export default DailyTotalSalesList;

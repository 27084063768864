import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { endOfDay } from "../../redux/actions/sale";
import { ClipLoader } from "react-spinners";

const EndOfDayModal = ({ showEndOfDayModal, setShowEndOfDayModal, branch }) => {
  const [cashInHand, setCashInHand] = React.useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sale);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      endOfDay(branch, cashInHand, () => {
        setShowEndOfDayModal(false);
        setCashInHand("");
      })
    );
  };

  return (
    <Modal
      onClose={() => setShowEndOfDayModal(false)}
      open={showEndOfDayModal}
      center
    >
      <form
        className="mt-2 p-2"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h5>End Of Day</h5>
        <p>Close your cash register, for</p>
        <p className="bold teal-text">{new Date().toDateString()}</p>

        <div className="mb-2 mt-2">
          <p>Enter Cash In Hand </p>
          <input
            type="number"
            className="browser-default
               custom-text-input"
            placeholder="Enter Cash In Hand"
            value={cashInHand}
            onChange={(e) => {
              setCashInHand(e.target.value);
            }}
            min="0"
          />
        </div>

        <div>
          <button className="btn" type="submit" disabled={cashInHand === ""}>
            {loading ? <ClipLoader size={20} color={"#fff"} /> : "Submit Sales"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EndOfDayModal;

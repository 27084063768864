import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SaleHeader from "./SaleHeader";
import { Tab, Tabs } from "react-materialize";
import SalesTab from "./SalesTab";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../redux/actions/branches";
import { isEmpty } from "lodash";
import Loading from "../../components/Loading";
import { getCustomers } from "../../redux/actions/customers";
import { locallySavedTabs } from "./actions";

const Sale = () => {
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const searchProductDropdownRef = React.useRef(null);
  const [searchProduct, setSearchProduct] = React.useState("");
  const { branch } = useSelector((state) => state.branch);
  const { salesReceiptTab } = useSelector((state) => state.sale);
  const branchId = useParams().id;
  const dispatch = useDispatch();
  const [tabs, setTabs] = React.useState([
    {
      id: 1,
      title: "Sales Tab 1",
      active: true,
      products: [],
      priceMode: branch.wholesale ? "wholesale" : "retail",
      customer: {
        name: "No customer selected",
        id: "",
        phoneNumber: "N/A",
      },
      salesType: "cash",
      tabType: "salesReceipt",
      branch: branchId,
    },
  ]);

  useEffect(() => {
    dispatch(getBranch(branchId));
    dispatch(getCustomers(branchId));
  }, [dispatch, branchId]);

  useEffect(() => {
    if (!isEmpty(salesReceiptTab) && salesReceiptTab.branch === branchId) {
      const salesReceiptTabClone = { ...salesReceiptTab };
      salesReceiptTabClone.active = true;
      salesReceiptTabClone.id = tabs.length + 1;
      salesReceiptTabClone.title = `Sales Tab ${tabs.length + 1} Edit`;
      console.log(salesReceiptTabClone);

      let final = [
        ...tabs.map((tab) => ({ ...tab, active: false })),
        salesReceiptTabClone,
      ];

      localStorage.setItem("salesTab", JSON.stringify(final));
      setTabs([...final]);
      // dispatch({ type: "RESET_SALES_RECEIPT_TAB" });
    }
  }, [branch.wholesale, dispatch]);

  useEffect(() => {
    locallySavedTabs(branchId, setTabs, "salesTab");
  }, [branchId]);

  if (isEmpty(branch)) return <Loading />;

  return (
    <div className="main lighten-4">
      <SaleHeader
        inputRef={inputRef}
        searchProductDropdownRef={searchProductDropdownRef}
        searchProduct={searchProduct}
        setSearchProduct={setSearchProduct}
        navigate={navigate}
        setTabs={setTabs}
        tabs={tabs}
      />

      <section className="sales-section  ">
        <div className="" style={{ height: "100%" }}>
          <Tabs className="">
            {tabs.map((tab, i) => {
              return (
                <Tab title={tab.title} active={tab.active} key={i} idx={`${i}`}>
                  <SalesTab
                    tab={tab}
                    setTabs={setTabs}
                    tabs={tabs}
                    tabIndex={i}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </section>
    </div>
  );
};

export default Sale;

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const getALlBranches = (cb) => async (dispatch) => {
  dispatch({ type: "GETTING_BRANCHES" });
  try {
    const res = await POS.get("/api/branches/read");
    dispatch({ type: "GOT_BRANCHES", payload: res.data });

    if (cb) cb();
  } catch (error) {
    dispatch({ type: "GET_BRANCHES_FAILED", payload: error });
  }
};

export const getBranch = (id, cb) => async (dispatch) => {
  dispatch({ type: "GETTING_BRANCH" });
  try {
    const res = await POS.get(`/api/branches/read/${id}`);
    dispatch({ type: "GOT_BRANCH", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "GET_BRANCH_FAILED", payload: error });
  }
};

const addStock = (stock, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_STOCK" });
  try {
    const res = await POS.post("/api/stocks/create", stock);
    dispatch({ type: "STOCK _ADDED", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "STOCK_ADD_FAILED", payload: error });
  }
};

export const createBranch = (branch, cb) => async (dispatch) => {
  dispatch({ type: "CREATING_BRANCH" });
  try {
    const res = await POS.post("/api/branches/create", branch);
    dispatch({ type: "CREATED_BRANCH", payload: res.data });
    if (cb) cb();
    notify("Branch created successfully", "success");
  } catch (error) {
    dispatch({ type: "CREATE_BRANCH_FAILED", payload: error });
    notify(error.response ? error.response.data : "An error occured", "error");
  }
};

import React, { useEffect } from "react";
import { Autocomplete, Chip, Icon } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { addSupplier } from "../../redux/actions/supplier";

const AddSupplierModal = ({
  setShowAddSupplierModal,
  showAddSupplierModal,
  branchId,
}) => {
  const { loading } = useSelector((state) => state.supplier);
  const [values, setValues] = React.useState({
    name: "",
    phoneNumbers: [],
  });
  const dispatch = useDispatch();
  console.log(branchId);

  const send = (e) => {
    e.preventDefault();

    const supplier = {
      name: values.name,
      phoneNumbers: values.phoneNumbers,
      branch: branchId,
    };

    dispatch(
      addSupplier(supplier, () => {
        setValues({
          name: "",
          phoneNumbers: [],
        });
        setShowAddSupplierModal(false);
      })
    );
  };

  return (
    <form onSubmit={send} className="mt-2 p-2 ">
      <h4>Add A Supplier</h4>

      <div className="mb-2">
        <p>
          Enter Supplier's name
          <span className="red-text"> * </span>
        </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. JD Pharmaceuticals"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          required
        />
      </div>

      <div className="mb-2">
        <p>Enter Phone Number </p>
        <input
          type="number"
          step="any"
          min="0"
          className="browser-default
               custom-text-input"
          placeholder="Eg.  08012345678"
          value={values.phoneNumbers[0]}
          onChange={(e) =>
            setValues({ ...values, phoneNumbers: [e.target.value] })
          }
        />
      </div>

      <button
        className="btn"
        type="submit"
        disabled={!values.name || !values.phoneNumbers[0] || loading}
      >
        {loading ? (
          <ClipLoader color="#fff" size={20} />
        ) : (
          <span>
            <Icon left>add</Icon> Add Supplier
          </span>
        )}
      </button>
    </form>
  );
};

export default AddSupplierModal;

import React from "react";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { account } = useSelector((state) => state.auth);
  return (
    <div className="side-bar white">
      <div>
        <div className="mb-2 p-2">
          <img src="/images/baiclass.png" alt="logo" className="my-logo" />
          <p className="uppercase grey-text small-text">{account?.company?.name}</p>
        </div>
        <ul>
          <li className="active">
            <span className="material-symbols-outlined">dashboard</span>
            <p>Main Menu</p>
          </li>
          <li>
            <span className="material-symbols-outlined">
              insert_chart_outlined
            </span>
            <p>Analytics</p>
          </li>

          <li>
            <span className="material-symbols-outlined">people</span>
            <p>Management</p>
          </li>
          <li>
            <span className="material-symbols-outlined">timeline</span>
            <p>Reports</p>
          </li>
          <li>
            <span className="material-symbols-outlined">settings</span>
            <p>settings</p>
          </li>
        </ul>
      </div>

      <div className="flex align-center column">
        <p className="small-text"></p>
        <p className="small-text">copyright&copy;{new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default Sidebar;

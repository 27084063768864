import React from "react";
import { amount } from "../../helpers/helper";
import { Dropdown } from "react-materialize";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StockReceiptModal from "../inventory/components/StockReceiptModal";
import ConfirmDeleteInventoryReceiptModal from "../inventory/components/ConfirmDeleteInventoryReceipt";

const InvetoryReceiptHistory = ({ inventoryReceipts, branch }) => {
  const [showSaleModal, setShowSaleModal] = React.useState(false);
  const [showDeleteInventoryReceiptModal, setShowDeleteInventoryReceiptModal] =
    React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <section className="p-2 main-body">
      <StockReceiptModal
        showSaleModal={showSaleModal}
        setShowSaleModal={setShowSaleModal}
      />

      <ConfirmDeleteInventoryReceiptModal
        showDeleteInventoryReceiptModal={showDeleteInventoryReceiptModal}
        setShowDeleteInventoryReceiptModal={setShowDeleteInventoryReceiptModal}
        id={branch._id}
      />
      <table className="">
        <thead>
          <tr>
            <th>#</th>
            <th>Date & Time</th>
            <th>Received By</th>
            <th>Total Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {inventoryReceipts.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>

                <td>
                  <p>
                    {new Date(item.createdAt).toDateString()},{" "}
                    {new Date(item.createdAt).toLocaleTimeString()}{" "}
                  </p>
                </td>
                <td>
                  <p>{item.receivedBy?.name} </p>
                </td>
                <td>
                  <h6>{amount(item.totalCost)}</h6>
                </td>

                <td className="">
                  <div className="flex align-center">
                    <button
                      className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                      onClick={() => {
                        setShowSaleModal(true);
                        dispatch({
                          type: "GOT_INVENTORY_RECEIPT",
                          payload: item,
                        });
                      }}
                    >
                      View
                    </button>
                    <Dropdown
                      trigger={
                        <i className="material-symbols-outlined ">more_vert</i>
                      }
                    >
                      <span
                        onClick={() => {
                          setShowDeleteInventoryReceiptModal(true);
                          dispatch({
                            type: "GOT_INVENTORY_RECEIPT",
                            payload: item,
                          });
                        }}
                      >
                        Reverse Invoice
                      </span>
                      <span
                        onClick={() => {
                          const products = item.products.map((product) => {
                            const data = {
                              expiryDate: "",
                              manufacturer: product.product.manufacturer,
                              name: product.product.name,
                              price: product.basePrice,
                              quantity: product.quantity,
                              retailPrice: product.retailPrice,
                              size: "200ml",
                              total: product.basePrice * product.quantity,
                              type: "syrup",
                              wholesalePrice: product.wholesalePrice,
                              _id: product.product._id,
                            };

                            return data;
                          });

                          const tab = {
                            id: 1,
                            title: "Sales Tab 1",
                            active: true,
                            products,
                            supplier: {
                              name: "No Supplier Selected",
                              id: "",
                              phoneNumber: "N/A",
                            },
                          };

                          dispatch({
                            type: "GOT_INVENTORY_RECEIPT_TAB",
                            payload: tab,
                          });

                          navigate("/receive-inventory/" + branch._id);
                        }}
                      >
                        Copy Invoice
                      </span>

                      <span
                        onClick={() => {
                          const products = item.products.map((product) => {
                            const data = {
                              expiryDate: "",
                              manufacturer: product.product.manufacturer,
                              name: product.product.name,
                              price: product.basePrice,
                              quantity: product.quantity,
                              retailPrice: product.retailPrice,
                              size: "200ml",
                              total: product.basePrice * product.quantity,
                              type: "syrup",
                              wholesalePrice: product.wholesalePrice,
                              _id: product.product._id,
                            };

                            return data;
                          });

                          const tab = {
                            id: 1,
                            title: "Sales Tab 1",
                            active: true,
                            products,
                            supplier: {
                              name:
                                item.supplier?.name || "No Supplier Selected",
                              id: item.supplier?._id || "",
                              phoneNumber: item.supplier?.phoneNumber || "N/A",
                            },
                            saved: true,
                            stockReceiptId: item._id,
                          };

                          dispatch({
                            type: "GOT_INVENTORY_RECEIPT_TAB",
                            payload: tab,
                          });

                          navigate("/receive-inventory/" + branch._id);
                        }}
                      >
                        Update Invoice
                      </span>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default InvetoryReceiptHistory;

import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { notify } from "../../helpers/helper";
import { editPayment, getCustomer } from "../../redux/actions/customers";
import { useNavigate } from "react-router-dom";

const EditPaymentModal = ({
  showMakePaymentModal,
  setShowMakePaymentModal,
  customerId,
  payment,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState("cash");
  const dispatch = useDispatch();
  const [mobileMoneyName, setMobileMoneyName] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const { loading } = useSelector((state) => state.customers);
  const [date, setDate] = React.useState("");
  const [chequeNumber, setChequeNumber] = React.useState("");
  const [chequeDueDate, setChequeDueDate] = React.useState("");
  const [chequeBankName, setChequeBankName] = React.useState("");
  const [chequeBranch, setChequeBranch] = React.useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setAmount(payment.amount || 0);
    setPaymentMethod(payment.paymentMethod || "cash");
    setMobileMoneyName(payment.momoName || "");
    setDate(
      payment.date?.slice(0, 10).replace(/-/g, "-") ||
        new Date().toISOString().slice(0, 10).replace(/-/g, "-")
    );
    if (payment.paymentMethod === "cheque") {
      setChequeBankName(payment.cheque.bank || "");
      setChequeBranch(payment.cheque.branch || "");
      setChequeNumber(payment.cheque.number || "");
      setChequeDueDate(
        payment.cheque.date?.slice(0, 10).replace(/-/g, "-") || ""
      );
    }
  }, [payment]);

  function pay(e, print = false) {
    e.preventDefault();

    if (paymentMethod === "cheque") {
      if (!chequeBankName || !chequeBranch || !chequeNumber || !chequeDueDate) {
        return notify("Please fill all cheque details", "error");
      }
    }

    if (paymentMethod === "momo") {
      if (!mobileMoneyName) {
        return notify("Please fill mobile money name", "error");
      }
    }

    const data = {
      amount: parseFloat(amount),
      note: "",
      paymentMethod,
      momoName: mobileMoneyName,
      transactionId: "",
      date,
      cheque: {
        bank: chequeBankName,
        branch: chequeBranch,
        number: chequeNumber,
        date: chequeDueDate,
      },
    };

    if (!paymentMethod === "cheque") delete data.cheque;

    dispatch(
      editPayment(payment._id, data, () => {
        dispatch(getCustomer(customerId));
        setPaymentMethod("cash");
        setAmount(0);
        setMobileMoneyName("");
        setShowMakePaymentModal(false);
        setDate(new Date().toISOString().slice(0, 10).replace(/-/g, "-"));
        setChequeBankName("");
        setChequeBranch("");
        setChequeNumber("");
        setChequeDueDate("");
        notify("Payment Editted Successfully!", "success");
        if (print) navigate(`/print-receipt/${customerId}`);
      })
    );
  }

  return (
    <Modal
      onClose={() => {
        setShowMakePaymentModal(false);
      }}
      open={showMakePaymentModal}
      center
    >
      <form className="mt-2 p-2">
        <div className="mb-1">
          <h5 className="margin-0">Edit Payment</h5>
          <p>Record Payment Received From A Customer </p>
        </div>

        <div className="mb-2 ">
          <p className="small-text ">Enter Amount Received</p>
          <input
            type="number"
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Amount"
            value={amount}
            step="any"
            min="0"
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
            }}
          />
        </div>

        <div className="mb-2">
          <p>Select The Date Amount Was Received</p>
          <input
            type="date"
            max={new Date().toISOString().slice(0, 10)}
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>

        <div className="mb-2">
          <p>Select Payment Method</p>
          <select
            className="browser-default custom-select"
            required
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="cash">Cash</option>
            <option value="momo">Mobile Money</option>
            <option value="cheque">Cheque</option>
            <option value="">select payment method</option>
          </select>
        </div>

        {paymentMethod === "momo" && (
          <div className="mb-2 ">
            <p className="">Enter Mobile Money Wallet Name </p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Wallet Name"
              value={mobileMoneyName}
              step="any"
              min="0"
              required={paymentMethod === "momo"}
              onChange={(e) => {
                setMobileMoneyName(e.target.value);
              }}
            />
          </div>
        )}
        {paymentMethod === "cheque" && (
          <div className="mb-2 ">
            <p className="">Enter Cheque's Bank Name</p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Cheque Bank Name"
              value={chequeBankName}
              step="any"
              min="0"
              required={paymentMethod === "cheque"}
              onChange={(e) => {
                setChequeBankName(e.target.value);
              }}
            />
          </div>
        )}

        {paymentMethod === "cheque" && (
          <div className="mb-2 ">
            <p className="">Enter Cheque's bank branch</p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Cheque Bank Branch"
              value={chequeBranch}
              step="any"
              min="0"
              required={paymentMethod === "cheque"}
              onChange={(e) => {
                setChequeBranch(e.target.value);
              }}
            />
          </div>
        )}

        {paymentMethod === "cheque" && (
          <div className="mb-2 ">
            <p className="">Enter Cheque's Number</p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Cheque Number"
              value={chequeNumber}
              step="any"
              min="0"
              required={paymentMethod === "cheque"}
              onChange={(e) => {
                setChequeNumber(e.target.value);
              }}
            />
          </div>
        )}

        {paymentMethod === "cheque" && (
          <div className="mb-2">
            <p>Select The Due Date Of The Cheque</p>
            <input
              type="date"
              max={new Date().toISOString().slice(0, 10)}
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Date"
              value={chequeDueDate}
              onChange={(e) => {
                setChequeDueDate(e.target.value);
              }}
            />
          </div>
        )}

        {loading ? (
          <div className="flex justify-center align-center">
            <ClipLoader size={30} color="#ccc" loading={loading} />
          </div>
        ) : (
          <div className="mt-2 gap-1 flex ">
            <button
              className="btn green flex align-center"
              disabled={
                parseFloat(amount) === 0 || !amount || amount < 0 || loading
              }
              type="button"
              onClick={(e) => {
                pay(e);
              }}
            >
              Save OnLy
            </button>

            <button
              className="btn blue darken-1 flex align-center"
              disabled={
                parseFloat(amount) === 0 || !amount || amount < 0 || loading
              }
              type="button"
              onClick={(e) => {
                pay(e, true);
              }}
            >
              Save & Print
            </button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default EditPaymentModal;

import React, { useEffect } from "react";
import { Autocomplete, Chip, Icon, Switch } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { createBranch } from "../../../redux/actions/branches";

const AddBranch = ({ setShowAddBranchModal }) => {
  const { branches, loading } = useSelector((state) => state.branch);
  const [values, setValues] = React.useState({
    name: "",
    phoneNumber: "",
    address: "",
    wholesalePricePercentage: 10,
    retailPricePercentage: 40,
    wholesale: false,
    retail: true,
    roundWholesalePrices: false,
    roundRetailPrices: false,
    currency: "GHS",
  });
  const dispatch = useDispatch();

  const send = (e) => {
    e.preventDefault();
    const data = {
      ...values,
      wholesalePricePercentage: values.wholesalePricePercentage / 100,
      retailPricePercentage: values.retailPricePercentage / 100,
    };

    dispatch(createBranch(data, () => setShowAddBranchModal(false)));
  };

  return (
    <form onSubmit={send} className="mt-2 p-2 ">
      <h4>Add A branch</h4>

      <div className="mb-2">
        <p>
          Enter Branch Name
          <span className="red-text"> * </span>
        </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. John Doe Pharmacy - Spintex"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          required
        />
      </div>

      <div className="mb-2">
        <p>Enter Branch Address </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. Behind the Mall, Spintex"
          value={values.address}
          onChange={(e) => setValues({ ...values, address: e.target.value })}
        />
      </div>

      <div className="mb-2">
        <p>
          Enter Branch Phone Number
          <span className="red-text"> * </span>
        </p>
        <input
          type="text"
          className="browser-default
               custom-text-input"
          placeholder="Eg. 024 123 4567"
          value={values.phoneNumber}
          onChange={(e) =>
            setValues({ ...values, phoneNumber: e.target.value })
          }
          required
        />
      </div>

      <div className="mb-2">
        <p>Choose Branch Currency </p>
        <select
          className="browser-default custom-select"
          required
          onChange={(e) => setValues({ ...values, currency: e.target.value })}
          value={values.currency}
        >
          <option value="" selected>
            Select Currency
          </option>
          <option value="GHS">GHS</option>
          <option value="USD">USD</option>
        </select>
      </div>

      <fieldset>
        <legend>Wholesale Branch</legend>
        <div className="mb-2  p-1">
          <p className=""> Enable Wholesale </p>
          <Switch
            offLabel="No wholesale"
            onChange={() =>
              setValues({
                ...values,
                wholesale: values.wholesale ? false : true,
              })
            }
            onLabel="Enable wholesale"
            checked={values.wholesale}
          />
        </div>
        {values.wholesale && (
          <div className="mb-2">
            <p className="small-text">Enter Wholesale Price Percentage</p>
            <input
              type="number"
              className="browser-default
                custom-text-input"
              placeholder="Eg. 10"
              value={values.wholesalePricePercentage}
              onChange={(e) =>
                setValues({
                  ...values,
                  wholesalePricePercentage: e.target.value,
                })
              }
            />{" "}
            <span>%</span>
          </div>
        )}
        {values.wholesale && (
          <div className="mb-2">
            <p className="small-text">Round Wholesale Prices </p>
            <Switch
              offLabel="No"
              onChange={() =>
                setValues({
                  ...values,
                  roundWholesalePrices: values.roundWholesalePrices
                    ? false
                    : true,
                })
              }
              onLabel="Yes"
              checked={values.roundWholesalePrices}
            />
          </div>
        )}
      </fieldset>

      <fieldset>
        <legend>Retail Branch</legend>
        <div className="mb-2">
          <p className="bold"> Enable Retail </p>
          <div className="mb-2">
            <Switch
              offLabel="No retail"
              onChange={() =>
                setValues({ ...values, retail: values.retail ? false : true })
              }
              onLabel="Enable retail"
              checked={values.retail}
            />
          </div>
          {values.retail && (
            <div className="mb-2">
              <p className="small-text">Enter Retail Price Percentage</p>
              <input
                type="number"
                className="browser-default
                    custom-text-input"
                placeholder="Eg. 40"
                value={values.retailPricePercentage}
                onChange={(e) =>
                  setValues({
                    ...values,
                    retailPricePercentage: e.target.value,
                  })
                }
              />{" "}
              <span>%</span>
            </div>
          )}
          {values.retail && (
            <div className="mb-2">
              <p className="small-text">Round Retail Prices </p>
              <Switch
                offLabel="No"
                onChange={() =>
                  setValues({
                    ...values,
                    roundRetailPrices: values.roundRetailPrices ? false : true,
                  })
                }
                onLabel="Yes"
                checked={values.roundRetailPrices}
              />
            </div>
          )}
        </div>
      </fieldset>

      <button className="btn" type="submit">
        {loading ? (
          <ClipLoader color="#fff" size={20} />
        ) : (
          <span>
            <Icon left>add</Icon> Add Branch
          </span>
        )}
      </button>
    </form>
  );
};

export default AddBranch;

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import branchReducer from "./branchReducer";
import productReducer from "./productReducer";
import analyticsReducer from "./analyticsReducer";
import salesReducer from "./salesReducer";
import suppliersReducer from "./suppliersReducer";
import { inventoryReducer } from "./inventoryReducer";
import customersReducer from "./customersReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  branch: branchReducer,
  product: productReducer,
  analytics: analyticsReducer,
  sale: salesReducer,
  supplier: suppliersReducer,
  inventory: inventoryReducer,
  customers: customersReducer,
});

export default rootReducer;

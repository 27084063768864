const branchReducer = (
  state = {
    loading: false,
    branches: [],
    branch: {},
    warehouses: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_BRANCHES":
      return {
        ...state,
        loading: true,
      };

    case "GOT_BRANCHES":
      return {
        ...state,
        loading: false,
        branches: action.payload,
        warehouses: action.payload.filter((branch) => branch.isWarehouse),
      };

    case "GET_BRANCHES_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GETTING_BRANCH":
      return {
        ...state,
        loading: true,
      };

    case "GOT_BRANCH":
      return {
        ...state,
        loading: false,
        branch: action.payload,
      };

    case "GET_BRANCH_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "CREATING_BRANCH":
      return {
        ...state,
        loading: true,
      };

    case "CREATED_BRANCH":
      return {
        ...state,
        loading: false,
        branches: [...state.branches, action.payload],
      };

    case "CREATE_BRANCH_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPDATING_BRANCH":
      return {
        ...state,
        loading: true,
      };

    case "UPDATED_BRANCH":
      return {
        ...state,
        loading: false,
        branches: state.branches.map((branch) =>
          branch.id === action.payload.id ? action.payload : branch
        ),
      };

    case "UPDATE_BRANCH_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETING_BRANCH":
      return {
        ...state,
        loading: true,
      };

    case "DELETED_BRANCH":
      return {
        ...state,
        loading: false,
        branches: state.branches.filter(
          (branch) => branch.id !== action.payload
        ),
      };

    case "DELETE_BRANCH_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADDING_STOCK":
      return {
        ...state,
        loading: true,
      };

    case "STOCK_ADDED":
      return {
        ...state,
        loading: false,
      };

    case "ADD_STOCK_FAILED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default branchReducer;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { amount, productImg } from "../../helpers/helper";
import { Dropdown } from "react-materialize";
import { getBranch } from "../../redux/actions/branches";
import isEmpty from "lodash/isEmpty";

const InventoryPrintTemplate = () => {
  const branchId = useParams().id;
  const { branch } = useSelector((state) => state.branch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(
      getBranch(branchId, () => {
        window.print();
        navigate(-1);
      })
    );
  }, []);

  return (
    <section className="A4">
      <section className="flex align-center space-between mb-1 mt-1">
        <div className="flex align-center">
          <img
            src="/images/baiclass.png"
            alt="logo"
            style={{ width: 50, height: 50 }}
          />
          <div className="flex justify column ml-2">
            <h6 className="margin-0">Baiclass Enterprise</h6>
            <p className="small-text">
              Behind main station, Ashaiman, Accra, Ghana
            </p>

            <div className="flex">
              <p className="small-text">+233 24 318 0605</p> /
              <p className="small-text">+233 54 333 3071 </p>
            </div>
          </div>
        </div>

        <div>
          {/* <p>{sale.customer?.name}</p>
              <p>kpone, Tema, Ghana</p>
              <p className="bold">{new Date().toDateString()}</p> */}
        </div>
      </section>
      <table className="">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Details</th>
            <th>Size</th>
            <th>Quantity In Stock</th>
            {/* <th>Factory Price</th> */}
            <th>Wholesale Price</th>
            {/* <th>Retail Price</th> */}
          </tr>
        </thead>
        <tbody>
          {branch.products?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className="flex align-center">
                  <div>
                    <p className="small-text capitalize">
                      {item.details?.manufacturer}
                    </p>
                    <p className="bold capitalize uppercase">
                      {item.details?.name}
                    </p>
                    <p style={{ fontSize: 12 }}>{item.details?.nickname}</p>
                  </div>
                </td>

                <td>
                  <div
                    className="flex column gap-1 align-center"
                    style={{ width: "fit-content" }}
                  >
                    <div className="flex gap-1 align-center">
                      <p>{item.details?.size || "N/A"}</p>
                    </div>
                  </div>
                </td>

                <td>
                  <p>{item.stock}</p>
                </td>
                {/* <td>
                  <p>{amount(item.basePrice)}</p>
                </td> */}

                <td>
                  <p>{amount(item.wholesalePrice)}</p>
                </td>

                {/* <td>
                  <p>{amount(item.retailPrice)}</p>
                </td> */}
              </tr> 
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default InventoryPrintTemplate;

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const getCustomers = (branchId) => async (dispatch) => {
  dispatch({ type: "GETTING_CUSTOMERS" });
  try {
    const res = await POS.get("/api/customers/read/" + branchId);
    dispatch({ type: "CUSTOMERS_GOT", payload: res.data });
  } catch (error) {
    dispatch({ type: "CUSTOMERS_GET_FAILED", payload: error });
  }
};

export const addCustomer = (data, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_CUSTOMER" });
  try {
    const res = await POS.post("/api/customers/create", data);
    dispatch({ type: "CUSTOMER_ADDED", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "CUSTOMER_ADD_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const makePayment = (customerId, data, cb) => async (dispatch) => {
  dispatch({ type: "MAKING_PAYMENT" });
  try {
    const res = await POS.put(
      "/api/customers/update/payment/" + customerId,
      data
    );
    dispatch({ type: "PAYMENT_MADE", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "PAYMENT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const deletePayment = (paymentId, cb) => async (dispatch) => {
  dispatch({ type: "DELETING_PAYMENT" });
  try {
    const res = await POS.delete("/api/customers/delete/payment/" + paymentId);
    dispatch({ type: "PAYMENT_DELETED", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "DELETE_PAYMENT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const editPayment = (paymentId, data, cb) => async (dispatch) => {
  dispatch({ type: "EDITING_PAYMENT" });
  try {
    const res = await POS.put(
      "/api/customers/update/edit-payment/" + paymentId,
      data
    );
    dispatch({ type: "PAYMENT_EDITED", payload: res.data });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "EDIT_PAYMENT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const getCustomer = (customerId) => async (dispatch) => {
  dispatch({ type: "GETTING_CUSTOMER" });
  try {
    const res = await POS.get("/api/customers/read/single/" + customerId);
    dispatch({ type: "CUSTOMER_GOT", payload: res.data });
  } catch (error) {
    dispatch({ type: "CUSTOMER_GET_FAILED", payload: error });
  }
};

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { amount } from "../../../helpers/helper";
import { deleteSale } from "../../../redux/actions/sale";

const ConfirmDeleteSale = ({
  setShowConfirmDeleteSaleModal,
  showConfirmDeleteSaleModal,
  sale,
  branchId,
}) => {
  const dispatch = useDispatch();
  const { deleting } = useSelector((state) => state.sale);

  function sumbit() {
    dispatch(
      deleteSale(sale._id, branchId, () => {
        setShowConfirmDeleteSaleModal(false);
      })
    );
  }

  return (
    <Modal
      onClose={() => {
        setShowConfirmDeleteSaleModal(false);
      }}
      open={showConfirmDeleteSaleModal}
      center
    >
      <div className="">
        <h6 className="bold">Delete Sale</h6>
        <p>Are you sure you want to save this sale?</p>
        <p className="mt-1 bold">Amount Paid: {amount(sale.total)}</p>
      </div>
      <div className="mt-2 gap-1 flex ">
        <button
          className="btn darken-3 flex align-center"
          type="button"
          onClick={sumbit}
          disabled={deleting}
        >
          {deleting ? (
            <ClipLoader size={15} color="#fff" loading={deleting} />
          ) : (
            "Delete"
          )}
        </button>

        <button
          className="btn red  flex align-center"
          type="button"
          onClick={(e) => {
            setShowConfirmDeleteSaleModal(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteSale;

import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { calculateTotal } from "../../sale/actions";
import { amount, notify } from "../../../helpers/helper";
import {
  addInvetoryReceipt,
  updateInventoryReceipt,
} from "../../../redux/actions/inventory";

const ConfirmReceivedStockModal = ({
  showConfirmReceivedStockModal,
  setShowConfirmReceivedStockModal,
  tab,
  setTabs,
  tabs,
  branchId,
  tabIndex,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.inventory);
  const { branch } = useSelector((state) => state.branch);
  const [discount, setDiscount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [applied, setApplied] = React.useState(false);

  useEffect(() => {
    setTotal(
      tab.products.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
    );
  }, [tab.products]);

  function saveInventory(e) {
    e.preventDefault();
    const products = tab.products.map((product) => {
      return {
        product: product._id,
        quantity: product.quantity,
        basePrice: product.price,
        wholesalePrice: branch.wholesale ? product.wholesalePrice : undefined,
        retailPrice: branch.retail ? product.retailPrice : undefined,
        expiryDate: product.expiryDate,
      };
    });

    if (!products.length) {
      notify("No products selected");
      setShowConfirmReceivedStockModal(false);
      return;
    }
    if (!tab.supplier?.id) {
      notify("No supplier selected");
      setShowConfirmReceivedStockModal(false);
      return;
    }

    const inventory = {
      _id: tab.stockReceiptId,
      products,
      totalCost: products.reduce(
        (acc, curr) => acc + curr.quantity * curr.basePrice,
        0
      ),
      supplier: tab.supplier?.id,
      invoiceDate: tab.invoiceDate,
    };

    !tab.saved
      ? dispatch(
          addInvetoryReceipt(inventory, branchId, () => {
            setShowConfirmReceivedStockModal(false);
            tabs[tabIndex] = {
              ...tab,
              products: [],
              supplier: {
                name: "No Supplier Selected",
                id: "",
                phoneNumber: "N/A",
              },
              tabType: "inventoryReceipt",
              invoiceDate: "",
              title: `Receipt ${tabs.length + 1}`,
            };
            setTabs([...tabs]);
          })
        )
      : dispatch(
          updateInventoryReceipt(inventory, () => {
            setShowConfirmReceivedStockModal(false);
            tabs[tabIndex] = {
              ...tab,
              products: [],
              supplier: {
                name: "No Supplier Selected",
                id: "",
                phoneNumber: "N/A",
              },
              tabType: "inventoryReceipt",
              invoiceDate: "",
              title: `Receipt ${tabs.length + 1}`,
            };
            setTabs([...tabs]);
          })
        );
  }

  return (
    <Modal
      onClose={() => {
        setShowConfirmReceivedStockModal(false);
      }}
      open={showConfirmReceivedStockModal}
      center
    >
      <form onSubmit={saveInventory} className="mt-2 p-2">
        {!tab.saved ? (
          <div>
            <h5 className="mb-1">Save Inventory Invoice</h5>
            <p>Are you sure you want to save this received stock?</p>
          </div>
        ) : (
          <div>
            <h5 className="mb-1">Update Inventory Invoice</h5>
            <p>Are you sure you want to update this received stock?</p>
          </div>
        )}

        <div className="mb-2 ">
          <p className="small-text  mt-2">
            Enter Percentage Discount Applied On Invoice{" "}
          </p>

          <div className="flex align-center gap-1">
            <input
              type="number"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Amount"
              value={discount}
              step="any"
              min="0"
              max="100"
              onChange={(e) => {
                setDiscount(parseFloat(e.target.value));
              }}
            />

            <button
              className="btn-small ml-1"
              type="button"
              onClick={() => {
                if (discount <= 100 && discount >= 0) {
                  tabs[tabIndex].products = tab.products.map((product) => {
                    product.price =
                      product.price - (discount / 100) * product.price;

                    product.total = product.quantity * product.price;
                    return product;
                  });

                  setTabs([...tabs]);
                  console.log(tabs[tabIndex].products);

                  const total = tab.products.reduce(
                    (acc, curr) => acc + curr.quantity * curr.price,
                    0
                  );
                  const discountedTotal = total - (discount / 100) * total;
                  setTotal(discountedTotal);
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>

        <h5>{amount(total)}</h5>

        <div className="mt-2 gap-1 flex ">
          <button type="submit" className="btn">
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Save Only"}
          </button>

          <button
            className="btn grey lighten-1"
            onClick={() => {
              setShowConfirmReceivedStockModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmReceivedStockModal;

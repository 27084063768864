import React from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const TopCustomers = () => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const { topCustomersLoading } = useSelector((state) => state.analytics);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Top Peforming Customers</h5>
        {topCustomersLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <div className="">
            <div className="flex align-center gap-1 datepicker">
              <ReactDatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="browser-default "
              />
              <span className="material-symbols-outlined">calendar_month</span>
            </div>
          </div>
        )}
      </div>

      <table className="striped">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Total Amount Purchased</th>
            <th>Profit </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Alvin Sem</td>
            <td>12000</td>
            <td>Abuja</td>
          </tr>
          <tr>
            <td>Alan</td>
            <td>12000</td>
            <td>Abuja</td>
          </tr>
          <tr>
            <td>Jonathan</td>
            <td>12000</td>
            <td>Abuja</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TopCustomers;

import React from "react";
import { Dropdown } from "react-materialize";
import { amount } from "../../helpers/helper";
import DailySalesChart from "./components/DailySalesChart";
import MonthlySalesChart from "./components/MonthlySalesChart";
import BestSellingProductsChart from "./components/BestSellingProducts";
import TopCustomers from "./components/TopCustomers";
import LowStockProducts from "./components/LowStockProducts";

const BranchAnalytics = () => {
  const dailySalesData = [
    { date: "2024-01-01", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-02", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-03", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-04", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-05", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-06", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-07", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-08", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-09", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-10", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-11", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-12", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-13", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-14", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-15", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-16", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-17", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-18", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-19", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-20", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-21", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-22", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-23", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-24", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-25", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-26", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-01-27", sales: 1200, profit: Math.random() * 1000 },
    { date: "2024-01-28", sales: 800, profit: Math.random() * 1000 },
    { date: "2024-01-29", sales: 1100, profit: Math.random() * 1000 },
    { date: "2024-01-30", sales: 900, profit: Math.random() * 1000 },
    { date: "2024-01-31", sales: 1000, profit: Math.random() * 1000 },
    { date: "2024-02-01", sales: 1200, profit: Math.random() * 1000 },
    // Add more data for each day
  ];

  const bestSellingProducts = [
    { product: "Product 1", quantity: 100 },
    { product: "Product 2", quantity: 200 },
    { product: "Product 3", quantity: 300 },
    { product: "Product 4", quantity: 400 },
    { product: "Product 5", quantity: 500 },
    { product: "Product 6", quantity: 600 },
    { product: "Product 7", quantity: 700 },
    { product: "Product 8", quantity: 800 },
    { product: "Product 9", quantity: 900 },
    { product: "Product 10", quantity: 1000 },
  ];

  return (
    <div className="p-2">
      <section className="flex space-between">
        <div className="d-card">
          <div>
            <h5 className="bold"> Total Revenue</h5>
            <Dropdown
              trigger={
                <button className="btn flex" style={{ width: "fit-content" }}>
                  <p>Last 3 months</p>
                  <i className="material-symbols-outlined ">expand_more</i>
                </button>
              }
            >
              <a href="#">This Month</a>
              <a href="#">Last Two Months</a>
            </Dropdown>
          </div>

          <div>
            <i
              className="material-symbols-outlined green-text"
              style={{ fontSize: "2rem" }}
            >
              show_chart
            </i>
            <h4 className="bold margin-0"> ₵20.00</h4>
          </div>
        </div>
        <div className="d-card">
          <div>
            <h5 className="bold">Products Sold</h5>
            <Dropdown
              trigger={
                <button className="btn flex " style={{ width: "fit-content" }}>
                  <p>Last 3 months</p>
                  <i className="material-symbols-outlined ">expand_more</i>
                </button>
              }
            >
              <a href="#">This Month</a>
              <a href="#">Last Two Months</a>
            </Dropdown>
          </div>

          <div>
            <i
              className="material-symbols-outlined green-text"
              style={{ fontSize: "2rem" }}
            >
              show_chart
            </i>
            <h4 className="bold margin-0">250</h4>
          </div>
        </div>

        <div className="d-card">
          <div>
            <h5 className="bold">Profit Made</h5>
            <Dropdown
              trigger={
                <button className="btn flex" style={{ width: "fit-content" }}>
                  <p>Last 3 months</p>
                  <i className="material-symbols-outlined ">expand_more</i>
                </button>
              }
            >
              <a href="#">This Month</a>
              <a href="#">Last Two Months</a>
            </Dropdown>
          </div>

          <div>
            <i
              className="material-symbols-outlined green-text"
              style={{ fontSize: "2rem" }}
            >
              show_chart
            </i>
            <h4 className="bold margin-0"> ₵20.00</h4>
          </div>
        </div>
      </section>

      <div className="flex gap-1">
        <DailySalesChart data={dailySalesData} />

        <MonthlySalesChart data={dailySalesData} />
      </div>

      <BestSellingProductsChart data={bestSellingProducts} />

      <TopCustomers />

      <LowStockProducts />
    </div>
  );
};

export default BranchAnalytics;

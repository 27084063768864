import React from "react";
import { BackButton } from "../../components/Buttons";
import { useSelector } from "react-redux";
import { productImg } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import Modal from "react-responsive-modal";
import AddProduct from "../../components/AddProduct";
import EditProduct from "../../components/EditProduct";
import DeleteProductModal from "./components/DeleteProductModal";

const AllProducts = () => {
  const { products, loading } = useSelector((state) => state.product);
  const { branches } = useSelector((state) => state.branch);
  const [productsList, setProductsList] = React.useState([]);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showEditProductModal, setShowEditProductModal] = React.useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] =
    React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState({
    name: "",
    manufacturer: "",
    nickname: "",
    type: "",
    size: "",
    factoryPrice: "",
  });

  React.useEffect(() => {
    setProductsList(products);
  }, [products]);

  return (
    <div className="main">
      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct setShowProductModal={setShowProductModal} />
      </Modal>

      <Modal
        open={showEditProductModal}
        onClose={() => setShowEditProductModal(false)}
      >
        <EditProduct
          setShowEditProductModal={setShowEditProductModal}
          product={selectedProduct}
        />
      </Modal>

      <DeleteProductModal
        product={selectedProduct}
        setShowDeleteProductModal={setShowDeleteProductModal}
        showDeleteProductModal={showDeleteProductModal}
      />
      <header className="flex full-width  space-between sales-header align-center">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Products</h5>
            <p>All products you've added to your platform</p>
          </div>
        </div>

        <div className="flex gap-1 align-center">
          <div className="custom-input-wrapper">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              className="browser-default custom-input"
              placeholder="search for product..."
              onChange={(e) => {
                const value = e.target.value;
                setProductsList(
                  products.filter(
                    (item) =>
                      item.name.toLowerCase().includes(value.toLowerCase()) ||
                      item.nickname.toLowerCase().includes(value.toLowerCase())
                  )
                );
              }}
            />
          </div>
          <button className="btn">search</button>
        </div>
      </header>
      {loading && <ProgressBar />}

      {productsList.length === 0 ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <h5>No Products Found</h5>
          <p>
            You have not added any products to your platform. Click the button
            below to add products
          </p>
          <button
            className="btn mt-1"
            onClick={() => setShowProductModal(true)}
          >
            Add Product
          </button>
        </div>
      ) : (
        <section className="p-2 main-body">
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Details</th>
                <th>Size</th>
                <th>Product Type</th>
                <th>Factory Price</th>
                <th>Branches</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {productsList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="flex align-center">
                      <img
                        src={productImg}
                        alt=""
                        className="mr-2"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10%",
                        }}
                      />
                      <div>
                        <p className="small-text capitalize">
                          {item.manufacturer}
                        </p>
                        <h6 className="capitalize">{item.name}</h6>
                        <p>{item.nickname}</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="flex column gap-1 align-center"
                        style={{ width: "fit-content" }}
                      >
                        <div className="flex gap-1 align-center">
                          <h6>{item.size || "N/A"}</h6>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div
                        className="flex column gap-1 align-center"
                        style={{ width: "fit-content" }}
                      >
                        <div className="flex gap-1 align-center">
                          <h6>{item.type || "N/A"}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h6>{item.basePrice}</h6>
                    </td>

                    <td className="flex align-center">
                      <div className="flex ">
                        {item.branches?.map((branch, i) => (
                          <div className="chip" key={i}>
                            {branches.find((item) => item._id === branch)?.name}
                          </div>
                        ))}
                      </div>
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          <span
                            onClick={() => {
                              setSelectedProduct(item);
                              setShowEditProductModal(true);
                            }}
                          >
                            Edit Product
                          </span>
                          <span
                            onClick={() => {
                              console.log(item);
                              setSelectedProduct(item);
                              setShowDeleteProductModal(true);
                            }}
                          >
                            Delete Product
                          </span>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default AllProducts;

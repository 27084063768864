import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { amount, amountWithoutCurrency } from "../../helpers/helper";
import { calculateTotal } from "./actions";
import PrintConfigModal from "../../components/PrintConfigModal";

const Invoice = () => {
  const { sale } = useSelector((state) => state.sale);
  const [showPrintConfigDialogue, setShowPrintConfigDialogue] =
    React.useState(false);
  const [documentType, setDocumentType] = React.useState("A4");
  const isA4 = documentType === "A4";

  const total = parseFloat(
    calculateTotal({
      products: sale?.products || [],
    })
  );

  useEffect(() => {
    setShowPrintConfigDialogue(true);
  }, [sale]);

  return (
    <div className="flex justify-center">
      <div className={documentType}>
        <PrintConfigModal
          setShowPrintConfigDialogue={setShowPrintConfigDialogue}
          showPrintConfigDialogue={showPrintConfigDialogue}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />

        {isA4 && (
          <section>
            <div className="flex align-center space-between mb-1 mt-1">
              <div className="flex align-center">
                <img
                  src="/images/baiclass.png"
                  alt="logo"
                  style={{ width: 50, height: 50 }}
                />
                <div className="flex justify column ml-2">
                  <h6 className="margin-0">Baiclass Enterprise</h6>
                  <p className="small-text">
                    Behind main station, Ashaiman, Accra, Ghana
                  </p>

                  <div className="flex">
                    <p className="small-text">+233 24 318 0605</p> /
                    <p className="small-text">+233 54 333 3071 </p>
                  </div>
                </div>
              </div>

              <div>
                <h6 className="capitalize bold margin-0">
                  {sale.customer?.name}
                </h6>
                <p>{sale.customer?.phoneNumber}</p>
                <p>{sale.customer?.city}</p>
              </div>
            </div>

            <div className="flex justify-end mt-1">
              <div className="mr-1">
                <p className="small-text grey-text">Invoice No.</p>
                <p>{sale.invoiceNumber}</p>
              </div>

              <div>
                <p className="small-text grey-text">Date</p>
                <p className="">{new Date(sale.createdAt).toDateString()}</p>
              </div>
            </div>
          </section>
        )}

        {!isA4 && (
          <div className="flex justify-center column align-center">
            <h6 className="">Baiclass Enterprise</h6>
            <p className="">Behind main station, Ashaiman, Accra, Ghana</p>

            <div className="flex">
              <p className="">+233 24 318 0605</p> /
              <p className="">+233 54 333 3071 </p>
            </div>
          </div>
        )}

        <table className="striped">
          <thead>
            <tr>
              <th>
                <p className="small-text">#</p>
              </th>

              <th>
                <p className="small-text">Product</p>
              </th>

              <th>
                <p className="small-text">Quantity</p>
              </th>

              <th>
                <p className="small-text">Unit Price</p>
              </th>

              <th>
                <p className="small-text">Subtotal</p>
              </th>
            </tr>
          </thead>

          <tbody>
            {sale?.products?.map((item, index) => {
              return (
                <tr className="uppercase" key={index} style={{ padding: 0 }}>
                  <td>
                    <h6 style={{ fontSize: 10 }}>{index + 1}</h6>
                  </td>

                  <td style={{ width: isA4 ? "50%" : "auto" }}>
                    <div>
                      <p style={{ fontSize: 10 }}>
                        {item.product?.manufacturer}
                      </p>

                      <div className="flex gap-1">
                        <p className="bold">{item.product?.name}</p>

                        <p className="bold">{item.product?.size}</p>
                        <p className="bold">{item.product?.type}</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p>{item.quantity}</p>
                  </td>

                  <td>
                    <p>{amount(item.price)}</p>
                  </td>

                  <td>
                    <p>{amountWithoutCurrency(item.total)}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <aside>
          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Subtotal</p>
              <p className="bold">{total.toFixed(2)}</p>
            </div>
            <div
              className="flex space-between align-center"
              style={{ gap: "50px" }}
            >
              <p className="grey-text">Discount</p>
              <p className="bold">0.00</p>
            </div>
            <div
              className="flex space-between align-center"
              style={{ gap: "50px" }}
            >
              <p className="grey-text">Tax</p>
              <p className="bold">0.00</p>
            </div>

            <div className="flex space-between align-center">
              <p className="bold">Total</p>
              <h6 className="bold">{amount(total)}</h6>
            </div>

            <div
              className="flex space-between align-center "
              style={{ marginTop: "50px" }}
            >
              <div>
                <p>......................................................</p>
                <p className="small-text">Checked By</p>
              </div>
              <div>
                <p>......................................................</p>
                <p className="small-text">Received By</p>
              </div>
            </div>
          </section>
        </aside>
      </div>
    </div>
  );
};

export default Invoice;

const analyticsReducer = (
  state = {
    dailySalesChartLoading: false,
    dailySalesChart: [],
    monthlySalesChartLoading: false,
    monthlySalesChart: [],
    bestSellingProductsLoading: false,
    bestSellingProducts: [],
    topCustomersLoading: false,
    topCustomers: [],
    worstSellingProductsLoading: false,
    worstSellingProducts: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_DAILY_SALES":
      return {
        ...state,
        dailySalesChartLoading: true,
      };
    case "GOT_DAILY_SALES":
      return {
        ...state,
        dailySalesChartLoading: false,
        dailySalesChart: action.payload,
      };
    case "GET_DAILY_SALES_FAILED":
      return {
        ...state,
        dailySalesChartLoading: false,
      };
    case "GETTING_MONTHLY_SALES":
      return {
        ...state,
        monthlySalesChartLoading: true,
      };
    case "GOT_MONTHLY_SALES":
      return {
        ...state,
        monthlySalesChartLoading: false,
        monthlySalesChart: action.payload,
      };
    case "GET_MONTHLY_SALES_FAILED":
      return {
        ...state,
        monthlySalesChartLoading: false,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
const PrintConfigModal = ({
  showPrintConfigDialogue,
  setShowPrintConfigDialogue,
  documentType,
  setDocumentType,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      onClose={() => {
        setShowPrintConfigDialogue(false);
      }}
      open={showPrintConfigDialogue}
      center
    >
      <form className="mt-2 p-2">
        {/* <h5 className="mb-1">Printing Configuration</h5> */}
        <div className="mb-2">
          <p>Choose Sheet Size</p>
          <select
            className="browser-default custom-select"
            required
            onChange={(e) => setDocumentType(e.target.value)}
            value={documentType}
          >
            <option value="" selected>
              -select-
            </option>
            <option value="A4">A4 Sheet</option>
            <option value="terminal">Terminal Printer Sheet</option>
          </select>
        </div>

        <div className="mt-2 gap-1 flex ">
          <button
            type="button"
            className="btn"
            onClick={() => {
              window.print();
              navigate(-1);
            }}
          >
            Print Document
          </button>

          <button
            className="btn grey lighten-1"
            type="button"
            onClick={(e) => {
              setShowPrintConfigDialogue(false);
              navigate(-1);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PrintConfigModal;

import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const login = (data) => async (dispatch) => {
  dispatch({ type: "LOGGING_IN" });
  try {
    const res = await POS.post("/api/accounts/read/login", data);
    dispatch({ type: "LOGGED_IN", payload: res.data });
    localStorage.setItem("__beemPOS", res.data);
    window.location.reload();
  } catch (error) {
    dispatch({ type: "LOGIN_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};


export const getAccount = () => async (dispatch) => {
  dispatch({ type: "GETTING_ACCOUNT" });
  try {
    const res = await POS.get("/api/accounts/read");
    dispatch({ type: "GOT_ACCOUNT", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_ACCOUNT_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};
import React from "react";
import Sidebar from "./Sidebar";
import MenuCardList from "./MenuCardList";

const Menu = () => {
  return (
    <div className="flex main">
      <Sidebar />
      <MenuCardList />
    </div>
  );
};

export default Menu;

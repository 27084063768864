import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { calculateTotal } from "../actions";
import { addNonTodaySale } from "../../../redux/actions/sale";
import { amount } from "../../../helpers/helper";
import SelectSearch from "react-select-search";
import { Autocomplete, Dropdown } from "react-materialize";
import AddCustomerModal from "./AddCustomerModal";

const SelectCustomerModal = ({
  showSelectCustomerModal,
  setShowSelectCustomerModal,
  tab,
  setTabs,
  branchId,
  tabs,
  tabIndex,
}) => {
  const { customers } = useSelector((state) => state.customers);
  const [autocomplete, setAutocomplete] = React.useState({});
  const [showAddCustomerModal, setShowAddCustomerModal] = React.useState(false);

  useEffect(() => {
    if (customers.length > 0) {
      const data = {};
      customers.forEach((customer, i) => {
        data[customer.name + "-" + customer.phoneNumber + "-" + customer.city] =
          customer.name;
      });

      setAutocomplete(data);
    }
  }, [customers]);

 

  function saveSale(e) {
    e.preventDefault();
  }

  return (
    <Modal
      onClose={() => {
        setShowSelectCustomerModal(false);
      }}
      open={showSelectCustomerModal}
      center
      styles={{
        modal: {
          width: "500px",
          height: "50vh",
          padding: "20px",
          borderRadius: "10px",
          overflow: "hidden",
        },
      }}
    >
      <AddCustomerModal
        setShow={setShowAddCustomerModal}
        show={showAddCustomerModal}
        branchId={branchId}
      />
      <form onSubmit={saveSale} className="mt-2 p-2" autoComplete="off">
        <h5 className="mb-1">Select Customer</h5>
        <p>Search for a name, phone number or location </p>

        <div className="flex space-between align-center">
          <Autocomplete
            id="Autocomplete-60"
            options={{
              data: autocomplete,

              onAutocomplete: function noRefCheck(e) {
                const customer = customers.find(
                  (customer) =>
                    customer.name +
                      "-" +
                      customer.phoneNumber +
                      "-" +
                      customer.city ===
                    e
                );

                tab.customer.id = customer?._id;
                tab.customer.name = customer?.name;
                tab.customer.phoneNumber = customer?.phoneNumber;
                tab.title = `${customer?.name}`;

                setTabs([...tabs]);

                setShowSelectCustomerModal(false);
              },
              limit: "Infinity",
              minLength: 1,
            }}
            className="full-width"
            placeholder="Enter customer name"
          />
          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span
              onClick={() => {
                setShowAddCustomerModal(true);
              }}
            >
              Add New Customer
            </span>
          </Dropdown>
        </div>
      </form>
    </Modal>
  );
};

export default SelectCustomerModal;

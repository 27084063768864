import React, { useEffect } from "react";
import ReceiveInventoryHeader from "./components/ReceiveInventoryHeader";
import { useParams } from "react-router-dom";
import { getBranch } from "../../redux/actions/branches";
import { useDispatch, useSelector } from "react-redux";
import RIproductListSection from "./components/RI_Tab";
import isEmpty from "lodash/isEmpty";
import { Tab, Tabs } from "react-materialize";
import { getCustomers } from "../../redux/actions/customers";
import { locallySavedTabs } from "../sale/actions";

const ReceiveInventory = () => {
  const branchId = useParams().id;
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branch);
  const { inventoryReceiptTab } = useSelector((state) => state.inventory);
  const [date, setDate] = React.useState("");
  const [tabs, setTabs] = React.useState([
    {
      id: 1,
      title: "Receipt 1",
      active: true,
      products: [],
      supplier: {
        name: "No Supplier Selected",
        id: "",
        phoneNumber: "N/A",
      },
      tabType: "inventoryReceipt",
      date: "",
      branch: branchId,
    },
  ]);

  useEffect(() => {
    if (branchId) dispatch(getBranch(branchId));
    dispatch(getCustomers(branchId));
  }, [dispatch, branchId]);

  useEffect(() => {
    if (!isEmpty(inventoryReceiptTab)) {
      setTabs([inventoryReceiptTab]);
      dispatch({ type: "RESET_INVENTORY_RECEIPT_TAB" });
    }
  }, [dispatch, inventoryReceiptTab]);


  useEffect(() => {
    locallySavedTabs(branchId, setTabs, "inventoryTab");
  }, [branchId]);

  return (
    <div>
      <ReceiveInventoryHeader
        branch={branch}
        tabs={tabs}
        setTabs={setTabs}
        date={date}
        setDate={setDate}
      />

      <section className="sales-section">
        <div style={{ height: "100%" }}>
          <Tabs className="">
            {tabs.map((tab, i) => (
              <Tab title={tab.title} active={tab.active} key={i} idx={`${i}`}>
                <RIproductListSection
                  tab={tab}
                  setTabs={setTabs}
                  tabs={tabs}
                  tabIndex={i}
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </section>
    </div>
  );
};

export default ReceiveInventory;

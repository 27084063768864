import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const DailyTotalSalesPrint = () => {
  const [documentType, setDocumentType] = React.useState("A4");
  const isA4 = documentType === "A4";
  const { branch } = useSelector((state) => state.branch);
  const { dailyTotalSales } = useSelector((state) => state.sale);
  const navigate = useNavigate();

  const useQuery = (name) => {
    return new URLSearchParams(window.location.search).get(name);
  };

  const startDate = useQuery("startDate");
  const endDate = useQuery("endDate");

  useEffect(() => {
    setTimeout(() => {
      window.print();
      navigate(-1);
    }, 1000);
  }, [navigate]);

  return (
    <div className="flex justify-center">
      <div className={documentType}>
        {isA4 && (
          <section className="flex align-center space-between mb-1 mt-1">
            <div className="flex align-center">
              <img
                src="/images/baiclass.png"
                alt="logo"
                style={{ width: 50, height: 50 }}
              />
              <div className="flex justify column ml-2">
                <h6 className="margin-0">Baiclass Enterprise</h6>
                <p className="small-text">
                  Behind main station, Ashaiman, Accra, Ghana
                </p>

                <div className="flex">
                  <p className="small-text">+233 24 318 0605</p> /
                  <p className="small-text">+233 54 333 3071 </p>
                </div>
              </div>
            </div>
          </section>
        )}

        <div className="">
          <div className="flex column justify-center align-center">
            <h6 className="bold">Daily Total Sales</h6>
            <p>{branch.name}</p>
            <p>
              {format(new Date(startDate), "dd/MM/yyyy")} -{" "}
              {format(new Date(endDate), "dd/MM/yyyy")}
            </p>
          </div>
          <table className="striped">
            <thead>
              <tr>
                <th>
                  <p className="small-text">Date</p>
                </th>

                <th>
                  <p className="small-text">Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {dailyTotalSales.eachDay?.map((item, index) => {
                return (
                  <tr className="uppercase" key={index} style={{ padding: 0 }}>
                    <td>{new Date(item.date).toLocaleDateString()}</td>

                    <td>{item.total}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="flex justify-end p-2">
            <div className="flex align-end gap-1">
              <p>Total:</p>
              <p className="margin-0">{dailyTotalSales.totalSales}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyTotalSalesPrint;

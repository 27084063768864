import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";

const SelectBranch = ({
  setShowSelectBranchModal,
  showSelectBranchModal,
  onClick,
  type,
}) => {
  const { branches } = useSelector((state) => state.branch);

  return (
    <Modal
      onClose={() => setShowSelectBranchModal(false)}
      open={showSelectBranchModal}
      center
    >
      <form className="p-2 mt-1">
        <h5>Select A Branch</h5>
        {branches.map((branch, index) => (
          <div
            className="box flex align-center space-between mb-1 hoverable gap-1"
            key={index}
            onClick={() => {
              if (onClick) {
                onClick(branch?._id, type);
                setShowSelectBranchModal(false);
              }
            }}
          >
            <div className="flex align-center">
              <i
                className="material-symbols-outlined mr-1 grey-text"
                style={{ fontSize: "4rem" }}
              >
                store
              </i>
              <div>
                <h5>{branch.name}</h5>
                <p>0{branch.phoneNumber}</p>
              </div>
            </div>

            <div>
              <p className="small-text grey-tex"> Total Products</p>
              <h5> {branch.products?.length} Products</h5>
            </div>
          </div>
        ))}
      </form>
    </Modal>
  );
};

export default SelectBranch;

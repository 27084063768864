import { POS } from "../../config/url";
import { notify } from "../../helpers/helper";

export const addSale = (sale, branchId, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_SALE" });
  try {
    const res = await POS.post(`/api/sales/create/${branchId}`, sale);
    dispatch({ type: "ADDED_SALE", payload: res.data });
    notify("Sale added successfully", "success");
    if (cb) cb(res.data);
  } catch (error) {
    dispatch({ type: "ADD_SALE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const deleteSale = (id, branchId, cb) => async (dispatch) => {
  dispatch({ type: "DELETING_SALE" });
  try {
    const res = await POS.delete(
      `/api/sales/delete/${id}?branchId=${branchId}`
    );
    dispatch({ type: "DELETED_SALE", payload: res.data });
    notify("Sale deleted successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "DELETE_SALE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const updateSale = (sale, id, branchId, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_SALE" });
  try {
    const res = await POS.put(
      `/api/sales/update/${id}?branchId=${branchId}`,
      sale
    );
    dispatch({ type: "UPDATED_SALE", payload: res.data });
    notify("Sale updated successfully", "success");
    if (cb) cb(res.data);
  } catch (error) {
    dispatch({ type: "UPDATE_SALE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const addNonTodaySale = (sale, branchId, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_NON_TODAY_SALE" });
  try {
    const res = await POS.post(
      `/api/sales/create/non-today-sale/${branchId}`,
      sale
    );
    dispatch({ type: "ADDED_NON_TODAY_SALE", payload: res.data });
    notify("Sale added successfully", "success");

    if (cb) cb();
  } catch (error) {
    dispatch({ type: "ADD_NON_TODAY_SALE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const getSales = (id, startDate, endDate) => async (dispatch) => {
  dispatch({ type: "GETTING_SALES" });
  try {
    let link =
      startDate && endDate
        ? `/api/sales/read/${id}?startDate=${startDate}&endDate=${endDate}`
        : `/api/sales/read/${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_SALES", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SALES_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const endOfDay = (id, cashInHand, cb) => async (dispatch) => {
  dispatch({ type: "ENDING_DAY" });
  try {
    const res = await POS.put(`/api/sales-shift/update/end-of-day/${id}`, {
      total: cashInHand,
    });
    dispatch({ type: "ENDED_DAY", payload: res.data });
    notify("Day ended successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "END_DAY_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const registerExpense = (expense, branchId, cb) => async (dispatch) => {
  dispatch({ type: "ADDING_EXPENSE" });
  try {
    const res = await POS.post(
      `/api/sales-shift/create/expense/${branchId}`,
      expense
    );
    dispatch({ type: "ADDED_EXPENSE", payload: res.data });
    notify("Expense added successfully", "success");
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "ADD_EXPENSE_FAILED", payload: error });
    notify(error.response?.data || error.message, "error");
  }
};

export const getSalesReport = (id, date) => async (dispatch) => {
  dispatch({ type: "GETTING_SALES_REPORT" });
  try {
    const link = `/api/sales-shift/read/sales-report/${date}?branch=${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_SALES_REPORT", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SALES_REPORT_FAILED", payload: error });
  }
};

export const getDailyTotalSales = (id, start, end) => async (dispatch) => {
  dispatch({ type: "GETTING_DAILY_TOTAL_SALES" });
  try {
    const link = `/api/sales-shift/read/daily-total-sales/${id}?startDate=${start}&endDate=${end}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_DAILY_TOTAL_SALES", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_DAILY_TOTAL_SALES_FAILED", payload: error });
  }
};

export const getSalesShift = (id, date) => async (dispatch) => {
  dispatch({ type: "GETTING_SALES_SHIFT" });
  try {
    const link = `/api/sales-shift/read/date/${date}?branch=${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_SALES_SHIFT", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SALES_SHIFT_FAILED", payload: error });
  }
};

export const getSalesTimelineChart = (id, date) => async (dispatch) => {
  dispatch({ type: "GETTING_SALES_TIMELINE_CHART" });
  try {
    const link = `/api/sales-shift/read/sales-timeline-chart/${date}?branch=${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_SALES_TIMELINE_CHART", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_SALES_TIMELINE_CHART_FAILED", payload: error });
  }
};

export const getBestSellingProducts = (id, date) => async (dispatch) => {
  dispatch({ type: "GETTING_BEST_SELLING_PRODUCTS" });
  try {
    const link = `/api/sales-shift/read/best-selling-products/${date}?branch=${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_BEST_SELLING_PRODUCTS", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_BEST_SELLING_PRODUCTS_FAILED", payload: error });
  }
};

export const getProductsLowInStock = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_PRODUCTS_LOW_IN_STOCK" });
  try {
    const link = `/api/sales-shift/read/products-low-in-stock/${id}`;
    const res = await POS.get(link);

    dispatch({ type: "GOT_PRODUCTS_LOW_IN_STOCK", payload: res.data });
  } catch (error) {
    dispatch({ type: "GET_PRODUCTS_LOW_IN_STOCK_FAILED", payload: error });
  }
};

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const MonthlySalesChart = ({ data }) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const { dailySalesChartLoading } = useSelector((state) => state.analytics);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <section className="box white mt-2">
      <div className="flex align-center space-between">
        <h5 className="bold mb-1">Monthly Sales Chart</h5>
        {dailySalesChartLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <div className="">
            <div className="flex align-center gap-1 datepicker">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="browser-default "

              />
              <span className="material-symbols-outlined">calendar_month</span>
            </div>
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sales" stroke="teal" strokeWidth="1" />
          <Line type="monotone" dataKey="profit" stroke="deeppink" strokeWidth="1" />
        </LineChart>
      </ResponsiveContainer>
    </section>
  );
};

export default MonthlySalesChart;

import React from "react";
import Modal from "react-responsive-modal";
import { calculateTotal } from "./actions";
import { amount, notify } from "../../helpers/helper";
import { addSale, updateSale } from "../../redux/actions/sale";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ReceivedPaymentModal = ({
  showReceivedPaymentModal,
  setShowReceivedPaymentModal,
  tab,
  setTabs,
  tabs,
  branchId,
}) => {
  const [paid, setPaid] = React.useState(0);

  const totalAmount = calculateTotal(tab);
  const change = isNaN(paid - totalAmount) ? 0 : paid - totalAmount;
  const [paymentMethod, setPaymentMethod] = React.useState("cash");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sale);
  const [mobileMoneyName, setMobileMoneyName] = React.useState("");
  const navigate = useNavigate();

  function saveSale(e, print = false) {
    e.preventDefault();

    if (tab.salesType === "credit" && !tab.customer.id) {
      notify("Please select a customer for credit sales", "error");
      setShowReceivedPaymentModal(false);
      return;
    }

    const products = tab.products.map((product) => {
      return {
        product: product._id,
        quantity: product.quantity,
        price: product.price,
        total: product.total,
      };
    });

    const customer = tab.customer.id;
    const sale = {
      customer,
      products,
      total: parseFloat(totalAmount),
      paid,
      due: change,
      paymentMethod,
      note: "",
      discount: 0,
      salesType: tab.salesType,
      priceMode: tab.priceMode,
    };

    if (!sale.customer) delete sale.customer;

    if (!tab.saved)
      dispatch(
        addSale(sale, branchId, (savedSale) => {
          setShowReceivedPaymentModal(false);
          const tabIndex = tab.id - 1;
          const newTabs = [...tabs];

          setPaid(0);
          setMobileMoneyName("");

          if (print) {
            dispatch({ type: "GOT_SALE", payload: savedSale });
            navigate(`/invoice/${savedSale._id}`);
            print = false;
          }

          newTabs[tabIndex].products = [];
          newTabs[tabIndex].salesType = "cash";
          newTabs[tabIndex].customer = {
            name: "No customer selected",
            id: "",
            phoneNumber: "N/A",
          };
          newTabs[tabIndex].title = `Sales Tab ${tabIndex + 1}`;

          setTabs([...newTabs]);

          console.log("newTabs", newTabs);

          localStorage.setItem("salesTab", JSON.stringify(newTabs));
        })
      );
    else
      dispatch(
        updateSale(sale, tab.saleId, branchId, (savedSale) => {
          setShowReceivedPaymentModal(false);
          const tabIndex = tab.id - 1;
          const newTabs = [...tabs];

          setPaid(0);
          setMobileMoneyName("");

          if (print) {
            dispatch({ type: "GOT_SALE", payload: savedSale });
            navigate(`/invoice/${savedSale._id}`);
            print = false;
          }

          newTabs[tabIndex].products = [];
          newTabs[tabIndex].salesType = "cash";
          newTabs[tabIndex].saved = false;
          newTabs[tabIndex].title = `Sales Tab ${tabIndex + 1}`;
          newTabs.customer = {
            name: "No customer selected",
            id: "",
            phoneNumber: "N/A",
          };
          setTabs(newTabs);

          localStorage.setItem("salesTab", JSON.stringify(newTabs));
        })
      );
  }

  return (
    <Modal
      onClose={() => {
        setShowReceivedPaymentModal(false);
      }}
      open={showReceivedPaymentModal}
      center
    >
      <form onSubmit={saveSale} className="mt-2 p-2">
        {tab.saved ? (
          <h5 className="mb-1">Update Sale</h5>
        ) : (
          <h5 className="mb-1">Save Sale</h5>
        )}
        <p></p>

        <div className="mb-2 ">
          <p className="small-text  mb-1">Enter Amount Paid </p>
          <input
            type="number"
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Amount"
            value={paid}
            step="any"
            min="0"
            onChange={(e) => {
              setPaid(parseFloat(e.target.value));
            }}
          />
        </div>

        <div className="mb-2">
          <p>Select Payment Method</p>
          <select
            className="browser-default custom-select"
            required
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="cash">Cash</option>
            <option value="momo">Mobile Money</option>
            <option value="">select payment method</option>
          </select>
        </div>

        {paymentMethod === "momo" && (
          <div className="mb-2 ">
            <p className="">Enter Mobile Money Wallet Name </p>
            <input
              type="text"
              className="browser-default
            custom-text-input  lighten-4"
              placeholder="Enter Wallet Name"
              value={mobileMoneyName}
              step="any"
              min="0"
              required={paymentMethod === "momo"}
              onChange={(e) => {
                setMobileMoneyName(e.target.value);
              }}
            />
          </div>
        )}

        <section className="mt-2">
          <div className="flex space-between align-center">
            <p className="small-text grey-text">Amount Payable</p>
            <h6 className="">{amount(totalAmount)}</h6>
          </div>
          <div className="flex space-between align-center">
            <p className="small-text grey-text">Amount Received</p>
            <h6 className="">{amount(paid)}</h6>
          </div>
          <div className="flex space-between align-center">
            <p className="small-text grey-text">Change To Be Returned</p>
            <h6
              className={
                isNaN(change) || change < 0 ? "red-text" : "green-text"
              }
            >
              {amount(change)}
            </h6>
          </div>
        </section>
        {loading ? (
          <div className="flex justify-center align-center">
            <ClipLoader size={30} color="#ccc" loading={loading} />
          </div>
        ) : (
          <div className="mt-2 gap-1 flex ">
            <button
              className="btn green darken-3 flex align-center"
              disabled={
                (paid < totalAmount && tab.salesType !== "credit") ||
                parseFloat(totalAmount) === 0 ||
                (!paid && tab.salesType !== "credit") ||
                loading
              }
              type="button"
              onClick={saveSale}
            >
              Save Only
            </button>

            <button
              className="btn blue darken-3 flex align-center"
              disabled={
                (paid < totalAmount && tab.salesType !== "credit") ||
                parseFloat(totalAmount) === 0 ||
                (!paid && tab.salesType !== "credit") ||
                loading
              }
              type="button"
              onClick={(e) => {
                saveSale(e, true);
              }}
            >
              Save & Print
            </button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default ReceivedPaymentModal;

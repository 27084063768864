import React from "react";
import { amount } from "../../helpers/helper";
import { Dropdown } from "react-materialize";
import { useDispatch } from "react-redux";
import SaleModal from "./SaleModal";
import { format } from "date-fns";

const SalesReportHistory = ({ data }) => {
  const [showSaleModal, setShowSaleModal] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <div className="sr-history">
      <SaleModal
        showSaleModal={showSaleModal}
        setShowSaleModal={setShowSaleModal}
      />
      <h5>Today's Sales</h5>
      <table className="">
        <thead>
          <tr>
            <th>Time</th>
            <th>Total Amount</th>
            <th>Payment Method</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <p>{format(new Date(item.createdAt), "hh:mm a")}</p>
                </td>

                <td>
                  <h6>{amount(item.total)}</h6>
                </td>

                <td>
                  <p>{item.paymentMethod}</p>
                </td>

                <td className="">
                  <div className="flex align-center">
                    <button
                      className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                      onClick={() => {
                        setShowSaleModal(true);
                        dispatch({ type: "GOT_SALE", payload: item });
                      }}
                    >
                      View
                    </button>
                    <Dropdown
                      trigger={
                        <i className="material-symbols-outlined ">more_vert</i>
                      }
                    >
                      <a href="#!">Reverse Sale </a>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SalesReportHistory;

import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { amount } from "../../helpers/helper";
import { Dropdown } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { isDate } from "date-fns";

const ProductSaleHistoryModal = ({
  showProductSaleHistoryModal,
  setShowProductSaleHistoryModal,
  startDate,
  endDate,
  product,
  setSale,
  setShowDeleteSaleModal,
  setShowSaleModal,
  setSearchProduct,
}) => {
  const { loading } = useSelector((state) => state.product);
  const { branch } = useSelector((state) => state.branch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function saveInventory(e) {}

  console.log();
  return (
    <Modal
      onClose={() => {
        setShowProductSaleHistoryModal(false);
        setSearchProduct("");
      }}
      open={showProductSaleHistoryModal}
      center
    >
      <div className="mt-2 ">
        <h6 className="mt-2 bold">Product Analytics</h6>

        <div>
          <div className="flex">
            <p>Date Range:</p>

            <div className="ml-2 flex gap-1">
              <p className=" bold">
                {isDate(startDate) ? startDate.toDateString() : "N/A"}
              </p>
              <p>to</p>
              <p className="bold">
                {isDate(endDate) ? endDate.toDateString() : "N/A"}
              </p>
            </div>
          </div>

          <div className="flex">
            <p>Product Details:</p>

            <div className="ml-2">
              <p className="bold">
                {product.manufacturer}-{product.name}
              </p>
            </div>
          </div>

          <div className="flex  ">
            <p>Quantity sold:</p>

            <p className="bold ml-2">
              {product.quantitySold} {product.type}
            </p>
          </div>

          <section className="p-2">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Sold By</th>
                  <th>Date & Time</th>
                  <th>Customer</th>
                  <th>Total Amount</th>
                  <th>Invoice Type </th>
                  <th>Invoice Number </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {product.sales?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <p>{item.seller?.name}</p>
                      </td>
                      <td>
                        <p>
                          {new Date(item.createdAt).toDateString()},{" "}
                          {new Date(item.createdAt).toLocaleTimeString()}{" "}
                        </p>
                      </td>
                      <td>
                        <p>{item.customer?.name}</p>
                      </td>

                      <td>
                        <h6>{amount(item.total)}</h6>
                      </td>

                      <td>
                        <p>{item.salesType}</p>
                      </td>
                      <td>
                        <p>{item.invoiceNumber}</p>
                      </td>

                      <td className="">
                        <div className="flex align-center">
                          <button
                            className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                            onClick={() => {
                              setShowSaleModal(true);
                              dispatch({ type: "GOT_SALE", payload: item });
                            }}
                          >
                            View
                          </button>
                          <Dropdown
                            trigger={
                              <i className="material-symbols-outlined ">
                                more_vert
                              </i>
                            }
                          >
                            <a
                              href="#!"
                              onClick={() => {
                                const products = item.products.map(
                                  (product) => {
                                    const data = {
                                      manufacturer:
                                        product.product.manufacturer,
                                      name: product.product.name,
                                      price: product.price,
                                      quantity: product.quantity,
                                      total: product.total,
                                      _id: product.product._id,
                                    };

                                    return data;
                                  }
                                );

                                const tab = {
                                  id: 1,
                                  title: "Sales Tab 1",
                                  active: true,
                                  products,
                                  branch: branch._id,
                                  customer: {
                                    name:
                                      item.customer?.name ||
                                      "No customer selected",
                                    id: item.customer?._id || "",
                                    phoneNumber:
                                      item.customer?.phoneNumber || "",
                                  },
                                  priceMode:
                                    item.priceMode || branch.wholesale
                                      ? "wholesale"
                                      : "retail",
                                  salesType: item.salesType || "cash",
                                  saleId: item._id,
                                  saved: true,
                                };

                                dispatch({
                                  type: "GOT_SALES_RECEIPT_TAB",
                                  payload: tab,
                                });

                                navigate("/sale/" + branch._id);
                              }}
                            >
                              Edit Sale{" "}
                            </a>
                            <a
                              href="#!"
                              onClick={() => {
                                setSale(item);
                                setShowDeleteSaleModal(true);
                              }}
                            >
                              Delete Sale{" "}
                            </a>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default ProductSaleHistoryModal;

import React, { useEffect } from "react";
import { Autocomplete, Icon } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { addProduct, addSystemProduct } from "../../../redux/actions/products";
import { getBranch } from "../../../redux/actions/branches";

const AddSystemProduct = ({ setShowAddSystemProductModal, branchId }) => {
  const { branches } = useSelector((state) => state.branch);
  const { loading } = useSelector((state) => state.product);
  const { branch } = useSelector((state) => state.branch);
  const { products } = useSelector((state) => state.product);
  const [keyword, setKeyword] = React.useState("");

  const [values, setValues] = React.useState({
    name: "",
    manufacturer: "",
    nickname: "",
    type: "",
    size: "",
    factoryPrice: "",
    branches: [],
  });

  const [chipData, setChipData] = React.useState([]);
  const [autocompleteData, setAutocompleteData] = React.useState({});
  const dispatch = useDispatch();
  const handleAutocomplete = (value) => {
    if (chipData.includes(value)) return;
    setChipData([...chipData, value]);
    setKeyword("");
  };

  useEffect(() => {
    let data = {};
    const branchProductIds = branch.products.map(
      (product) => product.details?._id
    );

    products
      .filter((product) => !branchProductIds.includes(product._id))
      .forEach((product) => {
        data[`${product.name}-${product.size} (${product.manufacturer})`] = product._id;
      });
    setAutocompleteData(data);
  }, [branch.products, products]);

  const send = (e) => {
    e.preventDefault();

    const selectedProducts = chipData
      .map((chip) => {
        const product = products.find((product) => {
          return `${product.name}-${product.size} (${product.manufacturer})` === chip;
        });
        return product;
      })
      .map((product) => product._id);

    dispatch(
      addSystemProduct(branchId, { products: selectedProducts }, () => {
        setChipData([]);
        setShowAddSystemProductModal(false);
        dispatch(getBranch(branchId));
      })
    );
  };

  return (
    <form onSubmit={send} className="mt-2 p-2 ">
      <h4>Add System Product</h4>

      <div className="mb-2">
        <p>
          Add a product to this branch if it has been added in other branches
        </p>
        <Autocomplete
          options={{
            data: autocompleteData,
            onAutocomplete: (value) => handleAutocomplete(value),
          }}
          placeholder="Search for product"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        {chipData.map((chip, index) => (
          <div className="chip">
            {chip}
            <i
              className="close material-icons"
              onClick={() =>
                setChipData(chipData.filter((chip) => chip !== chipData[index]))
              }
            >
              close
            </i>
          </div>
        ))}
      </div>

      <button className="btn" type="submit">
        {loading ? (
          <ClipLoader color="#fff" size={20} />
        ) : (
          <span>
            <Icon left>add</Icon> Add Product
          </span>
        )}
      </button>
    </form>
  );
};

export default AddSystemProduct;

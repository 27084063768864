import React from "react";
import Modal from "react-responsive-modal";
import { updateStockQuantity } from "../../../redux/actions/products";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../../redux/actions/branches";

const ChangeStockQuantity = ({
  product,
  setShowStockModal,
  showStockModal,
  branchId,
}) => {
  const [quantity, setQuantity] = React.useState(0);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.product);

  React.useEffect(() => {
    setQuantity(product.quantity);
  }, [product.quantity]);

  function updateProduct() {
    dispatch(
      updateStockQuantity(
        branchId,
        {
          stock: quantity,
          product: product.details?._id,
        },
        () => {
          setShowStockModal(false);
          dispatch(getBranch(branchId));
        }
      )
    );
  }

  return (
    <Modal
      onClose={() => {
        setShowStockModal(false);
      }}
      open={showStockModal}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      center
      closeOnEsc={false}
    >
      <form
        className="p-2"
        onSubmit={(e) => {
          e.preventDefault();
          updateProduct();
        }}
      >
        <div className="modal-header mb-2">
          <h5 className="modal-title">Change Stock Quantity</h5>
        </div>

        <div className="flex mb-2 gap-1 align-end">
          <div>
            <p className="small-text  mb-1">Change Quantity</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <button type="submit" className="btn" disabled={loading}>
            Save changes
          </button>
          <button
            type="button"
            className="btn white teal-text"
            onClick={() => {
              setShowStockModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeStockQuantity;

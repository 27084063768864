import React, { useEffect } from "react";
import { BackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { amount } from "../../helpers/helper";
import PurchaseHistory from "./PurchaseHistory";
import { Dropdown, ProgressBar } from "react-materialize";
import MakePaymentModal from "./MakePaymentModal";
import { getCustomer } from "../../redux/actions/customers";
import { useParams } from "react-router-dom";
import PaymentHistory from "./PaymentHistory";

const CustomerProfile = () => {
  const { branch } = useSelector((state) => state.branch);
  const { customer, loading } = useSelector((state) => state.customers);
  const [showMakePaymentModal, setShowMakePaymentModal] = React.useState(false);
  const customerId = useParams().id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomer(customerId));
  }, [dispatch, customerId]);

  return (
    <div>
      <MakePaymentModal
        showMakePaymentModal={showMakePaymentModal}
        setShowMakePaymentModal={setShowMakePaymentModal}
        customerId={customer._id}
        customer={customer}
      />

      <header className="flex full-width  space-between  sales-header align-center ">
        <div className="flex align-center">
          <BackButton />
          <div>
            <h5 className="margin-0">Customer Profile</h5>
            <h6 className="bold margin-0">{branch?.name}</h6>
          </div>
        </div>

        <Dropdown
          trigger={<a className="btn-small white teal-text">I want to...</a>}
        >
          <span
            onClick={() => {
              setShowMakePaymentModal(true);
            }}
          >
            Make Payment
          </span>
          <span>Charge to This Account</span>
          <span>Print</span>
        </Dropdown>
      </header>
      {loading && <ProgressBar />}
      <div className="p-1">
        <h5 className="capitalize">{customer?.name}</h5>
        <p className="capitalize">
          {customer?.phoneNumber},{customer?.city}
        </p>
      </div>

      <div>
        <section className="flex space-between mt-1  gap-1">
          <div className="d-card">
            <p className="">GROSS PURCHASE VALUE</p>

            <div>
              <h4 className="bold margin-0">
                {" "}
                {amount(customer?.totalPurchase)}
              </h4>
            </div>
          </div>

          <div className="d-card">
            <p>CREDIT LIMIT</p>

            <div>
              <h4 className="bold margin-0">{amount(customer.creditLimit)}</h4>
            </div>
          </div>

          <div className="d-card">
            <p>BALANCE DUE</p>

            <div>
              <h4 className="bold margin-0">
                {customer?.due ? amount(customer.due) : 0}
              </h4>
            </div>
          </div>

          <div className="d-card">
            <p>CREDIBILITY SCORE</p>

            <div>
              <h4 className="bold margin-0">
                {" "}
                {amount(customer?.credibilityScore)}
              </h4>
            </div>
          </div>
        </section>

        <PurchaseHistory />

        <PaymentHistory />
      </div>
    </div>
  );
};

export default CustomerProfile;

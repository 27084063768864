import React from "react";
import Modal from "react-responsive-modal";
import { notify } from "../../../helpers/helper";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const RIChangeParamsModal = ({
  showQuantityPriceModal,
  setShowQuantityPriceModal,
  tab,
  tabs,
  setTabs,
  productIndex,
  selectedInput,
}) => {
  const [price, setPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [wholesalePrice, setWholeSalePrice] = React.useState(0);
  const [retailPrice, setRetailPrice] = React.useState(0);
  const { branch } = useSelector((state) => state.branch);
  const { loading } = useSelector((state) => state.inventory);
  const priceRef = React.useRef();
  const quantityRef = React.useRef();
  const totalRef = React.useRef();
  const wholesalePriceRef = React.useRef();
  const retailPriceRef = React.useRef();

  React.useEffect(() => {
    if (productIndex === -1) return;
    const product = tab.products[productIndex];
    if (!product) return;
    setPrice(product.price);
    setQuantity(product.quantity);
    setTotal(product.total);
    setWholeSalePrice(product.wholesalePrice);
    setRetailPrice(product.retailPrice);
  }, [productIndex, tab.products]);

  React.useEffect(() => {
    setTimeout(() => {
      if (selectedInput === "price") {
        console.log("priceRef", priceRef);
        priceRef.current?.focus();
      } else if (selectedInput === "quantity") {
        quantityRef.current.focus();
      } else if (selectedInput === "total") {
        totalRef.current?.focus();
      } else if (selectedInput === "wholesalePrice") {
        wholesalePriceRef?.current.focus();
      } else if (selectedInput === "retailPrice") {
        retailPriceRef.current?.focus();
      }
    }, 100);
  }, [selectedInput]);

  if (productIndex === -1) return null;

  function updateProduct() {
    if (!quantity || !price || !total)
      return notify("Please enter Quantity and Price", "e rror");

    const newTabs = [...tabs];
    newTabs[tab.id - 1].products[productIndex].price = parseFloat(price);
    newTabs[tab.id - 1].products[productIndex].quantity = parseFloat(quantity);
    newTabs[tab.id - 1].products[productIndex].total = parseFloat(total);
    setTabs(newTabs);
    newTabs[tab.id - 1].products[productIndex].wholesalePrice =
      parseFloat(wholesalePrice);
    newTabs[tab.id - 1].products[productIndex].retailPrice =
      parseFloat(retailPrice);
  }

  const wholesalePricePercentage = branch?.wholesalePricePercentage;
  const retailPricePercentage = branch?.retailPricePercentage;

  return (
    <Modal
      onClose={() => {
        setShowQuantityPriceModal(false);
      }}
      open={showQuantityPriceModal}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      center
      closeOnEsc={false}
    >
      <form
        className="p-2"
        onSubmit={(e) => {
          e.preventDefault();
          updateProduct();
          setShowQuantityPriceModal(false);
        }}
      >
        <div className="modal-header mb-2">
          <h5 className="modal-title"> Change Quantiy & Prices</h5>
        </div>

        <div className="flex mb-2 gap-1 align-end">
          <div>
            <p className="small-text  mb-1">Change Quantity</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={quantity}
                min="0"
                ref={quantityRef}
                onChange={(e) => {
                  setQuantity(e.target.value);
                  setTotal((e.target.value * price).toFixed(2));
                }}
              />
            </div>
          </div>

          <p>
            <span className="material-symbols-outlined">close</span>
          </p>

          <div>
            <p className="small-text  mb-1">Unit Price</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={price}
                step="any"
                min="0"
                ref={priceRef}
                onChange={(e) => {
                  setPrice(e.target.value);
                  setTotal((e.target.value * quantity).toFixed(2));

                  let wholesalePrice =
                    e.target.value * wholesalePricePercentage +
                    parseFloat(e.target.value);

                  if (branch.roundWholesalePrices) {
                    wholesalePrice = Math.round(wholesalePrice);
                  }

                  setWholeSalePrice(
                    wholesalePrice.toFixed(2) > 0
                      ? wholesalePrice.toFixed(2)
                      : 0
                  );

                  let retailPrice =
                    e.target.value * retailPricePercentage +
                    parseFloat(e.target.value);

                  if (branch.roundRetailPrices) {
                    retailPrice = Math.round(retailPrice);
                  }

                  setRetailPrice(
                    retailPrice.toFixed(2) > 0 ? retailPrice.toFixed(2) : 0
                  );
                }}
              />
            </div>
          </div>

          <p style={{ fontSize: 22, fontWeight: 800 }}>=</p>

          <div>
            <p className="small-text  mb-1">Total</p>
            <div className="custom-input-wrapper grey lighten-4 flex ">
              <input
                type="number"
                className="browser-default
            custom-input grey lighten-4"
                placeholder="Enter Quantity"
                value={total}
                step="any"
                min="0"
                ref={totalRef}
                onChange={(e) => {
                  setTotal(e.target.value);
                  setPrice((e.target.value / quantity).toFixed(2));
                }}
              />
            </div>
          </div>
          {branch.wholesale && (
            <div>
              <p className="small-text  mb-1">
                Wholesale Price ({wholesalePricePercentage * 100}%)
              </p>
              <div className="custom-input-wrapper grey lighten-4 flex ">
                <input
                  type="number"
                  className="browser-default
            custom-input grey lighten-4"
                  placeholder="Enter Quantity"
                  value={wholesalePrice}
                  step="any"
                  ref={wholesalePriceRef}
                  min="0"
                  onChange={(e) => {
                    setWholeSalePrice(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          {branch.retail && (
            <div>
              <p className="small-text  mb-1">
                Retail Price ({retailPricePercentage * 100}% )
              </p>
              <div className="custom-input-wrapper grey lighten-4 flex ">
                <input
                  type="number"
                  className="browser-default
            custom-input grey lighten-4"
                  placeholder="Enter Quantity"
                  value={retailPrice}
                  step="any"
                  ref={retailPriceRef}
                  min="0"
                  onChange={(e) => {
                    setRetailPrice(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex gap-1">
          <button type="submit" className="btn" disabled={loading}>
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Save Only"}
          </button>
          <button
            type="button"
            className="btn white teal-text"
            onClick={() => {
              setShowQuantityPriceModal(false);
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RIChangeParamsModal;
